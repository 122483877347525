import { Component, createRef } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import TabelaPrecoService from 'lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService';
import TabelaPrecoItem from 'lirida-back-service/Servicos/TabelaPrecoItem/TabelaPrecoItem';
import HeaderLojista from "../components/HeaderLojista";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import * as XLSX from 'xlsx'
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import ItemCategoria from "lirida-back-service/Servicos/ItemCategoria/ItemCategoria";

export default class TabelaPrecoPg extends Component {
    constante = new Constante();
    util = new Util();
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base);
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    toast = createRef();
    inputFileRef = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoaAdministrador: new Pessoa(),
        listaTabelaPrecoItem: [],
        listaItemCategoria: [],
        listaPessoaFabricante: [],

        carregando: false,
        tabelaPrecoItem: new TabelaPrecoItem(),
        salvarCarregando: false,
        conteudo: '',
        mostrarDetalhe: false,
        codigoVisualizacao: '',
        precoMinimoVisualizacao: '',
        salvarExcelCarregando: false,

        itemCategoria: new ItemCategoria(),
        itemCategoriaFiltro: new ItemCategoria(),
        pessoaFabricante: new Pessoa(),
        pessoaFabricanteFiltro: new Pessoa(),
        situacaoFiltro: "",
        precoFiltro: "",
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();
        let retorno = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador, this.constante.seqUsuario);
        let pessoaAdministrador = retorno.objeto;

        this.listarCategoria();
        this.listarPessoaFabricante();

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
            pessoaAdministrador: pessoaAdministrador
        });
    }

    async listarCategoria() {
        let retornoListaCategoria = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        let listaItemCategoria = [];

        retornoListaCategoria.objeto.forEach(pItemCategoria => {
            if (pItemCategoria.situacao === "ATIVO") {
                listaItemCategoria.push(pItemCategoria);
            }
        })

        this.setState({
            listaItemCategoria: listaItemCategoria,
        });
    }

    async listarPessoaFabricante() {
        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilFabricante;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);
        const listaPessoaFabricante = retornoListaPessoa.objeto;

        this.setState({
            carregando: false,
            listaPessoaFabricante: listaPessoaFabricante
        })
    }

    async salvarSituacao(pTabelaPrecoItem) {
        const retornoTabelaPrecoItem = await this.tabelaPrecoService.salvarItem(pTabelaPrecoItem);
        pTabelaPrecoItem = retornoTabelaPrecoItem.objeto;

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            tabelaPrecoItem: pTabelaPrecoItem,
            mostrarDetalhe: false,
        });
        this.setState({
            tabelaPrecoItem: new TabelaPrecoItem(),
        })
    }

    async salvar() {
        const tabelaPrecoItem = this.state.tabelaPrecoItem;

        this.toast.current.clear();

        this.setState({ salvarCarregando: true });

        let codigoDuplicado = false;

        this.state.listaTabelaPrecoItem.forEach(pTabelaPrecoItem => {
            if (tabelaPrecoItem._itemCodigo === pTabelaPrecoItem._itemCodigo) {
                return;
            }

            if (pTabelaPrecoItem.tags0 === this.state.codigoVisualizacao) {
                this.toast.current.show([
                    { severity: 'error', summary: 'Você possui outro item com este código!', sticky: true },
                ]);

                this.setState({ salvarCarregando: false });

                codigoDuplicado = true;
            }
        })

        if (codigoDuplicado) {
            return;
        }

        tabelaPrecoItem.tags0 = this.state.codigoVisualizacao;
        tabelaPrecoItem.precoMinimo = this.state.precoMinimoVisualizacao;

        await this.tabelaPrecoService.salvarItem(tabelaPrecoItem);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, this.state.precoFiltro);

        this.setState({
            salvarCarregando: false,
            tabelaPrecoItem: new TabelaPrecoItem(),
            codigoVisualizacao: '',
            precoMinimoVisualizacao: '',
        });
    }

    async listar(pPessoa, pItemCategoria, pPessoaFabricante, pSituacao, pPreco) {
        this.setState({
            listaTabelaPrecoItem: [],
            carregando: true,
        });

        let listaPesquisa = [];
        let itemCategoria = new ItemCategoria();
        let pessoaFabricante = new Pessoa();
        let situacao = "";
        let preco = "";

        if (pItemCategoria) {
            itemCategoria = pItemCategoria;
        }

        if (pPessoaFabricante) {
            pessoaFabricante = pPessoaFabricante;
        }

        if (pSituacao) {
            situacao = pSituacao;
        }

        if (pPreco) {
            preco = pPreco;
        }

        this.setState({
            carregando: true,
            itemCategoriaFiltro: itemCategoria,
            pessoaFabricanteFiltro: pessoaFabricante,
            situacaoFiltro: situacao,
            precoFiltro: preco,
        })

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'tabela_preco_item.seq_tabela_preco';
        pesquisa.conteudo = pPessoa.seqTabelaPreco;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        if (preco === "S") {
            pesquisa = new Pesquisa();
            pesquisa.campo = ' AND tabela_preco_item.preco is not null ';
            pesquisa.conteudo = "S";
            pesquisa.operacao = "AND";
            pesquisa.tipo = "MANUAL";
            listaPesquisa.push(pesquisa);
        }

        if (preco === "N") {
            pesquisa = new Pesquisa();
            pesquisa.campo = ' AND tabela_preco_item.preco is null ';
            pesquisa.conteudo = "N";
            pesquisa.operacao = "AND";
            pesquisa.tipo = "MANUAL";
            listaPesquisa.push(pesquisa);
        }

        if (situacao === "ATIVO") {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'tabela_preco_item.tags1';
            pesquisa.conteudo = "S";
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        if (situacao === "INATIVO") {
            pesquisa = new Pesquisa();
            pesquisa.campo = " AND tabela_preco_item.tags1 is null ";
            pesquisa.conteudo = "S";
            pesquisa.operacao = "AND";
            pesquisa.tipo = "MANUAL";
            listaPesquisa.push(pesquisa);
        }

        pesquisa = new Pesquisa();
        pesquisa.campo = 'item.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = ` AND upper(concat(item.codigo, ' ', item.nome, ' ', item.descricao)) like '%${this.state.conteudo?.toUpperCase()}%' `;
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'item.tagn0';
        pesquisa.conteudo = 1;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "NUMERO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND item.situacao = 'ATIVO' ";
        pesquisa.conteudo = "ATIVO";
        pesquisa.operacao = "AND";
        pesquisa.tipo = "MANUAL";
        listaPesquisa.push(pesquisa);

        if (itemCategoria) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "item.seq_item_categoria"
            pesquisa.conteudo = itemCategoria._seqItemCategoria;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_tipo_relacionamento"
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoFabricante;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        if (pessoaFabricante) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "item_relacionamento.seq_pessoa_relacionada"
            pesquisa.conteudo = pessoaFabricante._seqPessoa;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        const retornoListaTabelaPrecoItem = await this.tabelaPrecoService.listarComFiltro(listaPesquisa);
        const listaTabelaPrecoItem = retornoListaTabelaPrecoItem.objeto;

        this.setState({
            listaTabelaPrecoItem: listaTabelaPrecoItem,
            carregando: false,
        });
    }

    exportarListaExcel() {
        let lista = [];

        this.state.listaTabelaPrecoItem.forEach(pTabelaPrecoItem => {
            if (pTabelaPrecoItem.tags1 === "S") {
                lista.push({
                    "Codigo": pTabelaPrecoItem.tags0?.toString(),
                    "Nome": pTabelaPrecoItem._itemNome?.toString(),
                    "Status": "ATIVO",
                    "Preco": pTabelaPrecoItem.preco,
                    "": "",
                    "ID": pTabelaPrecoItem._seqTabelaPrecoItem?.toString(),
                    "ID_Chave": pTabelaPrecoItem._tabelaPrecoChave?.toString(),
                    "ID_TabelaPreco": pTabelaPrecoItem.seqTabelaPreco?.toString(),
                    "ID_Item": pTabelaPrecoItem.seqItem?.toString(),
                });
            }
        });

        this.util.exportarExcel(lista, "Lista de Tabela de Preço ( Zé Marceneiro )");
    }

    gerenciarArquivoExcel(pArquivo) {
        const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (pArquivo) {
            if (pArquivo && fileType.includes(pArquivo.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(pArquivo);
                reader.onload = (e) => {
                    this.toast.current.clear();
                    this.importarListaExcel(e.target.result);
                }
            }
            else {
                this.toast.current.show([
                    { severity: 'error', summary: 'Por favor, selecione um arquivo Excel!', sticky: true },
                ]);
                this.setState({
                    arquivoExcel: null
                })
            }
        }
    }

    importarListaExcel(pArquivoExcel) {
        const workbook = XLSX.read(pArquivoExcel, { type: 'buffer' });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const listaTabelaPrecoItemExcel = XLSX.utils.sheet_to_json(worksheet);

        this.setState({ salvarExcelCarregando: true });

        listaTabelaPrecoItemExcel.forEach(pTabelaPrecoItemExcel => {
            this.state.listaTabelaPrecoItem.forEach(async pTabelaPrecoItem => {
                if (pTabelaPrecoItem._seqTabelaPrecoItem === pTabelaPrecoItemExcel.ID) {

                    let tabelaPrecoItem = new TabelaPrecoItem();
                    tabelaPrecoItem.preco = pTabelaPrecoItemExcel.Preco;
                    tabelaPrecoItem.seqItem = pTabelaPrecoItem.seqItem?.toString();
                    tabelaPrecoItem.seqTabelaPreco = pTabelaPrecoItem.seqTabelaPreco?.toString();
                    tabelaPrecoItem.seqUsuario = this.constante.seqUsuario?.toString();
                    tabelaPrecoItem.situacao = "ATIVO";
                    tabelaPrecoItem.tags0 = pTabelaPrecoItemExcel.Codigo?.toString();
                    tabelaPrecoItem.tags1 = "S";
                    tabelaPrecoItem._seqTabelaPrecoItem = pTabelaPrecoItem._seqTabelaPrecoItem?.toString();
                    tabelaPrecoItem._tabelaPrecoChave = pTabelaPrecoItem._tabelaPrecoChave?.toString();

                    await this.tabelaPrecoService.salvarItem(tabelaPrecoItem);
                }
            });
        });

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.inputFileRef.current.value = "";

        this.listar(this.state.pessoaUsuario);

        this.setState({ salvarExcelCarregando: false });
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro }}>
            <HeaderLojista />

            <Toast ref={this.toast} />

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', display: "flex", alignItems: 'flex-start', gap: 20, }}>
                <div style={{ width: 400, backgroundColor: 'white', padding: 20, marginTop: 20, marginLeft: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                    <h2 style={{ fontSize: 16, color: this.constante.corAzulMarinhoEscuro }}>FILTRO</h2>

                    <h3 style={{ fontSize: 18, color: this.constante.corPrincipal, marginTop: 20 }}>Categoria</h3>

                    <div style={{ display: "flex", flexDirection: 'column', gap: 5, marginTop: 20 }}>
                        <Button
                            label="Todas"
                            icon={!this.state.itemCategoriaFiltro._seqItemCategoria ? "pi pi-times-circle" : "pi pi-circle"}
                            className="p-button-link"
                            style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal }}
                            onClick={() => this.listar(this.state.pessoaUsuario, "", this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, this.state.precoFiltro)} />
                        {this.state.listaItemCategoria.map(pItemCategoria =>
                            <Button
                                label={pItemCategoria.nome}
                                icon={this.state.itemCategoriaFiltro._seqItemCategoria === pItemCategoria._seqItemCategoria ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.pessoaUsuario, pItemCategoria, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, this.state.precoFiltro)} />)}
                    </div>

                    <h3 style={{ fontSize: 18, color: this.constante.corPrincipal, marginTop: 20 }}>Fabricante</h3>

                    <div style={{ display: "flex", flexDirection: 'column', gap: 5, marginTop: 20 }}>
                        <Button
                            label="Todos"
                            icon={!this.state.pessoaFabricanteFiltro._seqPessoa ? "pi pi-times-circle" : "pi pi-circle"}
                            className="p-button-link"
                            style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal }}
                            onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, "", this.state.situacaoFiltro, this.state.precoFiltro)} />
                        {this.state.listaPessoaFabricante.map(pPessoa =>
                            <Button
                                label={pPessoa.nome}
                                icon={this.state.pessoaFabricanteFiltro._seqPessoa === pPessoa._seqPessoa ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, pPessoa, this.state.situacaoFiltro, this.state.precoFiltro)} />)}
                    </div>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", gap: 5, marginTop: 20 }}>
                        <h3 style={{ fontSize: 18, color: this.constante.corPrincipal }}>Preço</h3>

                        <div style={{ display: "flex", flexDirection: 'column', gap: 5, marginTop: 20 }}>
                            <Button
                                label="Todos"
                                icon={!this.state.precoFiltro ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal }}
                                onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "")} />

                            <Button
                                label="Com preço"
                                icon={this.state.precoFiltro === "S" ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "S")} />

                            <Button
                                label="Sem preço"
                                icon={this.state.precoFiltro === "N" ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "N")} />
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", gap: 5, marginTop: 20 }}>
                        <h3 style={{ fontSize: 18, color: this.constante.corPrincipal }}>Status</h3>

                        <div
                            style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer", marginTop: 20 }}
                            onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, "", this.state.precoFiltro)}>
                            <i style={{ color: this.constante.corPrincipal, }} className={!this.state.situacaoFiltro ? "pi pi-times-circle" : "pi pi-circle"}></i>

                            <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corPrincipal, textAlign: 'center' }}>
                                    Todas
                                </p>
                            </span>
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer" }}
                            onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, "ATIVO", this.state.precoFiltro)}>
                            <i style={{ color: this.constante.corPrincipal, }} className={this.state.situacaoFiltro === "ATIVO" ? "pi pi-times-circle" : "pi pi-circle"}></i>

                            <span style={{ backgroundColor: this.constante.corVerdeClaro, padding: 5, borderRadius: 5, }}>
                                <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corVerdeEscuro, textAlign: 'center' }}>
                                    ATIVO
                                </p>
                            </span>
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer" }}
                            onClick={() => this.listar(this.state.pessoaUsuario, this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, "INATIVO", this.state.precoFiltro)}>
                            <i style={{ color: this.constante.corPrincipal, }} className={this.state.situacaoFiltro === "INATIVO" ? "pi pi-times-circle" : "pi pi-circle"}></i>

                            <span style={{ backgroundColor: this.constante.corVermelhoClaro, padding: 5, borderRadius: 5, }}>
                                <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corVermelhoEscuro, textAlign: 'center' }}>
                                    INATIVO
                                </p>
                            </span>
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%", padding: 20, paddingLeft: 0, marginTop: 15 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                        <h1 style={{ width: "40%", fontSize: 20 }}>Lista de itens</h1>

                        <input
                            type="file" id="input_file" style={{ display: 'none' }}
                            onChange={pEvento => this.gerenciarArquivoExcel(pEvento.target.files[0])}
                            ref={this.inputFileRef} />
                        {this.state.salvarExcelCarregando ?
                            <Button
                                loading={true}
                                icon="pi pi-file-excel"
                                label="Importar lista do Excel"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                            />
                            :
                            <label
                                htmlFor="input_file"
                                style={{ padding: 8, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", display: "flex", alignItems: 'center', gap: 10 }}
                            >
                                <i className="pi pi-file-excel"></i>

                                <span style={{ textAlign: 'center' }}>Importar lista do Excel</span>
                            </label>}

                        <Button
                            icon="pi pi-file-excel"
                            label="Exportar lista para o Excel"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                            onClick={() => this.exportarListaExcel()}
                        />
                    </div>

                    <form
                        style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
                        onSubmit={pEvento => { pEvento.preventDefault(); this.listar(this.state.pessoaUsuario,); }}>
                        
                        <InputText
                            placeholder="Pesquisar"
                            style={{ width: "90%" }}
                            onChange={pEvento => this.setState({
                                conteudo: pEvento.target.value
                            })} />
                        <Button
                            label="Pesquisar"
                            loading={this.state.pesquisarCarregando}
                            type="submit"
                            icon="pi pi-search"
                            iconPos="left"
                            style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }} />
                    </form>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: this.constante.corAzulMarinhoMedio, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, gap: 20 }}>
                        <div style={{ width: "35%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Item
                            </p>
                        </div>
                        <div style={{ width: "25%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Fabricante
                            </p>
                        </div>
                        <div style={{ width: "20%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Categoria
                            </p>
                        </div>
                        <div style={{ width: "20%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Status
                            </p>
                        </div>
                        {/* <div style={{ width: "15%", textAlign: 'right' }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Preço
                            </p>
                        </div> */}
                    </div>

                    {this.state.carregando &&
                        <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                            <Button
                                loading={true}
                                label="Carregando"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                        </div>}

                    {(!this.state.carregando && this.state.listaTabelaPrecoItem.length === 0) &&
                        <p style={{ textAlign: "center", marginTop: 20 }}>Nenhum item listado</p>}

                    {this.state.listaTabelaPrecoItem.map(pTabelaPrecoItem =>
                        <div style={{ backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, marginTop: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', gap: 20 }}>
                                <div style={{ width: "35%", display: 'flex', alignItems: 'center', gap: 10, }}>
                                    <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                        <p
                                            key={Math.random()}
                                            style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                            {pTabelaPrecoItem.tags0}
                                        </p>
                                    </span>
                                    <img
                                        key={Math.random()}
                                        style={{ width: 80, height: 80, objectFit: "contain" }}
                                        src={this.state.urlBase + pTabelaPrecoItem._itemImagem}
                                        alt="Imagem do item"
                                    />
                                    <div>
                                        <p
                                            key={Math.random()}
                                            style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                                            {pTabelaPrecoItem._itemCodigo}
                                        </p>
                                        <p
                                            key={Math.random()}
                                            style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline', textDecorationColor: this.constante.corPrincipal, marginTop: 5 }}
                                            onClick={() => {
                                                console.log(pTabelaPrecoItem)

                                                if (this.state.tabelaPrecoItem._seqTabelaPrecoItem !== pTabelaPrecoItem._seqTabelaPrecoItem) {
                                                    this.setState({ tabelaPrecoItem: new TabelaPrecoItem() });
                                                    this.setState({
                                                        tabelaPrecoItem: pTabelaPrecoItem,
                                                        codigoVisualizacao: pTabelaPrecoItem.tags0,
                                                        precoMinimoVisualizacao: pTabelaPrecoItem.precoMinimo,
                                                        mostrarDetalhe: true,
                                                    });
                                                } else {
                                                    this.setState({
                                                        tabelaPrecoItem: new TabelaPrecoItem(),
                                                        mostrarDetalhe: false,
                                                    });
                                                    // this.listar(this.state.pessoaUsuario);
                                                }
                                            }}>
                                            {pTabelaPrecoItem._itemNome}
                                        </p>
                                        <p style={{ fontSize: 16, color: 'gray', marginTop: 5 }}>
                                            {pTabelaPrecoItem._itemDescricao}
                                        </p>
                                    </div>
                                </div>

                                <div style={{ width: "25%" }}>
                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                                        {pTabelaPrecoItem._pessoaRelacionadaNome}
                                    </p>
                                </div>

                                <div style={{ width: "20%" }}>
                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                                        {pTabelaPrecoItem._categoriaNome}
                                    </p>
                                </div>

                                <div
                                    key={Math.random()}
                                    style={{ width: "20%", display: 'flex', gap: 10 }}>

                                    <InputSwitch checked={pTabelaPrecoItem.tags1 === "S" ? true : false} onChange={pEvento => {
                                        if (pEvento.value === false) {
                                            pTabelaPrecoItem.tags1 = null;
                                        }

                                        if (pEvento.value === true) {
                                            pTabelaPrecoItem.tags1 = "S";
                                        }

                                        this.salvarSituacao(pTabelaPrecoItem);
                                    }} />

                                    <span
                                        key={Math.random()}
                                        style={{ backgroundColor: pTabelaPrecoItem.tags1 === "S" ? this.constante.corVerdeClaro : this.constante.corVermelhoClaro, padding: 5, borderRadius: 5, }}>
                                        <p
                                            key={Math.random()}
                                            style={{ fontSize: 13, color: this.constante.corPrincipal, fontWeight: 'bold', color: pTabelaPrecoItem.tags1 === "S" ? this.constante.corVerdeEscuro : this.constante.corVermelhoEscuro, textAlign: 'center' }}>
                                            {pTabelaPrecoItem.tags1 === 'S' ? "ATIVO" : "INATIVO"}
                                        </p>
                                    </span>
                                </div>

                                {/* <div style={{ width: "15%", textAlign: 'right' }}>
                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        {this.util.formatarValor(pTabelaPrecoItem.precoMaximo)}
                                    </p>
                                </div> */}
                            </div>

                            {((this.state.tabelaPrecoItem._seqTabelaPrecoItem === pTabelaPrecoItem._seqTabelaPrecoItem) && this.state.mostrarDetalhe) &&
                                <div>
                                    <div className="TabelaPrecoPg-dropdown_animado" style={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, padding: '0 20px' }}>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Seu código
                                            </label>
                                            <br />
                                            <InputText
                                                style={{ width: "100%", }}
                                                onChange={pEvento => this.setState({
                                                    codigoVisualizacao: pEvento.target.value,
                                                })}
                                                defaultValue={this.state.codigoVisualizacao} />
                                        </div>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Preço desejado (R$)
                                            </label>
                                            <br />
                                            <InputText
                                                style={{ width: "100%", }}
                                                type="number"
                                                onChange={pEvento => this.setState({
                                                    precoMinimoVisualizacao: pEvento.target.value,
                                                })}
                                                defaultValue={this.state.precoMinimoVisualizacao} />
                                        </div>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Taxa (%)
                                            </label>
                                            <br />
                                            <p style={{ marginTop: 10 }}>{this.state.pessoaAdministrador.tagn3}%</p>
                                        </div>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Desconto (%)
                                            </label>
                                            <br />
                                            <p style={{ marginTop: 10 }}>{this.state.pessoaUsuario.tagn4}%</p>
                                        </div>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Preço de venda (R$)
                                            </label>
                                            <br />
                                            <p style={{ marginTop: 10 }}>{this.util.formatarValor(this.state.precoMinimoVisualizacao * (1 + (this.state.pessoaAdministrador.tagn3 / 100)) - (this.state.pessoaUsuario.tagn4 ? this.state.precoMinimoVisualizacao * (this.state.pessoaUsuario.tagn4 / 100) : 0))}</p>
                                        </div>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Estoque mínimo
                                            </label>
                                            <br />
                                            <InputText
                                                style={{ width: "100%", }}
                                                type="number"
                                                onChange={pEvento => this.setState({
                                                    tabelaPrecoItem: {
                                                        ...this.state.tabelaPrecoItem,
                                                        tagn1: pEvento.target.value
                                                    }
                                                })}
                                                value={this.state.tabelaPrecoItem.tagn1}
                                            />
                                        </div>
                                        <div className="TabelaPrecoPg-dropdown_info">
                                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                Estoque disponível
                                            </label>
                                            <br />
                                            <InputText
                                                style={{ width: "100%", }}
                                                type="number"
                                                onChange={pEvento => this.setState({
                                                    tabelaPrecoItem: {
                                                        ...this.state.tabelaPrecoItem,
                                                        tagn2: pEvento.target.value
                                                    }
                                                })}
                                                value={this.state.tabelaPrecoItem.tagn2}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro }}>
                                        <Button
                                            label="Cancelar"
                                            className="p-button-outlined p-button-secondary"
                                            style={{ backgroundColor: 'white' }}
                                            onClick={() => {
                                                this.setState({
                                                    tabelaPrecoItem: new TabelaPrecoItem(),
                                                    mostrarDetalhe: false,
                                                });
                                                // this.listar(this.state.pessoaUsuario);
                                            }} />
                                        <Button
                                            loading={this.state.salvarCarregando}
                                            label="Salvar"
                                            className="p-button-success"
                                            onClick={() => this.salvar()} />
                                    </div>
                                </div>}
                        </div>)}
                </div>
            </main >
        </div >
    }
}