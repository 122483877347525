import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { Component } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import logo from '../../../assets/imagens/logo-branca.png';

export default class Header extends Component {
    constante = new Constante();
    util = new Util();

    state = {
        pessoaUsuario: new Pessoa(),
        dropdownItemVisivel: false,
        dropdownFinanceiroVisivel: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({ pessoaUsuario: pessoaUsuario });
    }

    render() {
        return <header style={{ height: 50, backgroundColor: this.constante.corPrincipal, padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={logo} style={{ width: 150, height: 50, objectFit: "contain" }} />

            <nav style={{ display: 'flex', alignItems: "center" }}>
                <Button
                    label="Dashboard"
                    onClick={() => window.location.href = "/sistema/admin/dashboard"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Clientes"
                    onClick={() => window.location.href = "/sistema/admin/cliente"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Pedidos"
                    onClick={() => window.location.href = "/sistema/admin/pedido"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Lojistas"
                    onClick={() => window.location.href = "/sistema/admin/lojista"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Fabricantes"
                    onClick={() => window.location.href = "/sistema/admin/fabricante"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <div style={{ position: 'relative' }}>
                    <Button
                        label="Itens"
                        onClick={() => this.setState({ dropdownItemVisivel: !this.state.dropdownItemVisivel })}
                        className="p-button-link"
                        style={{ color: 'white' }} />
                    {this.state.dropdownItemVisivel &&
                        <div style={{ position: 'absolute', padding: 5, backgroundColor: "white", boxShadow: "0 0 10px rgba(0,0,0,0.5)", zIndex: 999 }}>
                            <Button
                                label="Cadastro"
                                style={{ textAlign: 'left' }}
                                onClick={() => window.location.href = "/sistema/admin/cadastro"}
                                className="p-button-link" />
                            <Button
                                label="Categoria"
                                style={{ textAlign: 'left' }}
                                onClick={() => window.location.href = "/sistema/admin/categoria"}
                                className="p-button-link" />
                            <Button
                                label="Aprovação"
                                style={{ textAlign: 'left' }}
                                onClick={() => window.location.href = "/sistema/admin/aprovacao"}
                                className="p-button-link" />
                        </div>
                    }
                </div>
                <div style={{ position: 'relative' }}>
                    <Button
                        label="Financeiro"
                        onClick={() => this.setState({ dropdownFinanceiroVisivel: !this.state.dropdownFinanceiroVisivel })}
                        className="p-button-link"
                        style={{ color: 'white' }} />
                    {this.state.dropdownFinanceiroVisivel &&
                        <div style={{ width: 200, position: 'absolute', padding: 5, backgroundColor: "white", boxShadow: "0 0 10px rgba(0,0,0,0.5)", zIndex: 999 }}>
                            {/* <Button
                                label="Contas à pagar"
                                style={{ textAlign: 'left' }}
                                onClick={() => window.location.href = "/sistema/admin/contas_a_pagar"}
                                className="p-button-link" /> */}
                            <Button
                                label="Contas à receber"
                                style={{ textAlign: 'left' }}
                                onClick={() => window.location.href = "/sistema/admin/contas_a_receber"}
                                className="p-button-link" />
                        </div>
                    }
                </div>
                <Button
                    label="Perfil"
                    onClick={() => window.location.href = "/sistema/admin/perfil"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Sair"
                    onClick={() => {
                        this.util.removerLocalStorage("PESSOA_USUARIO");
                        window.location.href = "/login";
                    }}
                    className="p-button-link"
                    style={{ color: 'white' }} />
            </nav>
        </header>
    }
}