import { Button } from "primereact/button";
import { Component } from "react";
import Header from "../components/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";

export default class ContasPagar extends Component {
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    itemService = new ItemService(this.constante.token, this.constante.url_base);

    state = {
        urlBaseFinanceiro: '',
        pessoaUsuario: new Pessoa(),
        listaFinanceiro: [],
        carregando: false,

        //navigation
        liberarAcessoLinkPagamento: false,

        /* Filtros */
        status: -1,
        dataCadastro_inicial: '',
        dataCadastro_final: '',
        dataProgramada_inicial: '',
        dataProgramada_final: '',
        dataBaixa_inicial: '',
        dataBaixa_final: '',
        dataCredito_inicial: '',
        dataCredito_final: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.setState({ carregando: true });
        const urlBaseFinanceiro = await this.util.buscarUrlBaseFinanceiro();
        const pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        await this.listarProjeto(pessoaUsuario);
        await this.listar(pessoaUsuario);

        this.setState({
            urlBaseFinanceiro: urlBaseFinanceiro,
            pessoaUsuario: pessoaUsuario,
        });
    }

    async listar(pPessoa) {
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "movimento_financeiro.cf_operacao",
                "Débito",
                "AND",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "movimento_financeiro.seq_pessoa_recebedor",
                pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            )
        );

        if (this.state.item._seqItem) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.seq_item",
                    this.state.item._seqItem,
                    "AND",
                    "TEXTO"
                )
            );
        }

        if (this.state.status >= 0) {
            if (this.state.status === 0) {
                listaPesquisa.push(
                    this.util.retornaPesquisa(
                        "movimento_financeiro.qtde_baixa",
                        this.state.status,
                        "AND",
                        "NUMERO"
                    )
                );
            } else {
                listaPesquisa.push(
                    this.util.retornaPesquisa(
                        "AND movimento_financeiro.qtde_baixa > 0",
                        this.state.status,
                        "AND",
                        "MANUAL"
                    )
                );
            }
        }

        if (this.state.dataCadastro_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarData(this.state.dataCadastro_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataCadastro_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarData(this.state.dataCadastro_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataProgramada_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarData(this.state.dataProgramada_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataProgramada_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarData(this.state.dataProgramada_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataBaixa_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_baixa",
                    this.util.formatarData(this.state.dataBaixa_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataBaixa_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_baixa",
                    this.util.formatarData(this.state.dataBaixa_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataCredito_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_credito",
                    this.util.formatarData(this.state.dataCredito_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataCredito_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_credito",
                    this.util.formatarData(this.state.dataCredito_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        const retornoListaFinanceiro = await this.financeiroService.listarComFiltro(
            listaPesquisa
        );
        const listaFinanceiro = retornoListaFinanceiro.objeto

        console.log(listaFinanceiro)

        this.setState({
            listaFinanceiro: listaFinanceiro,
            carregando: false,
        });
    }

    render() {
        return <div>
            <Header />

            <p style={{margin: '20px 0', textAlign: 'center'}}>Em desenvolvimento</p>

            {/* <main style={{ width: '98%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <h1 style={{ fontSize: 22 }}>Contas à pagar</h1>
                    </div>
                </div>

                <form
                    onSubmit={e => { e.preventDefault(); this.listar() }}
                    style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20, display: 'flex', flexDirection: 'column', gap: 15 }}>

                    <h1 style={{ fontSize: 20 }}>Filtros de busca</h1>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        <div>
                            <label>Status</label><br />
                            <Dropdown
                                placeholder="Selecione"
                                options={[
                                    { label: 'Não pago', value: 0 },
                                    { label: 'Pago', value: 1 },
                                ]}
                                value={this.state.status}
                                onChange={e => this.setState({ status: e.value })}
                                style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCadastro_inicial: e.value.toLocaleDateString() })}
                                value={this.state.dataCadastro_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCadastro_final: e.value.toLocaleDateString() })}
                                value={this.state.dataCadastro_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data programada inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataProgramada_inicial: e.value.toLocaleDateString() })}
                                value={this.state.dataProgramada_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data programada final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataProgramada_final: e.value.toLocaleDateString() })}
                                value={this.state.dataProgramada_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data baixa inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataBaixa_inicial: e.value.toLocaleDateString() })}
                                value={this.state.dataBaixa_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data baixa final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataBaixa_final: e.value.toLocaleDateString() })}
                                value={this.state.dataBaixa_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data crédito inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCredito_inicial: e.value.toLocaleDateString() })}
                                value={this.state.dataCredito_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data crédito final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCredito_final: e.value.toLocaleDateString() })}
                                value={this.state.dataCredito_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button type="submit" label="Buscar" className="p-button-raised" loading={this.state.carregando} />
                    </div>
                </form>

                <DataTable
                    style={{ marginTop: 20 }}
                    selectionMode="single"
                    showGridlines
                    removableSort
                    value={this.state.listaFinanceiro}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                    <Column field="_pesNome" header="Nome" sortable></Column>
                    <Column field="_fpNome" header="Forma de pagamento" sortable></Column>
                    <Column field="valorProgramado" header="Valor" sortable body={pItem =>
                        <p>{this.util.formatarValor(pItem.valorProgramado)}</p>
                    }></Column>
                    <Column field="qtdeBaixa" header="Status" sortable body={item => {
                        return <p>{item.qtdeBaixa === 0 ? "Não pago" : "pago"}</p>
                    }}></Column>
                    <Column field="dataCadastro" header="Data cadastro" sortable body={pItem =>
                        <p>{this.util.formatarDataComTimestamp(pItem.dataCadastro)}</p>
                    }></Column>
                    <Column field="dataProgramado" header="Data programada" sortable body={pItem =>
                        <p>{this.util.formatarDataComTimestamp(pItem.dataProgramado)}</p>
                    }></Column>
                    <Column field="dataBaixa" header="Data baixa" sortable body={pItem =>
                        <p>{this.util.formatarDataComTimestamp(pItem.dataBaixa)}</p>
                    }></Column>
                    <Column field="dataCredito" header="Data crédito" sortable body={pItem =>
                        <p>{this.util.formatarDataComTimestamp(pItem.dataCredito)}</p>
                    }></Column>
                </DataTable>
            </main> */}
        </div>
    }
}