import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from 'primereact/messages';
import { Component, createRef } from "react";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import logo from '../../assets/imagens/logo.png';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class Login extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    mensagem = createRef();

    state = {
        email: '',
        senha: '',
        carregando: false,
        inputError: {
            email: false,
            senha: false,
        }
    }

    async validarAcesso(pEvento) {
        pEvento.preventDefault();
        let pessoa = new Pessoa();
        let inputError = this.state.inputError;
        let listaPesquisa = [];
        let inputOK = true;

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!this.state.email) {
            inputError.email = true;
        }

        if (!await this.util.validarEmail(this.state.email)) {
            inputError.email = true;
        }

        if (!this.state.senha) {
            inputError.senha = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        });

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        this.mensagem.current.clear();

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.seq_pessoa_perfil IN ('" + this.constante.seqPessoaPerfilAdministrador + "', '" + this.constante.seqPessoaPerfilLojista + "', '" + this.constante.seqPessoaPerfilFabricante + "' ) ";
        pesquisa.conteudo = this.state.seqPessoaPerfilAdministrador;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoa = retorno.objeto[0];

        if (!pessoa._seqPessoa) {
            this.mensagem.current.show([
                { severity: 'error', summary: 'Usuário não encontrado', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilFabricante && pessoa.status !== 1) {
            this.mensagem.current.show([
                { severity: 'error', summary: 'Usuário aguardando aprovação', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        if (pessoa.senha !== this.state.senha) {
            this.mensagem.current.show([
                { severity: 'error', summary: 'Senha inválida', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador) {
            window.location.href = "/sistema/admin/dashboard";
        }

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilLojista) {
            window.location.href = "/sistema/lojista/dashboard";
        }

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilFabricante) {
            window.location.href = "/sistema/fabricante/cadastro";
        }
    }

    render() {
        return <main style={{ width: "90%", padding: '110px 0 50px 0', margin: '0 auto' }}>
            <Messages ref={this.mensagem} />

            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} style={{ width: 400, height: 400, objectFit: "contain" }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
                    <form
                        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
                        onSubmit={pEvento => this.validarAcesso(pEvento)}>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Email
                            </label>
                            <InputText
                                type="text"
                                onChange={pEvento => this.setState({
                                    email: pEvento.target.value
                                })}
                                value={this.state.email}
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red', marginTop: 5 }} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Senha
                            </label>
                            <InputText
                                type="password"
                                onChange={pEvento => this.setState({
                                    senha: pEvento.target.value
                                })}
                                value={this.state.senha}
                                style={{ width: '100%', borderColor: this.state.inputError.senha && 'red', marginTop: 5 }} />
                            {this.state.inputError.senha && <small style={{ color: 'red' }}>Senha inválida</small>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 10, marginTop: 10 }}>
                            <Button
                                style={{ padding: 0 }}
                                type="button"
                                label="Recuperar senha"
                                className="p-button-link"
                                onClick={() => window.location.href = "/recuperar_senha"} />
                            <Button
                                style={{ marginTop: 20 }}
                                loading={this.state.carregando}
                                type="submit"
                                label="Entrar" />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    }
}