import { Component, createRef } from "react";
import ItemService from 'lirida-back-service/Servicos/Item/ItemService';
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Item from "lirida-back-service/Servicos/Item/Item";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import HeaderFabricante from "../components/HeaderFabricante";
import { Tag } from "primereact/tag";

export default class ItemFabricante extends Component {
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    toast = createRef();
    inputFileRef = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        item: new Item(),
        listaItem: [],
        conteudo: "",
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar(pessoaUsuario);

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
        })
    }

    async listar(pPessoaUsuario) {
        let listaPesquisa = [];

        this.setState({
            carregando: true,
            item: new Item(),
            listaItem: [],
        });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'item.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = ` AND upper(concat(item.codigo, item.nome, item.descricao)) like '%${this.state.conteudo?.toUpperCase()}%' `;
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "MANUAL";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'item_relacionamento.seq_tipo_relacionamento';
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoFabricante;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'item_relacionamento.seq_pessoa_relacionada';
        pesquisa.conteudo = pPessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        let retorno = await this.itemService.listarComFiltro(listaPesquisa);
        let listaItem = retorno.objeto;

        this.setState({
            carregando: false,
            listaItem: listaItem
        })
    }

    exportarModeloExcel() {
        let lista = [];

        lista.push({
            "Código": "",
            "Nome": "",
            "Descrição": "",
            "Foto": "",
            "Link para detalhes do produto": "",
            "Categoria": ""
        });

        this.util.exportarExcel(lista, "Modelo de Excel - Zé Marceneiro");
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro, paddingBottom: 100 }}>
            <HeaderFabricante />

            <Toast ref={this.toast} />

            <Dialog
                header={this.state.item._seqItem ? "Detalhe do item" : "Cadastrar novo item"}
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                contentStyle={{ padding: 0 }}
                visible={this.state.itemDetalheVisivel}
                onHide={() => this.setState({ itemDetalheVisivel: false })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20, padding: 20 }}>
                    <div style={{ margin: '0 auto' }}>
                        <img
                            src={this.state.urlBase + this.state.item.imagem}
                            style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20, objectFit: "contain" }}
                        />
                    </div>
                    <div>
                        <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                            Link ou descrição detalhada do item
                        </label>
                        <br />
                        <InputText
                            disabled
                            style={{ width: "100%", marginTop: 5 }}
                            value={this.state.item.tags0} />
                    </div>
                    <div>
                        <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                            Código
                        </label>
                        <br />
                        <InputText
                            disabled
                            style={{ width: "100%", marginTop: 5 }}
                            value={this.state.item.codigo} />
                    </div>
                    <div>
                        <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                            Nome
                        </label>
                        <br />
                        <InputText
                            disabled
                            style={{ width: "100%", marginTop: 5 }}
                            value={this.state.item.nome} />
                    </div>
                    <div>
                        <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                            Descrição
                        </label>
                        <br />
                        <InputText
                            disabled
                            style={{ width: "100%", marginTop: 5 }}
                            value={this.state.item.descricao} />
                    </div>
                </div>
            </Dialog>

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 20, }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ width: "20%", fontSize: 20 }}>Lista de itens</h1>

                    <Button
                        label='Exportar modelo Excel'
                        style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                        onClick={() => this.exportarModeloExcel()}
                    />
                </div>

                <form
                    style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
                    onSubmit={pEvento => { pEvento.preventDefault(); this.listar(this.state.pessoaUsuario); }}>
                    <InputText
                        placeholder="Pesquise digitando o código, o nome ou a descrição do produto"
                        style={{ width: "80%" }}
                        onChange={pEvento => this.setState({
                            conteudo: pEvento.target.value
                        })} />
                    <Button
                        label="Pesquisar"
                        loading={this.state.pesquisarCarregando}
                        type="submit"
                        icon="pi pi-search"
                        iconPos="left"
                        style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }} />
                </form>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", backgroundColor: this.constante.corAzulMarinhoMedio, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, gap: 20 }}>
                    <div style={{ width: "50%" }}>
                        <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                            Item
                        </p>
                    </div>
                    <div style={{ width: "50%" }}>
                        <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', textAlign: 'right' }}>
                            Status
                        </p>
                    </div>
                </div>

                {this.state.carregando &&
                    <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            loading={true}
                            label="Carregando"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                    </div>}

                {(!this.state.carregando && this.state.listaItem.length === 0) &&
                    <p style={{ textAlign: "center", marginTop: 20 }}>Nenhum item listado</p>}

                {this.state.listaItem.map(pItem =>
                    <div style={{ backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20, }}>
                            <img src={this.state.urlBase + pItem.imagem} style={{ width: 50, height: 50, borderRadius: 500, objectFit: 'contain' }} />

                            <div style={{ display: 'flex', alignItems: "center", gap: 20 }}>
                                <Tag value={pItem.codigo} style={{ backgroundColor: this.constante.corPrincipal, color: this.constante.corAzulMarinhoClaro }}></Tag>
                                <div>
                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', cursor: "pointer", textDecoration: "underline", textDecorationColor: this.constante.corPrincipal }}
                                        onClick={() => this.setState({
                                            item: pItem,
                                            itemDetalheVisivel: true,
                                        })}
                                    >
                                        {pItem.nome}
                                    </p>
                                    <p style={{ fontSize: 16, color: this.constante.corPrincipal, marginTop: 5 }}>
                                        {pItem.descricao}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            {pItem.tagn0 ?
                                <span style={{ backgroundColor: this.constante.corVerdeClaro, padding: 5, borderRadius: 5, }}>
                                    <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corVerdeEscuro, textAlign: 'center' }}>
                                        APROVADO
                                    </p>
                                </span> :
                                <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                    <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corAzulMarinhoEscuro, textAlign: 'center' }}>
                                        AGUARDANDO APROVAÇÃO
                                    </p>
                                </span>}
                        </div>
                    </div>)}
            </main >
        </div >
    }
}