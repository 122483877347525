import { Component } from "react";
import { Chart } from 'primereact/chart';
import Constante from "../../../util/Constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Util from "../../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import HeaderLojista from "../components/HeaderLojista";

export default class DashboardLojista extends Component {
    constante = new Constante();
    util = new Util();

    state = {
        listaDataApontamento: null,
        listaQtdApontamento: null,
        pessoaUsuario: new Pessoa(),
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        const listaPesquisa = [];

        const data = new Date();

        data.setDate(data.getDate() - 7);

        const pesquisa1 = new Pesquisa();
        pesquisa1.campo = "indicador.seq_usuario";
        pesquisa1.conteudo = this.constante.seqUsuario;
        pesquisa1.operacao = '';
        pesquisa1.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa1);

        const pesquisa2 = new Pesquisa();
        pesquisa2.campo = "indicador.seq_tipo_indicador";
        pesquisa2.conteudo = this.constante.seqTipoIndicadorAcessoLojista;
        pesquisa2.operacao = 'AND';
        pesquisa2.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa2);

        const pesquisa3 = new Pesquisa();
        pesquisa3.campo = "indicador.seq_pessoa";
        pesquisa3.conteudo = pessoaUsuario._seqPessoa;
        pesquisa3.operacao = 'AND';
        pesquisa3.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa3);

        const pesquisa4 = new Pesquisa();
        pesquisa4.campo = `indicador.data_apontamento`;
        pesquisa4.conteudo = data.toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        pesquisa4.operacao = 'AND';
        pesquisa4.tipo = 'DATA_INICIAL';

        listaPesquisa.push(pesquisa4);

        const pesquisa5 = new Pesquisa();
        pesquisa5.campo = `indicador.data_apontamento`;
        pesquisa5.conteudo = new Date().toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        pesquisa5.operacao = 'AND';
        pesquisa5.tipo = 'DATA_FINAL';

        listaPesquisa.push(pesquisa5);

        const url = new URL("v1/api/indicador/listar_com_filtro", this.constante.hostBase);

        const jsonBody = JSON.stringify(listaPesquisa);

        const response = await fetch(url, {
            method: "POST",
            body: jsonBody
        });

        const responseBody = await response.json();

        const listaIndicador = responseBody.objeto;

        const listaDataApontamento = [];

        for (let i = 0; i < listaIndicador.length; i++) {
            const indicador = listaIndicador[i];

            const dataApontamento = indicador.dataApontamento.split("T")[0].split("-").join("/");

            listaDataApontamento.push(dataApontamento);
        }

        const listaDataApontamentoSemDuplicidade = [...new Set(listaDataApontamento)];
        const listaQtdApontamento = [];

        for (let i = 0; i < listaDataApontamentoSemDuplicidade.length; i++) {
            const dataApontamentoSemDuplicidade = listaDataApontamentoSemDuplicidade[i];

            let qtdApontamento = 0;

            for (let j = 0; j < listaDataApontamento.length; j++) {
                const dataApontamento = listaDataApontamento[j];

                if (dataApontamentoSemDuplicidade === dataApontamento) {
                    qtdApontamento = qtdApontamento + 1;
                }
            }

            listaQtdApontamento.push(qtdApontamento);
        }

        this.setState({
            listaDataApontamento: listaDataApontamentoSemDuplicidade,
            listaQtdApontamento: listaQtdApontamento,
            pessoaUsuario: pessoaUsuario
        })
    }

    render() {
        return <div>
            <HeaderLojista />

            <main style={{ height: "calc(100vh - 50px)", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 50, gap: 20 }}>
                <div style={{ width: '100%', height: '100%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5, padding: 20 }}>
                    <h2 style={{ fontSize: 20, textAlign: "center", textDecoration: "underline", textDecorationThickness: 3, textDecorationColor: this.constante.corPrincipal }}>
                        Acesso à minha loja nos últimos 7 dias
                    </h2>
                    <Chart
                        style={{ width: "100%", height: "100%", padding: 20 }}
                        type="line"
                        data={{
                            labels: this.state.listaDataApontamento,
                            datasets: [{
                                label: this.state.pessoaUsuario.nome,
                                data: this.state.listaQtdApontamento,
                                fill: false,
                                borderColor: this.constante.corPrincipal,
                                tension: 0.4
                            }]
                        }}
                        options={{
                            maintainAspectRatio: false,
                            aspectRatio: 0.6
                        }}
                    />
                </div>
            </main>
        </div>
    }
}