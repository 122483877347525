import { Component } from "react";
import Header from "../components/Header";
import { Chart } from 'primereact/chart';
import Constante from "../../../util/Constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class Dashboard extends Component {
    constante = new Constante();

    state = {
        listaDataApontamentoGeral: null,
        listaQtdApontamentoGeral: null,
        listaDadosLojista: null,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.listarApontamentoGeral();
        this.listarApontamentoLojista();
    }

    gerarCorAleatoria() {
        const letras = '0123456789ABCDEF';
        let cor = '#';

        for (let i = 0; i < 6; i++) {
            cor += letras[Math.floor(Math.random() * 16)];
        }

        return cor;
    }

    async listarApontamentoGeral() {
        const listaPesquisa = [];

        const data = new Date();

        data.setDate(data.getDate() - 7);

        const pesquisa1 = new Pesquisa();
        pesquisa1.campo = "indicador.seq_usuario";
        pesquisa1.conteudo = this.constante.seqUsuario;
        pesquisa1.operacao = '';
        pesquisa1.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa1);

        const pesquisa2 = new Pesquisa();
        pesquisa2.campo = "indicador.seq_tipo_indicador";
        pesquisa2.conteudo = this.constante.seqTipoIndicadorAcessoGeral;
        pesquisa2.operacao = 'AND';
        pesquisa2.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa2);

        const pesquisa3 = new Pesquisa();
        pesquisa3.campo = `indicador.data_apontamento`;
        pesquisa3.conteudo = data.toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        pesquisa3.operacao = 'AND';
        pesquisa3.tipo = 'DATA_INICIAL';

        listaPesquisa.push(pesquisa3);

        const pesquisa4 = new Pesquisa();
        pesquisa4.campo = `indicador.data_apontamento`;
        pesquisa4.conteudo = new Date().toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        pesquisa4.operacao = 'AND';
        pesquisa4.tipo = 'DATA_FINAL';

        listaPesquisa.push(pesquisa4);

        const url = new URL("v1/api/indicador/listar_com_filtro", this.constante.hostBase);

        const jsonBody = JSON.stringify(listaPesquisa);

        const response = await fetch(url, {
            method: "POST",
            body: jsonBody
        });

        const responseBody = await response.json();

        const listaIndicador = responseBody.objeto;

        const listaDataApontamento = [];

        for (let i = 0; i < listaIndicador.length; i++) {
            const indicador = listaIndicador[i];

            const dataApontamento = indicador.dataApontamento.split("T")[0].split("-").join("/");

            listaDataApontamento.push(dataApontamento);
        }

        const listaDataApontamentoSemDuplicidade = [...new Set(listaDataApontamento)];
        const listaQtdApontamento = [];

        for (let i = 0; i < listaDataApontamentoSemDuplicidade.length; i++) {
            const dataApontamentoSemDuplicidade = listaDataApontamentoSemDuplicidade[i];

            let qtdApontamento = 0;

            for (let j = 0; j < listaDataApontamento.length; j++) {
                const dataApontamento = listaDataApontamento[j];

                if (dataApontamentoSemDuplicidade === dataApontamento) {
                    qtdApontamento = qtdApontamento + 1;
                }
            }

            listaQtdApontamento.push(qtdApontamento);
        }

        this.setState({
            listaDataApontamentoGeral: listaDataApontamentoSemDuplicidade,
            listaQtdApontamentoGeral: listaQtdApontamento
        })
    }

    async listarApontamentoLojista() {
        const listaPesquisa = [];

        const data = new Date();

        data.setDate(data.getDate() - 7);

        const pesquisa1 = new Pesquisa();
        pesquisa1.campo = "indicador.seq_usuario";
        pesquisa1.conteudo = this.constante.seqUsuario;
        pesquisa1.operacao = '';
        pesquisa1.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa1);

        const pesquisa2 = new Pesquisa();
        pesquisa2.campo = "indicador.seq_tipo_indicador";
        pesquisa2.conteudo = this.constante.seqTipoIndicadorAcessoLojista;
        pesquisa2.operacao = 'AND';
        pesquisa2.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa2);

        const pesquisa3 = new Pesquisa();
        pesquisa3.campo = `indicador.data_apontamento`;
        pesquisa3.conteudo = data.toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        pesquisa3.operacao = 'AND';
        pesquisa3.tipo = 'DATA_INICIAL';

        listaPesquisa.push(pesquisa3);

        const pesquisa4 = new Pesquisa();
        pesquisa4.campo = `indicador.data_apontamento`;
        pesquisa4.conteudo = new Date().toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        pesquisa4.operacao = 'AND';
        pesquisa4.tipo = 'DATA_FINAL';

        listaPesquisa.push(pesquisa4);

        const url = new URL("v1/api/indicador/listar_com_filtro", this.constante.hostBase);

        const jsonBody = JSON.stringify(listaPesquisa);

        const response = await fetch(url, {
            method: "POST",
            body: jsonBody
        });

        const responseBody = await response.json();

        const listaIndicador = responseBody.objeto;

        const listaDataApontamento = [];
        const listaNomeLojista = [];

        for (let i = 0; i < listaIndicador.length; i++) {
            const indicador = listaIndicador[i];

            listaDataApontamento.push(indicador.dataApontamento);
            listaNomeLojista.push(indicador._pessoaNome);
        }

        const listaDataApontamentoSemDuplicidade = [...new Set(listaDataApontamento)];
        const listaNomeLojistaSemDuplicidade = [...new Set(listaNomeLojista)];

        const datasets = [];

        for (let i = 0; i < listaNomeLojistaSemDuplicidade.length; i++) {
            const nomeLojistaSemDuplicidade = listaNomeLojistaSemDuplicidade[i];

            const dataset = {};

            dataset.label = nomeLojistaSemDuplicidade;
            dataset.data = [];

            for (let j = 0; j < listaDataApontamentoSemDuplicidade.length; j++) {
                const dataApontamentoSemDuplicidade = listaDataApontamentoSemDuplicidade[j];

                let quantidadeApontamento = 0;

                for (let k = 0; k < listaIndicador.length; k++) {
                    const indicador = listaIndicador[k];

                    if (dataApontamentoSemDuplicidade === indicador.dataApontamento && nomeLojistaSemDuplicidade === indicador._pessoaNome) {
                        quantidadeApontamento = quantidadeApontamento + 1;
                    }
                }

                dataset.data.push(quantidadeApontamento);
            }

            dataset.fill = false;
            dataset.borderColor = this.gerarCorAleatoria();
            dataset.tension = 0.4;

            datasets.push(dataset);
        }

        const dados = {};

        dados.labels = listaDataApontamentoSemDuplicidade.map(dataApontamentoSemDuplicidade => dataApontamentoSemDuplicidade.split("T")[0].split("-").join("/"))
        dados.datasets = datasets;

        this.setState({ listaDadosLojista: dados });
    }

    render() {
        return <div>
            <Header />

            <main style={{ height: "calc(100vh - 50px)", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 50, gap: 20 }}>
                <div style={{ width: '100%', height: '50%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5 }}>
                    <h2 style={{fontSize: 20, textAlign: "center", marginTop: 20, textDecoration: "underline", textDecorationThickness: 3, textDecorationColor: this.constante.corPrincipal}}>
                        Acesso ao aplicativo nos últimos 7 dias
                    </h2>
                    <Chart
                        style={{ width: "100%", height: "90%", padding: 20 }}
                        type="line"
                        data={{
                            labels: this.state.listaDataApontamentoGeral,
                            datasets: [{
                                label: "Acesso Geral",
                                data: this.state.listaQtdApontamentoGeral,
                                fill: false,
                                borderColor: this.constante.corPrincipal,
                                tension: 0.4
                            }]
                        }}
                        options={{
                            maintainAspectRatio: false,
                            aspectRatio: 0.6
                        }}
                    />
                </div>
                <div style={{ width: '100%', height: '50%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5 }}>
                    <h2 style={{fontSize: 20, textAlign: "center", marginTop: 20, textDecoration: "underline", textDecorationThickness: 3, textDecorationColor: this.constante.corPrincipal}}>
                        Acesso em loja nos últimos 7 dias
                    </h2>
                    <Chart
                        style={{ width: "100%", height: "90%", padding: 20 }}
                        type="line"
                        data={this.state.listaDadosLojista}
                        options={{
                            maintainAspectRatio: false,
                            aspectRatio: 0.6
                        }}
                    />
                </div>
            </main>
        </div>
    }
}