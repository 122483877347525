import { Component, createRef } from "react";
import Header from "../components/Header";
import { InputText } from "primereact/inputtext";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../../util/Constante";
import FormAlterarSenha from "../components/FormAlterarSenha";
import Util from "../../../util/Util";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Toast } from "primereact/toast";
import HeaderLojista from "../components/HeaderLojista";
import { InputSwitch } from "primereact/inputswitch";
import { Tag } from 'primereact/tag';
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";

class Banco {
    _codigo;
    _nome;
    _seqBanco;
}

export default class PerfilLojista extends Component {
    util = new Util();
    constante = new Constante();
    cepService = new CepService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaAdministrador: new Pessoa(),
        urlBase: '',
        endereco: new Endereco(),
        banco: new Banco(),
        carregando: false,
        tab: "Meu perfil",
        inputError: {
            nome: false,
            email: false,
            telefone: false,
            documento: false,
            responsavelDocumento: false,
            responsavelNome: false,
            banco: false,
            agencia: false,
            digitoAgencia: false,
            conta: false,
            digitoConta: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
            valorMinimoFrete: false,
        },
        contaAlterada: false,
        listaBanco: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let endereco = new Endereco();
        let banco = new Banco();
        let pessoaAdministrador = new Pessoa();

        const urlBase = await this.util.buscarUrlBaseArquivo();
        this.setState({ urlBase: urlBase });

        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const listaBanco = await this.util.listarBanco('');

        if (pessoaUsuario.contaCodigoBanco) {
            for (let i = 0; i < listaBanco.length; i++) {
                if (listaBanco[i]._codigo === pessoaUsuario.contaCodigoBanco) {
                    banco = listaBanco[i];
                }
            }
        }

        const retorno = await this.pessoaService.listarEndereco(pessoaUsuario);
        const listaEndereco = retorno.objeto;

        listaEndereco.forEach(pEndereco => {
            if (pessoaUsuario.seqEnderecoPrincipal === pEndereco._seqEndereco) {
                endereco = pEndereco;
            }
        })

        let retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador, this.constante.seqUsuario);
        pessoaAdministrador = retornoPessoa.objeto;

        this.setState({
            pessoaUsuario: pessoaUsuario,
            pessoaAdministrador: pessoaAdministrador,
            endereco: endereco,
            listaBanco: listaBanco,
            banco: banco,
        })
    }

    async salvarContaBanco(pPessoa) {
        this.setState({ carregando: true, });

        if (!pPessoa.contaDigitoAgencia) {
            pPessoa.contaDigitoAgencia = '0'
        }

        const retorno = await this.pessoaService.salvarContaBanco(pPessoa);
        pPessoa = retorno.objeto;

        if (!pPessoa.contaS2pCodigo) {
            this.toast.current.show([
                { severity: 'error', sticky: true, summary: 'Confira seus dados bancários e tente novamente' },
            ]);
        } else {
            const retornoPessoaEndereco = await this.pessoaService.salvar(pPessoa);
            pPessoa = retornoPessoaEndereco.objeto;

            this.util.salvarLocalStorage("PESSOA_USUARIO", pPessoa);

            this.toast.current.show([
                { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
            ]);
        }

        this.setState({ contaAlterada: false });

        return pPessoa;
    }

    async salvar(pPessoa, alterandoImagem) {
        const inputError = this.state.inputError;
        let endereco = this.state.endereco;

        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        this.toast.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        })

        if (!this.state.pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.state.pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.state.pessoaUsuario.documento) {
            inputError.documento = true;
        }

        if (this.state.pessoaUsuario.tipoDocumento === "CPF" && this.state.pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        }
        if (this.state.pessoaUsuario.tipoDocumento === "CNPJ" && this.state.pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        if (this.state.pessoaUsuario.tipoDocumento === "CNPJ") {
            if (!this.state.pessoaUsuario.contaResponsavelCpf) {
                inputError.responsavelDocumento = true;
            }

            if (!this.state.pessoaUsuario.contaResponsavelNome) {
                inputError.responsavelNome = true;
            }

            const retorno = await this.util.validarDocumento(this.state.pessoaUsuario.contaResponsavelCpf);

            if (!retorno) {
                inputError.responsavelDocumento = true;
            }
        }

        if (!this.state.endereco.cep) {
            inputError.cep = true;
        }

        if (!this.state.endereco.logradouro) {
            inputError.logradouro = true;
        }

        if (!this.state.endereco.numero) {
            inputError.numero = true;
        }

        if (!this.state.endereco.bairro) {
            inputError.bairro = true;
        }

        if (!this.state.endereco.cidade) {
            inputError.cidade = true;
        }

        if (!this.state.endereco.estado) {
            inputError.estado = true;
        }

        if (!this.state.endereco.uf) {
            inputError.uf = true;
        }

        if (this.state.pessoaUsuario.tags2 === "S") {
            if (!this.state.pessoaUsuario.tagn3 && this.state.pessoaUsuario.tagn3 !== 0) {
                inputError.valorMinimoFrete = true;
            }

            if (!this.state.pessoaUsuario.tagn5 && this.state.pessoaUsuario.tagn5 !== 0) {
                inputError.distanciaMaxima = true;
            }
        }

        let inputOK = true;

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        this.setState({ inputError: inputError });

        console.log(inputError);

        if (!inputOK && !alterandoImagem) {
            return;
        }

        this.setState({ carregando: true });

        const retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retornoPessoa.objeto;

        if (!pessoaUsuario.seqEnderecoPrincipal) {
            endereco.seqUsuario = this.constante.seqUsuario;
            endereco.seqPessoa = pessoaUsuario._seqPessoa;
            endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

            const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
            endereco = retornoEndereco.objeto;

            pessoaUsuario.seqEnderecoPrincipal = endereco._seqEndereco;

            const retornoPessoaEndereco = await this.pessoaService.salvar(pessoaUsuario);
            pessoaUsuario = retornoPessoaEndereco.objeto;

            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        } else {
            const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
            endereco = retornoEndereco.objeto;
        }

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        if (this.state.contaAlterada) {
            pessoaUsuario = await this.salvarContaBanco(pessoaUsuario)
        } else {
            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

            this.toast.current.show([
                { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
            ]);
        }

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
            carregando: false,
        });
    }


    async buscarCEP(pCep) {
        const endereco = this.state.endereco;

        const retorno = await this.cepService.buscarCep(pCep);

        endereco.bairro = retorno.bairro;
        endereco.complemento = retorno.complemento;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.logradouro = retorno.logradouro;
        endereco.uf = retorno.uf;

        this.setState({ endereco: endereco });
    }

    async selecionarArquivo(pArquivo) {
        const retorno = await this.util.pegarBase64(pArquivo);
        const pessoaUsuario = this.state.pessoaUsuario;

        pessoaUsuario.foto = retorno;

        this.salvar(pessoaUsuario, true);
    }

    async abrirFecharLoja() {
        let pessoa = { ...this.state.pessoaUsuario };

        if (pessoa.tags1 === 'S') {
            pessoa.tags1 = 'N'
        } else {
            pessoa.tags1 = 'S'
        }

        console.log(pessoa.tags1);

        const retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);

        this.setState({
            pessoaUsuario: pessoa
        });
    }

    async salvarFrete() {
        this.setState({ carregando: true });

        const pessoa = { ...this.state.pessoaUsuario };

        let inputError = {};
        let temErro = false;

        console.log(temErro);

        if (temErro) {
            this.setState({ inputError: inputError });
            return;
        }

        const retorno = await this.pessoaService.salvar(pessoa);
        const pessoaSalva = retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaSalva);

        this.toast.current.show([
            { severity: "success", summary: "Sucesso!", detail: "Informações armazenadas com sucesso!" }
        ]);

        this.setState({
            pesosaUsuario: pessoaSalva,
            carregando: false
        });
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro, paddingBottom: 100 }}>
            <HeaderLojista />

            <Toast ref={this.toast} />

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 20, display: 'flex', alignItems: 'flex-start', gap: 20 }}>
                <div style={{ width: 300, display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Button
                        icon="pi pi-home"
                        label="Meu perfil"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Meu perfil" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Meu perfil" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Meu perfil"
                        })} />
                    <Button
                        icon="pi pi-user"
                        label="Dados cadastrais"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Dados cadastrais" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Dados cadastrais" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Dados cadastrais"
                        })} />
                    <Button
                        icon="pi pi-lock"
                        label="Segurança"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Segurança" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Segurança" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Segurança"
                        })} />
                    <Button
                        icon="pi pi-dollar"
                        label="Taxas"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Taxas" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Taxas" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Taxas"
                        })} />
                    <Button
                        icon="pi pi-car"
                        label="Frete"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Frete" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Frete" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Frete"
                        })} />
                    <Button
                        icon="pi pi-dollar"
                        label="Desconto"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Desconto" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Desconto" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Desconto"
                        })} />
                </div>

                {this.state.tab === "Meu perfil" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 20 }}>
                        {this.state.carregando ?
                            <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    loading={true}
                                    label="Carregando"
                                    style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                            </div>
                            :
                            this.state.pessoaUsuario.foto ?
                                <div>
                                    <img
                                        src={this.state.urlBase + this.state.pessoaUsuario.foto}
                                        style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20, objectFit: "contain" }} />
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", marginLeft: -50, }}
                                    >
                                        <i className="pi pi-camera"></i>
                                    </label>
                                </div>
                                :
                                <div>
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", }}
                                    >
                                        <i className="pi pi-camera"></i> Adicionar imagem
                                    </label>
                                </div>}

                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-user" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.nome}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-at" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.emailPlataformaLirida}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-phone" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.telefonePlataformaLirida}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-clock" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>Usuário desde {this.util.formatarDataComTimestamp(this.state.pessoaUsuario.dataCadastro)}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <InputSwitch onChange={() => this.abrirFecharLoja()} checked={this.state.pessoaUsuario.tags1 === "S"} />
                            {this.state.pessoaUsuario.tags1 === 'S' ? (
                                <Tag value="Loja Aberta" severity="success" />
                            ) : (
                                <Tag value="Loja Fechada" severity="danger" />
                            )}
                        </div>
                    </div>}

                {this.state.tab === "Dados cadastrais" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seus dados</h1><br />

                        <form
                            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Nome
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.nome || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            nome: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.nome && 'var(--red-600)', marginTop: 5, marginTop: 5, }} />
                                {this.state.inputError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Email
                                </label>
                                <InputText
                                    disabled
                                    value={this.state.pessoaUsuario.emailPlataformaLirida || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            emailPlataformaLirida: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Telefone
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.telefonePlataformaLirida || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            telefonePlataformaLirida: this.util.formatarTelefone(e.target.value)
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.telefone && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Tipo de pessoa
                                </label>
                                <Dropdown
                                    options={[{ label: "Pessoa física", value: "CPF" }, { label: "Pessoa jurídica", value: "CNPJ" }]}
                                    value={this.state.pessoaUsuario.tipoDocumento || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tipoDocumento: e.value
                                        }
                                    })}
                                    style={{ width: '100%', marginTop: 5 }} />
                            </div>
                            {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                                <div>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        CPF
                                    </label>
                                    <InputText
                                        type="tel"
                                        value={this.state.pessoaUsuario.documento || ''}
                                        onChange={e => {
                                            if (e.target.value.length > 14) return;

                                            this.setState({
                                                pessoaUsuario: {
                                                    ...this.state.pessoaUsuario,
                                                    documento: this.util.formatarCPF(e.target.value)
                                                }
                                            })
                                        }}
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                                </div>}

                            {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                                <div>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        CNPJ
                                    </label>
                                    <InputText
                                        type="tel"
                                        value={this.state.pessoaUsuario.documento || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                documento: this.util.formatarCNPJ(e.target.value)
                                            }
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                                </div>}

                            <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seus dados bancários</h1>

                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Nome do responsável
                                </label>
                                <InputText
                                    disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                    value={this.state.pessoaUsuario.contaResponsavelNome || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            contaResponsavelNome: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.responsavelNome && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.responsavelNome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CPF do responsável
                                </label>
                                <InputText
                                    disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                    value={this.state.pessoaUsuario.contaResponsavelCpf || ''}
                                    onChange={e => {
                                        if (e.target.value.length > 14) return;

                                        this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaResponsavelCpf: this.util.formatarCPF(e.target.value)
                                            }
                                        })
                                    }}
                                    style={{ width: '100%', borderColor: this.state.inputError.responsavelDocumento && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.responsavelDocumento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Banco
                                </label>
                                <Dropdown
                                    filter
                                    options={[
                                        { ...new Banco(), _nome: 'Selecione' },
                                        ...this.state.listaBanco
                                    ]}
                                    optionLabel="_nome"
                                    value={this.state.banco}
                                    onChange={e => this.setState({
                                        banco: e.value,
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            contaCodigoBanco: e.value.codigo
                                        },
                                        contaAlterada: true
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.banco && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.banco && <small style={{ color: 'var(--red-600)' }}>Banco inválido</small>}
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <div style={{ width: '70%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Agência
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaAgencia || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaAgencia: e.target.value
                                            },
                                            contaAlterada: true,
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.agencia && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.agencia && <small style={{ color: 'var(--red-600)' }}>Agência inválida</small>}
                                </div>
                                <div style={{ width: '30%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Digito da agência
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaDigitoAgencia || ''}
                                        maxLength={3}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaDigitoAgencia: e.target.value
                                            },
                                            contaAlterada: true,
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.digitoAgencia && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.digitoAgencia && <small style={{ color: 'var(--red-600)' }}>Dígito da agência inválido</small>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <div style={{ width: '70%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Conta
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaNumeroConta || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaNumeroConta: e.target.value
                                            },
                                            contaAlterada: true
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.conta && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.conta && <small style={{ color: 'var(--red-600)' }}>Conta inválida</small>}
                                </div>
                                <div style={{ width: '30%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Digito da conta
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaDigitoConta || ''}
                                        maxLength={3}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaDigitoConta: e.target.value
                                            },
                                            contaAlterada: true
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.digitoConta && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.digitoConta && <small style={{ color: 'var(--red-600)' }}>Dígito da conta inválido</small>}
                                </div>
                            </div>
                            {this.state.pessoaUsuario.contaS2pCodigo &&
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <i className="pi pi-check" style={{ color: "green", fontSize: 20 }}></i>
                                    <p>Conta cadastrada</p>
                                </div>
                            }

                            <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seu endereço</h1>

                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CEP
                                </label>
                                <div className="p-inputgroup">
                                    <InputText
                                        type="tel"
                                        onChange={e => this.setState({
                                            endereco: {
                                                ...this.state.endereco,
                                                cep: this.util.formatarCEP(e.target.value),
                                            }
                                        })}
                                        value={this.state.endereco.cep}
                                        style={{ width: '100%', borderColor: this.state.inputError.cep && 'var(--red-600)', marginTop: 5 }} />
                                    <Button
                                        icon="pi pi-search"
                                        type="button"
                                        onClick={() => this.buscarCEP(this.state.endereco.cep)} />
                                </div>
                                {this.state.inputError.cep && <small style={{ color: 'var(--red-600)' }}>Cep inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Logradouro
                                </label>
                                <InputText
                                    value={this.state.endereco.logradouro}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            logradouro: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.logradouro && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.logradouro && <small style={{ color: 'var(--red-600)' }}>Logradouro inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Número
                                </label>
                                <InputText
                                    value={this.state.endereco.numero}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            numero: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.numero && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.numero && <small style={{ color: 'var(--red-600)' }}>Número inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Bairro
                                </label>
                                <InputText
                                    value={this.state.endereco.bairro}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            bairro: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.bairro && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.bairro && <small style={{ color: 'var(--red-600)' }}>Bairro inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Cidade
                                </label>
                                <InputText
                                    value={this.state.endereco.cidade}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            cidade: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.cidade && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.cidade && <small style={{ color: 'var(--red-600)' }}>Cidade inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Estado
                                </label>
                                <InputText
                                    value={this.state.endereco.estado}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            estado: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.estado && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.estado && <small style={{ color: 'var(--red-600)' }}>Estado inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    UF
                                </label>
                                <InputText
                                    value={this.state.endereco.uf}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            uf: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.uf && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.uf && <small style={{ color: 'var(--red-600)' }}>UF inválido</small>}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    loading={this.state.carregando}
                                    type="submit"
                                    label='Salvar'
                                    className="p-button-success" />
                            </div>
                        </form>
                    </div>}

                {this.state.tab === "Segurança" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Altere sua senha</h1><br />

                        <FormAlterarSenha />
                    </div>}

                {this.state.tab === "Taxas" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Taxas</h1><br />

                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }} >
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Ganho da plataforma
                                </label>
                                <InputText
                                    disabled
                                    value={`${this.state.pessoaAdministrador.tagn3}%`}
                                    style={{ width: '100%' }} />
                            </div>
                        </form>
                    </div>}

                {this.state.tab === "Frete" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Frete</h1><br />

                        <form
                            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Valor mínimo
                                </label>
                                <InputNumber
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-br"
                                    style={{ width: '100%', marginTop: 5, }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn0: pEvento.value,
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn0} />
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Distância mínima (KM)
                                </label>
                                <InputNumber
                                    style={{ width: '100%', marginTop: 5, }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn1: pEvento.value,
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn1} />
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Valor / KM
                                </label>
                                <InputNumber
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-br"
                                    style={{ width: '100%', marginTop: 5, }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn2: pEvento.value,
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn2} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <Checkbox
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tags2: e.checked ? "S" : null
                                        }
                                    })}
                                    checked={this.state.pessoaUsuario.tags2 === "S"}
                                />
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Frete Grátis
                                </label>
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Valor mínimo do pedido para Frete Grátis
                                </label>
                                <InputNumber
                                    className={this.state.inputError.valorMinimoFrete && "p-invalid"}
                                    disabled={this.state.pessoaUsuario.tags2 !== "S"}
                                    mode="currency"
                                    currency="BRL"
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn3: e.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn3}
                                    style={{ width: '100%' }} />
                                {this.state.inputError.valorMinimoFrete &&
                                    <small style={{ color: "red" }}>Valor mínimo do pedido para frete grátis obrigatório</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Distância Máxima para Frete Gŕatis (KM)
                                </label>
                                <InputNumber
                                    className={this.state.inputError.distanciaMaxima && "p-invalid"}
                                    disabled={this.state.pessoaUsuario.tags2 !== "S"}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn5: e.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn5}
                                    style={{ width: '100%' }} />
                                {this.state.inputError.distanciaMaxima &&
                                    <small style={{ color: "red" }}>Distância máxima para frete grátis obrigatória</small>}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    loading={this.state.carregando}
                                    type="submit"
                                    label='Salvar'
                                    className="p-button-success" />
                            </div>
                        </form>
                    </div>}

                {this.state.tab === "Desconto" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Desconto</h1><br />

                        <form
                            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Percentual de desconto
                                </label>
                                <InputNumber
                                    suffix="%"
                                    style={{ width: '100%', marginTop: 5, }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn4: pEvento.value,
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn4} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    loading={this.state.carregando}
                                    type="submit"
                                    label='Salvar'
                                    className="p-button-success" />
                            </div>
                        </form>
                    </div>}
            </main>
        </div>
    }
}