import { Component, createRef } from "react";
import Header from "../components/Header";
import ItemCategoria from 'lirida-back-service/Servicos/ItemCategoria/ItemCategoria';
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

export default class ItemCategoriaPg extends Component {
    constante = new Constante();
    util = new Util();
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);

    toast = createRef();

    state = {
        urlBase: "",
        pessoaUsuario: new Pessoa(),
        itemCategoria: new ItemCategoria(),
        listaItemCategoria: [],
        inputError: {
            codigo: false,
            nome: false,
            descricao: false,
            categoria: false,
            situacao: false,
        },
        base64: "",
        imagemPreview: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar();

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        })
    }

    async listar() {
        const retornoListaItemCategoria = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        const listaItemCategoria = retornoListaItemCategoria.objeto;

        console.log(listaItemCategoria);

        this.setState({ listaItemCategoria: listaItemCategoria })
    }

    selecionar(pItemCategoria) {
        this.setState({
            itemCategoria: pItemCategoria,
            itemCategoriaDetalheVisivel: true,
            imagemPreview: '',
        })
    }

    async salvarSituacao(pItemCategoria) {
        const retornoItem = await this.itemCategoriaService.salvar(pItemCategoria);
        pItemCategoria = retornoItem.objeto;

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            itemCategoria: pItemCategoria,
        });
    }

    async salvar(pItemCategoria) {
        const inputError = this.state.inputError;
        let inputOK = true;
        let itemCategoria;

        if (pItemCategoria) {
            itemCategoria = pItemCategoria
        } else {
            itemCategoria = this.state.itemCategoria;
        }

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!itemCategoria.codigo) {
            inputError.codigo = true;
        }

        if (!itemCategoria.nome) {
            inputError.nome = true;
        }

        if (!itemCategoria.descricao) {
            inputError.descricao = true;
        }

        if (!itemCategoria.situacao) {
            inputError.situacao = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ salvarCarregando: true });

        itemCategoria.seqUsuario = this.constante.seqUsuario;

        if (this.state.base64) {
            itemCategoria.imagem = this.state.base64;
        }

        const retornoItem = await this.itemCategoriaService.salvar(itemCategoria);
        itemCategoria = retornoItem.objeto;

        this.toast.current.show([
            { severity: 'success', summary: 'Categoria cadastrada com sucesso!' },
        ]);

        this.setState({
            itemCategoria: itemCategoria,
            salvarCarregando: false,
            base64: "",
            itemCategoriaDetalheVisivel: false,
        });

        this.listar();
    }

    async selecionarArquivo(pArquivo) {
        const retorno = await this.util.pegarBase64(pArquivo);
        const itemCategoria = this.state.itemCategoria;

        const objetoUrl = URL.createObjectURL(pArquivo);

        itemCategoria.imagem = retorno;

        this.setState({
            base64: retorno,
            imagemPreview: objetoUrl,
            itemCategoria: itemCategoria
        });
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro }}>
            <Header />

            <Toast ref={this.toast} />

            <Dialog
                header="Cadastrar nova categoria"
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                contentStyle={{ padding: 0 }}
                visible={this.state.itemCategoriaDetalheVisivel}
                onHide={() => this.setState({ itemCategoriaDetalheVisivel: false })}>
                <form onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 20, padding: 20 }}>
                        {this.state.imagemPreview ?
                            <div style={{ margin: '0 auto' }}>
                                <img
                                    src={this.state.imagemPreview}
                                    style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20 }} />
                                <input
                                    type="file" id="imagem_item" style={{ display: 'none' }}
                                    onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                <label
                                    htmlFor="imagem_item"
                                    style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", marginLeft: -50, }}
                                >
                                    <i className="pi pi-camera"></i>
                                </label>
                            </div>
                            :
                            this.state.itemCategoria.imagem ?
                                <div style={{ margin: '0 auto' }}>
                                    <img
                                        src={this.state.urlBase + this.state.itemCategoria.imagem}
                                        style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20 }} />
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", marginLeft: -50, }}
                                    >
                                        <i className="pi pi-camera"></i>
                                    </label>
                                </div>
                                :
                                <div style={{ margin: '20px auto' }}>
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", }}
                                    >
                                        <i className="pi pi-camera"></i> Adicionar imagem
                                    </label>
                                </div>}

                        {this.state.itemCategoria._seqItemCategoria && <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                ID
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%" }}
                                value={this.state.itemCategoria._seqItemCategoria} />
                        </div>}
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Código
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.codigo && 'red' }}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        codigo: pEvento.target.value
                                    }
                                })}
                                value={this.state.itemCategoria.codigo} />
                            {this.state.inputError.codigo && <small style={{ color: 'red' }}>Código inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.itemCategoria.nome} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Descrição
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.descricao && 'red' }}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        descricao: pEvento.target.value
                                    }
                                })}
                                value={this.state.itemCategoria.descricao} />
                            {this.state.inputError.descricao && <small style={{ color: 'red' }}>Descrição inválida</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Situação
                            </label>
                            <br />
                            <Dropdown
                                placeholder="Selecione uma situação"
                                style={{ width: "100%", borderColor: this.state.inputError.situacao && 'red' }}
                                options={[
                                    { label: "ATIVO", value: "ATIVO" },
                                    { label: "INATIVO", value: "INATIVO" },
                                ]}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        situacao: pEvento.value
                                    }
                                })}
                                value={this.state.itemCategoria.situacao} />
                            {this.state.inputError.situacao && <small style={{ color: 'red' }}>Situação inválida</small>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro }}>
                        <Button
                            type="button"
                            label="Cancelar"
                            className="p-button-outlined p-button-secondary"
                            style={{ backgroundColor: 'white' }}
                            onClick={() => this.setState({
                                itemCategoriaDetalheVisivel: false
                            })} />
                        <Button
                            loading={this.state.salvarCarregando}
                            type="submit"
                            label="Salvar"
                            className="p-button-success" />
                    </div>
                </form>
            </Dialog>

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 20, }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ width: "20%", fontSize: 20 }}>Lista de categorias</h1>

                    <Button
                        label="+ Cadastrar nova categoria"
                        style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                        onClick={() => {
                            let itemCategoria = new ItemCategoria();

                            itemCategoria.situacao = "ATIVO";

                            this.setState({
                                itemCategoria: itemCategoria,
                                itemCategoriaDetalheVisivel: true
                            })
                        }} />
                </div>

                {this.state.carregando &&
                    <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            loading={true}
                            label="Carregando"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                    </div>}

                {this.state.listaItemCategoria.map(pItem =>
                    <div
                        style={{ backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, }}>
                            <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    {pItem.codigo}
                                </p>
                            </span>
                            <p
                                key={Math.random()}
                                style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline', textDecorationColor: this.constante.corPrincipal }}
                                onClick={() => this.selecionar(pItem)}>
                                {pItem.nome}
                            </p>
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 10 }}>
                            <InputSwitch checked={pItem.situacao === "ATIVO" ? true : false} onChange={pEvento => {
                                if (pEvento.value === false) {
                                    pItem.situacao = "INATIVO";
                                }

                                if (pEvento.value === true) {
                                    pItem.situacao = "ATIVO";
                                }

                                this.salvarSituacao(pItem);
                            }} />

                            <span
                                key={Math.random()}
                                style={{ backgroundColor: pItem.situacao === "ATIVO" ? this.constante.corVerdeClaro : this.constante.corVermelhoClaro, padding: 5, borderRadius: 5, }}>
                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 13, color: this.constante.corPrincipal, fontWeight: 'bold', color: pItem.situacao === "ATIVO" ? this.constante.corVerdeEscuro : this.constante.corVermelhoEscuro, textAlign: 'center' }}>
                                    {pItem.situacao}
                                </p>
                            </span>
                        </div>
                    </div>)}
            </main >

            {/* {this.state.tela === "2" ?
                <ItemCategoriaDetalhe
                    listar={() => this.listar()}
                    setState={pState => this.setState(pState)}
                    itemCategoria={this.state.itemCategoriaCategoria} />
                :
                <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                        <h1 style={{ fontSize: 22.5 }}>Cadastro de categoria</h1>

                        <Button
                            label="Novo"
                            onClick={() => this.setState({
                                tela: "2",
                            })}
                            className="p-button-raised" />
                    </div>

                    <DataTable
                        style={{ marginTop: 20 }}
                        selectionMode="single"
                        showGridlines
                        removableSort
                        value={this.state.listaItemCategoria}
                        onSelectionChange={pEvento => {
                            this.setState({
                                itemCategoria: pEvento.value,
                                tela: "2",
                            })
                        }}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        <Column header="Foto" body={pItem =>
                            <div style={{ width: 100, height: 100, backgroundColor: "lightgray", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {pItem.imagem ?
                                <img
                                    src={this.state.urlBase + pItem.imagem}
                                    style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                :
                                <p>sem imagem</p>
                            }
                        </div>
                        }></Column>
                        <Column field="codigo" header="Código" sortable></Column>
                        <Column field="nome" header="Nome" sortable></Column>
                        <Column field="descricao" header="Descrição" sortable></Column>
                        <Column field="situacao" header="Situação" sortable></Column>
                    </DataTable>
                </main>
            } */}
        </div >
    }
}