import { Sidebar } from "primereact/sidebar";
import { Component } from "react";
import headerLogo from '../../../assets/imagens/logo.png';

export default class HeaderHotsite extends Component {

    state = {
        sidebarVisivel: false,
    }

    render() {
        return <>
            <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
                    <a
                        onClick={() => { this.setState({ sidebarVisivel: false }); window.location.href = "/#sobre" }}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#sobre">Sobre</a>
                    <a
                        onClick={() => { this.setState({ sidebarVisivel: false }); window.location.href = "/#funcionalidades" }}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#funcionalidades">Funcionalidades</a>
                    <a
                        onClick={() => { this.setState({ sidebarVisivel: false }); window.location.href = "/#depoimentos" }}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#depoimentos">Depoimentos</a>
                    <a
                        onClick={() => { this.setState({ sidebarVisivel: false }); window.location.href = "/#perguntas-frequentes" }}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#perguntas-frequentes">Perguntas frequentes</a>
                    <a
                        onClick={() => { this.setState({ sidebarVisivel: false }); window.location.href = "/cadastro_fabricante" }}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="/cadastro_fabricante">Fabricante</a>
                    <a
                        onClick={() => { this.setState({ sidebarVisivel: false }); window.location.href = "/login" }}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="/login">Login</a>
                </div>
            </Sidebar>

            <div className="h-header">
                <header className="h-header-container">
                    <img src={headerLogo} alt="logo" onClick={() => window.location.href = "/"} />

                    <nav>
                        <a href="#sobre" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/#sobre" }}>Sobre</a>
                        <a href="#funcionalidades" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/#funcionalidades" }}>Funcionalidades</a>
                        <a href="#depoimentos" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/#depoimentos" }}>Depoimentos</a>
                        {/* <a href="#plataformas" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/#plataformas" }}>Plataformas</a> */}
                        <a href="#perguntas-frequentes" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/#perguntas-frequentes" }}>Perguntas frequentes</a>
                        <a href="/cadastro_fabricante" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/cadastro_fabricante" }}>Fabricante</a>
                        <a href="/login" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/login" }}>Login</a>

                        <button onClick={() => this.setState({ sidebarVisivel: !this.state.sidebarVisivel })}>
                            <i className="pi pi-bars"></i>
                        </button>
                    </nav>
                </header>
            </div>
        </>
    }
}