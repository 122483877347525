import { Component } from "react";
import footerLogo from '../../../assets/imagens/logo-branca.png';
import letsEncrypt from '../../../assets/imagens/letsencrypt.png';
import certificadoSSL from '../../../assets/imagens/certificadossl.png';
import httpsWww from '../../../assets/imagens/httpswww.png';
import bandeirasCartaoCredito from '../../../assets/imagens/bandeirascartaodecredito.png';
import disponivelPlayStore from '../../../assets/imagens/disponivelplaystore.png';
import disponivelAppleStore from '../../../assets/imagens/disponivelapple.png';

export default class FooterHotsite extends Component {
    render() {
        return <>
            <div className="h-footer">
                <footer className="h-footer-container">
                    <section>
                        <div>
                            <a href="/#sobre">Sobre</a>
                            <a href="/#funcionalidades">Funcionalidades</a>
                            <a href="/#depoimentos">Depoimentos</a>
                            <a href="/#perguntas-frequentes">Perguntas frequentes</a>
                        </div>
                        <div>
                            <a href="#" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/politica_de_privacidade" }}>Política de privacidade</a>
                            <a href="#" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/termo_de_uso" }}>Termo de uso</a>
                        </div>
                        {/* <div>
                            <a href="https://www.facebook.com/crossystem.tec" target="_blank" rel="noreferrer">Facebook</a>
                            <a href="https://www.instagram.com/crosssystemtecnologia" target="_blank" rel="noreferrer">Instagram</a>
                        </div> */}
                    </section>


                    <h2 style={{ fontSize: 20, textAlign: 'center', color: 'white', marginTop: 50 }}>Baixe agora!</h2>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20 }}>
                        <img
                            src={disponivelPlayStore} alt="Disponível na Play Store"
                            style={{ width: 200, height: 100, objectFit: 'contain', cursor: 'pointer' }}
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.liridazemarceneiromobile')} />
                        <img
                            src={disponivelAppleStore} alt="Disponível na Apple Store"
                            style={{ width: 200, height: 100, objectFit: 'contain', cursor: 'pointer' }}
                            onClick={() => window.open('https://apps.apple.com/us/app/z%C3%A9-marceneiro/id6444013758')} />
                    </div>

                    <div>
                        <img
                            src={bandeirasCartaoCredito} alt="Cartões de crédito"
                            style={{ width: 300, height: 100, objectFit: 'contain' }} />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20 }}>
                        <img
                            src={letsEncrypt} alt="Certificado de segurança Let's Encrypt"
                            style={{ width: 100, height: 100, objectFit: 'contain' }} />
                        <img
                            src={certificadoSSL} alt="Certificado SSL"
                            style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>

                    <img src={footerLogo} alt="logo rodapé" />
                </footer>
            </div>
        </>
    }
}