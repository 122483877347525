import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Component, createRef } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import Header from "../components/Header";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import GoogleAPI from "lirida-back-service/Servicos/Google/GoogleAPI";
import { Toast } from "primereact/toast";
import { InputSwitch } from 'primereact/inputswitch';


export default class Fabricante extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base);
    googleApi = new GoogleAPI(this.constante.token, this.constante.url_base);
    toast = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        endereco: new Endereco(),
        conteudoEndereco: '',
        pesquisaEnderecoVisivel: false,
        listaPessoa: [],
        conteudo: "",
        fabricanteDetalheVisivel: false,
        carregando: false,
        salvarCarregando: false,
        listaEndereco: [],
        inputError: {
            nome: false,
            apelido: false,
            tipoPessoa: false,
            documento: false,
            email: false,
            telefone: false,
            endereco: false,
            situacao: false,
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar();

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        });
    }

    async listar() {
        const listaPesquisa = [];

        this.setState({
            carregando: true,
            listaPessoa: [],
        })

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_pessoa_perfil";
        pesquisa.conteudo = this.constante.seqPessoaPerfilFabricante;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.nome";
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);
        const listaPessoa = retornoListaPessoa.objeto;

        this.setState({
            carregando: false,
            listaPessoa: listaPessoa
        });
    }

    async listarEndereco(pTexto) {
        if (pTexto.length < 3) return;

        const retornoListaEndereco = await this.googleApi.listarEndereco(pTexto);
        this.setState({ listaEndereco: retornoListaEndereco.predictions });
    }

    async buscarEndereco(pEndereco) {
        const retornoEndereco = await this.googleApi.buscarEndereco(pEndereco.place_id);
        const endereco = this.state.endereco;

        retornoEndereco.result.address_components.forEach(pEndereco => {
            if (pEndereco.types.includes("street_number")) {
                endereco.numero = pEndereco.long_name;
            }
            if (pEndereco.types.includes("route")) {
                endereco.logradouro = pEndereco.long_name;
            }
            if (pEndereco.types.includes("sublocality_level_1")) {
                endereco.bairro = pEndereco.long_name;
            }
            if (pEndereco.types.includes("administrative_area_level_2")) {
                endereco.cidade = pEndereco.long_name;
            }
            if (pEndereco.types.includes("administrative_area_level_1")) {
                endereco.estado = pEndereco.long_name;
                endereco.uf = pEndereco.short_name;
            }
            if (pEndereco.types.includes("country")) {
                endereco.pais = pEndereco.long_name;
            }
            if (pEndereco.types.includes("postal_code")) {
                endereco.cep = pEndereco.long_name;
            }
        });

        endereco.latitude = retornoEndereco.result.geometry.location.lat;
        endereco.longitude = retornoEndereco.result.geometry.location.lng;

        console.log(endereco);

        this.setState({
            endereco: endereco,
            pesquisaEnderecoVisivel: false,
            listaEndereco: [],
            conteudoEndereco: pEndereco.description,
            pessoa: {
                ...this.state.pessoa,
                tags0: pEndereco.description,
                latitude: endereco.latitude,
                longitude: endereco.longitude,
            }
        });
    }

    async selecionar(pPessoa) {
        let endereco = this.state.endereco;

        const retornoListaEndereco = await this.pessoaService.listarEndereco(pPessoa);
        const listaEndereco = retornoListaEndereco.objeto;

        listaEndereco.forEach(pEndereco => {
            if (pEndereco._seqEndereco === pPessoa.seqEnderecoPrincipal) {
                endereco = pEndereco;
            }
        })

        this.setState({
            pessoa: pPessoa,
            conteudoEndereco: pPessoa.tags0,
            endereco: endereco,
            fabricanteDetalheVisivel: true,
            inputError: {
                nome: false,
                tipoPessoa: false,
                documento: false,
                email: false,
                telefone: false,
                endereco: false,
                situacao: false,
            },
        });
    }

    async salvarStatus(pPessoa) {
        let retorno = await this.pessoaService.salvar(pPessoa);
        pPessoa = retorno.objeto;

        if (pPessoa.status === 1) {
            let apiEmail = {
                seqEmail: this.constante.seqEmailAcesso,
                email: pPessoa.emailPlataformaLirida,
                parametro: {
                    nome: pPessoa.nome,
                    email: pPessoa.emailPlataformaLirida,
                    senha: pPessoa.senha
                }
            }

            this.util.enviarEmail(apiEmail);
        }

        this.toast.current.show([
            { severity: 'success', summary: 'Status atualizado com sucesso!' },
        ]);

        this.setState({ pessoa: pPessoa });
    }

    async salvar() {
        const inputError = this.state.inputError;
        // let tabelaPreco = new TabelaPreco();
        let endereco = this.state.endereco;
        let inputOK = true;
        // let enviarEmail = true;
        let pessoa = this.state.pessoa;

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!pessoa.nome) {
            inputError.nome = true;
        }

        if (!pessoa.apelido) {
            inputError.apelido = true;
        }

        if (!pessoa.tipoDocumento) {
            inputError.tipoPessoa = true;
        }

        if (!pessoa.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(pessoa.documento)) {
            inputError.documento = true;
        } else if (pessoa.tipoDocumento === "CPF" && pessoa.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoa.tipoDocumento === "CNPJ" && pessoa.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!pessoa.situacao) {
            inputError.situacao = true;
        }

        if (!pessoa.tags0) {
            inputError.endereco = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        });

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ salvarCarregando: true });

        // if (pessoa._seqPessoa) {
        //     enviarEmail = false;
        // }

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilFabricante;

        const retornoPessoa = await this.pessoaService.salvar(pessoa);
        pessoa = retornoPessoa.objeto;

        console.log(pessoa);

        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pessoa._seqPessoa;

        const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
        endereco = retornoEndereco.objeto;

        pessoa.seqEnderecoPrincipal = endereco._seqEndereco;

        await this.pessoaService.salvar(pessoa);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            salvarCarregando: false,
            fabricanteDetalheVisivel: false,
        });

        this.listar();
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro }}>
            <Header />

            <Toast ref={this.toast} />

            <Dialog
                header={this.state.pessoa._seqPessoa ? "Detalhe do fabricante" : "Cadastrar novo fabricante"}
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                contentStyle={{ padding: 0 }}
                visible={this.state.fabricanteDetalheVisivel}
                onHide={() => this.setState({ fabricanteDetalheVisivel: false })}>
                <form onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 20, padding: 20 }}>
                        {this.state.pessoa._seqPessoa && <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                ID
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa._seqPessoa} />
                        </div>}
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.nome && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome fantasia
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.apelido && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        apelido: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.apelido} />
                            {this.state.inputError.apelido && <small style={{ color: 'red' }}>Nome fantasia inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Tipo de pessoa
                            </label>
                            <br />
                            <Dropdown
                                disabled
                                placeholder="Selecione um tipo de pessoa"
                                style={{ width: "100%", borderColor: this.state.inputError.tipoPessoa && 'red', marginTop: 5 }}
                                options={[
                                    { label: "Pessoa física", value: "CPF" },
                                    { label: "Pessoa jurídica", value: "CNPJ" },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoa.tipoDocumento} />
                            {this.state.inputError.tipoPessoa && <small style={{ color: 'red' }}>Tipo de pessoa inválido</small>}
                        </div>
                        {this.state.pessoa.tipoDocumento === "CPF" &&
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CPF
                                </label>
                                <br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.documento && 'red', marginTop: 5 }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        {this.state.pessoa.tipoDocumento === "CNPJ" &&
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CNPJ
                                </label>
                                <br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.documento && 'red', marginTop: 5 }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Email de Acesso
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.email && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Senha de Acesso
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.senha} />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Telefone
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.telefone && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Situação
                            </label>
                            <br />
                            <Dropdown
                                placeholder="Selecione uma situação"
                                style={{ width: "100%", borderColor: this.state.inputError.situacao && 'red', marginTop: 5 }}
                                options={[
                                    { label: "ATIVO", value: "ATIVO" },
                                    { label: "INATIVO", value: "INATIVO" },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        situacao: pEvento.value
                                    }
                                })}
                                value={this.state.pessoa.situacao} />
                        </div>

                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Endereço
                            </label>
                            <br />
                            <div>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    {!this.state.pesquisaEnderecoVisivel && this.state.endereco.logradouro ?
                                        <i className="pi pi-check" />
                                        :
                                        <i className="pi pi-search" />
                                    }
                                    <InputText
                                        style={{ width: '100%', borderColor: this.state.inputError.endereco && 'red', marginTop: 5 }}
                                        onChange={pEvento => {
                                            this.setState({
                                                conteudoEndereco: pEvento.target.value,
                                                pesquisaEnderecoVisivel: true
                                            });
                                            this.listarEndereco(pEvento.target.value)
                                        }}
                                        value={this.state.conteudoEndereco || ""} />
                                </span>
                                {this.state.inputError.endereco && <small style={{ color: 'red' }}>Endereço inválido</small>}
                            </div>

                            {this.state.listaEndereco.map(pEndereco =>
                                <Button
                                    type="button"
                                    key={Math.random()}
                                    label={pEndereco.description}
                                    className="p-button-link"
                                    style={{ width: "100%", textAlign: 'left' }}
                                    onClick={() => this.buscarEndereco(pEndereco)} />
                            )}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Complemento
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", marginTop: 5, }}
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.endereco.complemento} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro }}>
                        <Button
                            type="button"
                            label="Cancelar"
                            className="p-button-outlined p-button-secondary"
                            style={{ backgroundColor: 'white' }}
                            onClick={() => this.setState({
                                fabricanteDetalheVisivel: false
                            })} />
                        <Button
                            loading={this.state.salvarCarregando}
                            type="submit"
                            label="Salvar"
                            className="p-button-success" />
                    </div>
                </form>
            </Dialog>

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 20, }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                    <h1 style={{ width: "20%", fontSize: 20 }}>Lista de fabricantes</h1>

                    <form
                        style={{ width: "60%", display: 'flex', alignItems: 'center' }}
                        onSubmit={pEvento => { pEvento.preventDefault(); this.listar(); }}>
                        <InputText
                            placeholder="Pesquisar fabricante"
                            style={{ width: "80%" }}
                            onChange={pEvento => this.setState({
                                conteudo: pEvento.target.value
                            })} />
                        <Button
                            label="Pesquisar"
                            loading={this.state.pesquisarCarregando}
                            type="submit"
                            icon="pi pi-search"
                            iconPos="left"
                            style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }} />
                    </form>

                    <div style={{ width: "20%", display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            label="+ Cadastrar novo fabricante"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                            onClick={() => {
                                let pessoa = new Pessoa();

                                pessoa.situacao = "ATIVO";
                                pessoa.tipoDocumento = "CNPJ";

                                this.setState({
                                    fabricanteDetalheVisivel: true,
                                    pessoa: pessoa,
                                    endereco: new Endereco(),
                                    conteudoEndereco: '',
                                    pesquisaEnderecoVisivel: false,
                                    inputError: {
                                        nome: false,
                                        apelido: false,
                                        tipoPessoa: false,
                                        documento: false,
                                        email: false,
                                        telefone: false,
                                        endereco: false,
                                        situacao: false,
                                    },
                                })
                            }} />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", backgroundColor: this.constante.corAzulMarinhoMedio, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20 }}>
                    <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                        Fabricante
                    </p>
                    <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                        Status
                    </p>
                </div>

                {this.state.carregando &&
                    <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            loading={true}
                            label="Carregando"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                    </div>}

                {(!this.state.carregando && this.state.listaPessoa.length === 0) &&
                    <p style={{ textAlign: "center", marginTop: 20 }}>Nenhum fabricante listado</p>}

                {this.state.listaPessoa.map(pPessoa =>
                    <div
                        key={Math.random()}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20 }}
                    >
                        <div>
                            <p
                                key={Math.random()}
                                style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline', textDecorationColor: this.constante.corPrincipal }}
                                onClick={() => this.selecionar(pPessoa)}>
                                {pPessoa.nome}
                            </p>
                            <p
                                key={Math.random()}
                                style={{ fontSize: 14, color: this.constante.corPrincipal, marginTop: 5 }}>
                                {pPessoa.tags0}
                            </p>
                        </div>

                        <div
                            key={Math.random()}
                            style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>

                            <InputSwitch checked={pPessoa.status === 1} onChange={pEvento => {
                                if (pEvento.value === false) {
                                    pPessoa.status = 0;
                                }

                                if (pEvento.value === true) {
                                    pPessoa.status = 1;
                                }

                                this.salvarStatus(pPessoa);
                            }} />

                            <span
                                key={Math.random()}
                                style={{ backgroundColor: pPessoa.status === 0 ? this.constante.corVermelhoClaro : pPessoa.status === 1 ? this.constante.corVerdeClaro : 'lightgray', padding: 5, borderRadius: 5, }}>
                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 13, color: this.constante.corPrincipal, fontWeight: 'bold', color: pPessoa.status === 0 ? this.constante.corVermelhoEscuro : pPessoa.status === 1 ? this.constante.corVerdeEscuro : 'gray', textAlign: 'center' }}>
                                    {pPessoa.status === 0 ? "INATIVO" : pPessoa.status === 1 ? "ATIVO" : "Não informado"}
                                </p>
                            </span>
                        </div>
                    </div>)}
            </main>
        </div>
    }
}