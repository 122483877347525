import { Component } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import HeaderLojista from "../components/HeaderLojista";
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import TipoDocumentoProcessoService from 'lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcessoService';
import TipoDocumentoProcesso from 'lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export default class PedidoLojista extends Component {
    util = new Util();
    constante = new Constante();
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    tipoDocumentoProcessoService = new TipoDocumentoProcessoService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        documento: new Documento(),
        tipoDocumentoProcesso: new TipoDocumentoProcesso(),
        listaDocumento: [],
        carregando: false,
        seqTipoDocumentoProcesso: this.constante.seqTipoDocumentoProcessoAguardandoPagamento,
        listaTipoDocumentoProcesso: [],
        conteudo: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaTipoDocumentoProcesso = [];
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let retorno = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcesso(this.constante.seqTipoDocumento);
        listaTipoDocumentoProcesso = retorno.objeto;

        listaTipoDocumentoProcesso = listaTipoDocumentoProcesso.filter(
            item =>
                item._seqTipoDocumentoProcesso !== (this.constante.seqTipoDocumentoProcessoAguardandoPagamento) &&
                item._seqTipoDocumentoProcesso !== (this.constante.seqTipoDocumentoProcessoArquivado)
        );

        this.listar(listaTipoDocumentoProcesso[0], pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            tipoDocumentoProcesso: listaTipoDocumentoProcesso[0],
            listaTipoDocumentoProcesso: listaTipoDocumentoProcesso,
        });
    }

    async listar(pTipoDocumentoProcesso, pPessoaUsuario) {
        this.setState({
            carregando: true,
            listaDocumento: [],
            tipoDocumentoProcesso: pTipoDocumentoProcesso,
        });

        const listaPesquisa = [];
        let listaDocumento = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "documento.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento.seq_usuario_vendedor";
        pesquisa.conteudo = pPessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento.seq_usuario_vendedor";
        pesquisa.conteudo = this.state.pessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento.seq_tipo_documento_processo";
        pesquisa.conteudo = pTipoDocumentoProcesso._seqTipoDocumentoProcesso;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento.pago";
        pesquisa.conteudo = 'S';
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.nome";
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        const retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumento = retorno.objeto;

        listaDocumento.sort((a, b) => {
            return new Date(this.util.formatarDataBancoParaObjetoData(b.dataCadastro)) - new Date(this.util.formatarDataBancoParaObjetoData(a.dataCadastro));
        });

        this.setState({
            listaDocumento: listaDocumento,
            carregando: false,
        });
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro }}>
            <HeaderLojista />

            <Toast ref={this.toast} />

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', display: "flex", alignItems: 'flex-start', gap: 20, }}>
                <div style={{ width: 500, backgroundColor: 'white', padding: 20, marginTop: 20, marginLeft: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                    <h2 style={{ fontSize: 16, color: this.constante.corAzulMarinhoEscuro }}>FILTRO</h2>

                    <h3 style={{ fontSize: 18, color: this.constante.corPrincipal, marginTop: 20 }}>Processo</h3>

                    <div style={{ display: "flex", flexDirection: 'column', gap: 5, marginTop: 20 }}>
                        {this.state.listaTipoDocumentoProcesso.map(pTipoDocumentoProcesso =>
                            <Button
                                label={pTipoDocumentoProcesso.nome}
                                icon={this.state.tipoDocumentoProcesso === pTipoDocumentoProcesso ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(pTipoDocumentoProcesso, this.state.pessoaUsuario)} />)}
                    </div>
                </div>

                <div style={{ width: "100%", padding: 20, paddingLeft: 0, marginTop: 15 }}>
                    <h1 style={{ width: "20%", fontSize: 20 }}>Lista de pedidos</h1>

                    <form
                        style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
                        onSubmit={pEvento => { pEvento.preventDefault(); this.listar(this.state.tipoDocumentoProcesso, this.state.pessoaUsuario); }}>
                        <InputText
                            placeholder="Pesquisar item"
                            style={{ width: "80%" }}
                            onChange={pEvento => this.setState({
                                conteudo: pEvento.target.value
                            })} />
                        <Button
                            label="Pesquisar"
                            loading={this.state.pesquisarCarregando}
                            type="submit"
                            icon="pi pi-search"
                            iconPos="left"
                            style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }} />
                    </form>

                    {this.state.carregando &&
                        <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                            <Button
                                loading={true}
                                label="Carregando"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                        </div>}

                    {(!this.state.carregando && this.state.listaDocumento.length === 0) &&
                        <p style={{ textAlign: 'center', marginTop: 20 }}>Nenhum pedido listado</p>}

                    {this.state.listaDocumento.map(pDocumento =>
                        <div
                            style={{ backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, cursor: 'pointer' }}
                            onClick={() => window.open("/sistema/lojista/pedido_detalhe?id=" + pDocumento._seqDocumento)}>
                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, }}>
                                    <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                        <p
                                            key={Math.random()}
                                            style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                            {pDocumento._codigo}
                                        </p>
                                    </span>
                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        {pDocumento._pessoaNome}
                                    </p>
                                </div>

                                <p style={{fontSize: 16, color: this.constante.corPrincipal}}>
                                    {this.util.formatarDataComTimestamp(pDocumento.dataCadastro)}
                                </p>
                            </div>
                            <p
                                key={Math.random()}
                                style={{ marginTop: 10, color: this.constante.corPrincipal }}>
                                {pDocumento._endLogradouro}, {pDocumento._endNumero}, {pDocumento._endComplemento && pDocumento._endComplemento + ', '} {pDocumento._endCidade}, {pDocumento._endUF}
                            </p>
                        </div>)}
                </div>
            </main>
        </div >
    }
}