import { Component, createRef } from "react";
import Header from "../components/Header";
import ItemService from 'lirida-back-service/Servicos/Item/ItemService';
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Item from "lirida-back-service/Servicos/Item/Item";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import ItemCategoria from "lirida-back-service/Servicos/ItemCategoria/ItemCategoria";
import { Toast } from "primereact/toast";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import ItemRelacionamento from 'lirida-back-service/Servicos/ItemRelacionamento/ItemRelacionamento';
import { InputSwitch } from "primereact/inputswitch";
import * as XLSX from 'xlsx'

export default class ItemPg extends Component {
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    toast = createRef();
    inputFileRef = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoaFabricante: new Pessoa(),
        item: new Item(),
        itemRelacionamento: new ItemRelacionamento(),
        itemCategoria: new ItemCategoria(),
        itemCategoriaFiltro: new ItemCategoria(),
        pessoaFabricanteFiltro: new Pessoa(),
        situacaoFiltro: "",
        imagemFiltro: "",
        listaItem: [],
        listaItemCategoria: [],
        listaPessoaFabricante: [],
        conteudo: "",
        inputError: {
            codigo: false,
            nome: false,
            descricao: false,
            categoria: false,
            fabricante: false,
            situacao: false,
        },
        base64: "",
        imagemPreview: '',
        carregando: false,
        arquivoExcel: null,
        salvarExcelCarregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        // this.listar();
        this.listarCategoria();
        this.listarPessoaFabricante();

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase
        });
    }

    async listar(pItemCategoria, pPessoaFabricante, pSituacao, pImagem) {
        const listaPesquisa = [];
        let itemCategoria = new ItemCategoria();
        let pessoaFabricante = new Pessoa();
        let situacao = "";
        let imagem = "";

        if (pItemCategoria) {
            itemCategoria = pItemCategoria;
        }
        if (pPessoaFabricante) {
            pessoaFabricante = pPessoaFabricante;
        }
        if (pSituacao) {
            situacao = pSituacao;
        }

        if (pImagem) {
            imagem = pImagem;
        }

        this.setState({
            carregando: true,
            item: new Item(),
            listaItem: [],
            itemCategoriaFiltro: itemCategoria,
            pessoaFabricanteFiltro: pessoaFabricante,
            situacaoFiltro: situacao,
            imagemFiltro: imagem,
        })

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'item.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = ` AND upper(concat(item.codigo, ' ', item.nome, ' ', item.descricao)) like '%${this.state.conteudo?.toUpperCase()}%' `;
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'item.tagn0';
        pesquisa.conteudo = 1;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "NUMERO";
        listaPesquisa.push(pesquisa);

        if (itemCategoria) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'item.seq_item_categoria';
            pesquisa.conteudo = itemCategoria._seqItemCategoria;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        if (situacao) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "AND item.situacao = '" + situacao + "'";
            pesquisa.conteudo = situacao;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "MANUAL";
            listaPesquisa.push(pesquisa);
        }

        // if(imagem === "S") {
        //     pesquisa = new Pesquisa();
        //     pesquisa.campo = 'AND item.imagem is not null';
        //     pesquisa.conteudo = "";
        //     pesquisa.operacao = "AND";
        //     pesquisa.tipo = "MANUAL";
        //     listaPesquisa.push(pesquisa);
        // }

        // if(imagem === "N") {
        //     pesquisa = new Pesquisa();
        //     pesquisa.campo = 'AND item.imagem is null';
        //     pesquisa.conteudo = "";
        //     pesquisa.operacao = "AND";
        //     pesquisa.tipo = "MANUAL";
        //     listaPesquisa.push(pesquisa);
        // }

        if (pessoaFabricante._seqPessoa) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'item_relacionamento.seq_pessoa_relacionada';
            pesquisa.conteudo = pessoaFabricante._seqPessoa;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        } else {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'item_relacionamento.seq_tipo_relacionamento';
            pesquisa.conteudo = this.constante.seqTipoRelacionamentoFabricante;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        const retornoListaItem = await this.itemService.listarComFiltro(listaPesquisa);
        let listaItem = [];

        if (imagem === "S") {
            retornoListaItem.objeto.forEach(pItem => {
                if (pItem.imagem) {
                    listaItem.push(pItem);
                }
            });
        } else if (imagem === "N") {
            retornoListaItem.objeto.forEach(pItem => {
                if (!pItem.imagem) {
                    listaItem.push(pItem);
                }
            });
        } else {
            listaItem = retornoListaItem.objeto;
        }

        this.setState({
            carregando: false,
            listaItem: listaItem
        })
    }

    async listarCategoria() {
        let retornoListaCategoria = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        let listaItemCategoria = [];

        retornoListaCategoria.objeto.forEach(pItemCategoria => {
            if (pItemCategoria.situacao === "ATIVO") {
                listaItemCategoria.push(pItemCategoria);
            }
        })

        this.setState({
            listaItemCategoria: listaItemCategoria,
        });
    }

    async listarPessoaFabricante() {
        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilFabricante;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);
        const listaPessoaFabricante = retornoListaPessoa.objeto;

        this.setState({
            carregando: false,
            listaPessoaFabricante: listaPessoaFabricante
        })
    }

    async selecionar(pItem) {
        let itemCategoria = new ItemCategoria();
        let pessoaFabricante = new Pessoa();
        let itemRelacionamento = new ItemRelacionamento();

        let retornoListaItemRelacionamento = await this.itemService.listarRelacionamento(pItem);
        let listaItemRelacionamento = retornoListaItemRelacionamento.objeto;

        this.state.listaItemCategoria.forEach(pItemCategoria => {
            if (pItemCategoria._seqItemCategoria === pItem.seqItemCategoria) {
                itemCategoria = pItemCategoria;
            }
        })

        listaItemRelacionamento.forEach(async pItemRelacionamento => {
            if (pItemRelacionamento.seqTipoRelacionamento === this.constante.seqTipoRelacionamentoFabricante) {
                itemRelacionamento = pItemRelacionamento;
            }
        })

        if (itemRelacionamento._seqItemRelacionamento) {
            let retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(itemRelacionamento.seqPessoaRelacionada, this.constante.seqUsuario);
            pessoaFabricante = retornoPessoa.objeto;
        }

        this.setState({
            item: pItem,
            itemRelacionamento: itemRelacionamento,
            itemCategoria: itemCategoria,
            pessoaFabricante: pessoaFabricante,
            itemDetalheVisivel: true,
            imagemPreview: '',
            inputError: {
                codigo: false,
                nome: false,
                descricao: false,
                categoria: false,
                situacao: false,
            },
        })
    }

    async salvarSituacao(pItem) {
        const retornoItem = await this.itemService.salvar(pItem);
        pItem = retornoItem.objeto;

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({ item: pItem });
    }

    async salvar() {
        let itemRelacionamento = new ItemRelacionamento();
        const inputError = this.state.inputError;
        let inputOK = true;

        let item = this.state.item;

        this.toast.current.clear();

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!item.codigo) {
            inputError.codigo = true;
        } else {
            this.state.listaItem.forEach(pItem => {
                if (pItem._seqItem === item._seqItem) {
                    return;
                }

                if (pItem.codigo === item.codigo) {
                    inputError.codigo = true;

                    this.toast.current.show([
                        { severity: 'error', summary: 'Você possui outro item com este código!', sticky: true },
                    ]);
                }
            })
        }

        if (!item.nome) {
            inputError.nome = true;
        }

        if (!item.descricao) {
            inputError.descricao = true;
        }

        if (!this.state.itemCategoria._seqItemCategoria) {
            inputError.categoria = true;
        }

        if (!this.state.pessoaFabricante._seqPessoa) {
            inputError.fabricante = true;
        }

        if (!item.situacao) {
            inputError.situacao = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ salvarCarregando: true });

        item.seqUsuario = this.constante.seqUsuario;
        item.seqItemCategoria = this.state.itemCategoria._seqItemCategoria;

        if (this.state.base64) {
            item.imagem = this.state.base64;
        }

        let retornoItem;

        try {
            retornoItem = await this.itemService.salvar(item);
        } catch (error) {
            this.toast.current.show({
                severity: 'error', summary: "Algo de errado aconteceu!"
            });
            return;
        }

        item = retornoItem.objeto;

        let retornoListaItemRelacionamento = await this.itemService.listarRelacionamento(item);
        let listaItemRelacionamento = retornoListaItemRelacionamento.objeto;

        listaItemRelacionamento.forEach(pItemRelacionamento => {
            if (pItemRelacionamento.seqTipoRelacionamento === this.constante.seqTipoRelacionamentoFabricante) {
                itemRelacionamento = pItemRelacionamento;
            }
        })

        itemRelacionamento.seqItem = item._seqItem;
        itemRelacionamento.seqPessoaRelacionada = this.state.pessoaFabricante._seqPessoa;
        itemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoFabricante;
        itemRelacionamento.seqUsuario = this.constante.seqUsuario;

        await this.itemService.salvarRelacionamento(itemRelacionamento);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            item: new Item(),
            itemCategoria: new ItemCategoria(),
            itemDetalheVisivel: false,
            salvarCarregando: false,
        });

        this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro);
    }

    async selecionarArquivo(pArquivo) {
        const retorno = await this.util.pegarBase64(pArquivo);
        const item = this.state.item;

        const objetoUrl = URL.createObjectURL(pArquivo);

        item.imagem = retorno;

        this.setState({
            base64: retorno,
            imagemPreview: objetoUrl,
            item: item
        });
    }

    exportarListaExcel() {
        let lista = [];

        this.state.listaItem.forEach(pItem => {
            lista.push({
                "Codigo": pItem.codigo?.toString(),
                "Nome": pItem.nome?.toString(),
                "Apelido": pItem.descricao?.toString(),
                "Tem foto": pItem.imagem && 'Sim',
                "Status": pItem.situacao,
                "Detalhe do item": pItem.tags0,
                "Categoria": pItem._itemCategoriaNome,
                "Fabricante": pItem._pessoaRelacionadaNome,
                "ID do Fabricante": pItem._seqPessoaRelacionada?.toString(),
                "ID da Categoria": pItem.seqItemCategoria?.toString(),
                "": "",
                "ID": pItem._seqItem?.toString(),
                "ID de Chave": pItem.chave?.toString(),
                "Data de Cadastro": pItem.dataCadastro?.toString(),
            });
        });

        this.util.exportarExcel(lista, "Lista de itens ( Zé Marceneiro )");
    }

    gerenciarArquivoExcel(pArquivo) {
        const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (pArquivo) {
            if (pArquivo && fileType.includes(pArquivo.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(pArquivo);
                reader.onload = (e) => {
                    this.toast.current.clear();
                    this.importarListaExcel(e.target.result);
                }
            }
            else {
                this.toast.current.show([
                    { severity: 'error', summary: 'Por favor, selecione um arquivo Excel!', sticky: true },
                ]);
                this.setState({
                    arquivoExcel: null
                })
            }
        }
    }

    async importarListaExcel(pArquivoExcel) {
        const workbook = XLSX.read(pArquivoExcel, { type: 'buffer' });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const listaItemExcel = XLSX.utils.sheet_to_json(worksheet);

        this.setState({ salvarExcelCarregando: true });

        for (let i = 0; i < listaItemExcel.length; i++) {
            let item = new Item();

            item._seqItem = listaItemExcel[i]["ID"]?.toString();
            item.chave = listaItemExcel[i]["ID de Chave"]?.toString();
            item.seqItemCategoria = listaItemExcel[i]["ID da Categoria"]?.toString();
            item.dataCadastro = listaItemExcel[i]["Data de Cadastro"]?.toString();

            item.codigo = listaItemExcel[i]["Codigo"]?.toString();
            item.nome = listaItemExcel[i]["Nome"]?.toString();
            item.descricao = listaItemExcel[i]["Apelido"]?.toString();
            item.situacao = listaItemExcel[i]["Status"]?.toString();
            item.tags0 = listaItemExcel[i]["Detalhe do item"]?.toString();

            item.imagem = listaItemExcel[i]["ID de Chave"] && listaItemExcel[i]["ID de Chave"] + '.jpg';
            item.seqUsuario = this.constante.seqUsuario;
            item._seqPessoaRelacionada = listaItemExcel[i]["ID do Fabricante"]?.toString();
            item.tagn0 = 1;

            let itemRelacionamento = new ItemRelacionamento();

            const seqPessoaRelacionada = item._seqPessoaRelacionada;

            const retornoItem = await this.itemService.salvar(item);
            item = retornoItem.objeto;

            let retornoListaItemRelacionamento = await this.itemService.listarRelacionamento(item);
            let listaItemRelacionamento = retornoListaItemRelacionamento.objeto;

            for (let j = 0; j < listaItemRelacionamento.length; j++) {
                if (listaItemRelacionamento[j].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoFabricante) {
                    itemRelacionamento = listaItemRelacionamento[j];
                }
            }

            itemRelacionamento.seqItem = item._seqItem;
            itemRelacionamento.seqPessoaRelacionada = seqPessoaRelacionada;
            itemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoFabricante;
            itemRelacionamento.seqUsuario = this.constante.seqUsuario;

            await this.itemService.salvarRelacionamento(itemRelacionamento);
        }

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.inputFileRef.current.value = "";

        this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro);

        this.setState({ salvarExcelCarregando: false });
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro, paddingBottom: 100 }}>
            <Header />

            <Toast ref={this.toast} />

            <Dialog
                header={this.state.item._seqItem ? "Detalhe do item" : "Cadastrar novo item"}
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                contentStyle={{ padding: 0 }}
                visible={this.state.itemDetalheVisivel}
                onHide={() => this.setState({ itemDetalheVisivel: false })}>
                <form onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 20, padding: 20 }}>
                        {this.state.imagemPreview ?
                            <div style={{ margin: '0 auto' }}>
                                <img
                                    src={this.state.imagemPreview}
                                    style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20, objectFit: "contain" }} />
                                <input
                                    type="file" id="imagem_item" style={{ display: 'none' }}
                                    onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                <label
                                    htmlFor="imagem_item"
                                    style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", marginLeft: -50, }}
                                >
                                    <i className="pi pi-camera"></i>
                                </label>
                            </div>
                            :
                            this.state.item.imagem ?
                                <div style={{ margin: '0 auto' }}>
                                    <img
                                        src={this.state.urlBase + this.state.item.imagem}
                                        style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20, objectFit: "contain" }} />
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", marginLeft: -50, }}
                                    >
                                        <i className="pi pi-camera"></i>
                                    </label>
                                </div>
                                :
                                <div style={{ margin: '20px auto' }}>
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", }}
                                    >
                                        <i className="pi pi-camera"></i> Adicionar imagem
                                    </label>
                                </div>}
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Link de detalhe do item
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    item: {
                                        ...this.state.item,
                                        tags0: pEvento.target.value
                                    }
                                })}
                                value={this.state.item.tags0} />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Código
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.codigo && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    item: {
                                        ...this.state.item,
                                        codigo: pEvento.target.value
                                    }
                                })}
                                value={this.state.item.codigo} />
                            {this.state.inputError.codigo && <small style={{ color: 'red' }}>Código inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.nome && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    item: {
                                        ...this.state.item,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.item.nome} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Descrição
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.descricao && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    item: {
                                        ...this.state.item,
                                        descricao: pEvento.target.value
                                    }
                                })}
                                value={this.state.item.descricao} />
                            {this.state.inputError.descricao && <small style={{ color: 'red' }}>Descrição inválida</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Categoria
                            </label>
                            <br />
                            <Dropdown
                                placeholder="Selecione uma categoria"
                                style={{ width: "100%", borderColor: this.state.inputError.categoria && 'red', marginTop: 5 }}
                                options={this.state.listaItemCategoria}
                                optionLabel="nome"
                                onChange={pEvento => this.setState({
                                    itemCategoria: pEvento.value
                                })}
                                value={this.state.itemCategoria} />
                            {this.state.inputError.categoria && <small style={{ color: 'red' }}>Categoria inválida</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Fabricante
                            </label>
                            <br />
                            <Dropdown
                                placeholder="Selecione um fabricante"
                                style={{ width: "100%", borderColor: this.state.inputError.fabricante && 'red', marginTop: 5 }}
                                options={this.state.listaPessoaFabricante}
                                optionLabel="apelido"
                                onChange={pEvento => this.setState({
                                    pessoaFabricante: pEvento.value
                                })}
                                value={this.state.pessoaFabricante} />
                            {this.state.inputError.fabricante && <small style={{ color: 'red' }}>Fabricante inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Situação
                            </label>
                            <br />
                            <Dropdown
                                placeholder="Selecione uma situação"
                                style={{ width: "100%", borderColor: this.state.inputError.situacao && 'red', marginTop: 5 }}
                                options={[
                                    { label: "ATIVO", value: "ATIVO" },
                                    { label: "INATIVO", value: "INATIVO" },
                                ]}
                                onChange={pEvento => this.setState({
                                    item: {
                                        ...this.state.item,
                                        situacao: pEvento.value
                                    }
                                })}
                                value={this.state.item.situacao} />
                            {this.state.inputError.situacao && <small style={{ color: 'red' }}>Situação inválida</small>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro }}>
                        <Button
                            type="button"
                            label="Cancelar"
                            className="p-button-outlined p-button-secondary"
                            style={{ backgroundColor: 'white' }}
                            onClick={() => this.setState({
                                itemDetalheVisivel: false
                            })} />
                        <Button
                            loading={this.state.salvarCarregando}
                            type="submit"
                            label="Salvar"
                            className="p-button-success" />
                    </div>
                </form>
            </Dialog>

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', display: "flex", alignItems: 'flex-start', gap: 20, }}>
                <div style={{ width: 500, backgroundColor: 'white', padding: 20, marginTop: 20, marginLeft: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                    <h2 style={{ fontSize: 16, color: this.constante.corAzulMarinhoEscuro }}>FILTRO</h2>

                    <h3 style={{ fontSize: 18, color: this.constante.corPrincipal, marginTop: 20 }}>Categoria</h3>

                    <div style={{ display: "flex", flexDirection: 'column', gap: 5, marginTop: 20 }}>
                        <Button
                            label="Todas"
                            icon={!this.state.itemCategoriaFiltro._seqItemCategoria ? "pi pi-times-circle" : "pi pi-circle"}
                            className="p-button-link"
                            style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal }}
                            onClick={() => this.listar("", this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, this.state.imagemFiltro)} />
                        {this.state.listaItemCategoria.map(pItemCategoria =>
                            <Button
                                label={pItemCategoria.nome}
                                icon={this.state.itemCategoriaFiltro._seqItemCategoria === pItemCategoria._seqItemCategoria ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(pItemCategoria, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, this.state.imagemFiltro)} />)}
                    </div>

                    <h3 style={{ fontSize: 18, color: this.constante.corPrincipal, marginTop: 20 }}>Fabricante</h3>

                    <div style={{ display: "flex", flexDirection: 'column', gap: 5, marginTop: 20 }}>
                        <Button
                            label="Todos"
                            icon={!this.state.pessoaFabricanteFiltro._seqPessoa ? "pi pi-times-circle" : "pi pi-circle"}
                            className="p-button-link"
                            style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, "", this.state.situacaoFiltro, this.state.imagemFiltro)} />
                        {this.state.listaPessoaFabricante.map(pPessoaFabricante =>
                            <Button
                                label={pPessoaFabricante.nome}
                                icon={this.state.pessoaFabricanteFiltro._seqPessoa === pPessoaFabricante._seqPessoa ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.itemCategoriaFiltro, pPessoaFabricante, this.state.situacaoFiltro, this.state.imagemFiltro)} />)}
                    </div>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", gap: 5, marginTop: 20 }}>
                        <h3 style={{ fontSize: 18, color: this.constante.corPrincipal }}>Status</h3>

                        <div
                            style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer", marginTop: 20 }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, "", this.state.imagemFiltro)}>
                            <i style={{ color: this.constante.corPrincipal, }} className={!this.state.situacaoFiltro ? "pi pi-times-circle" : "pi pi-circle"}></i>

                            <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corPrincipal, textAlign: 'center' }}>
                                    Todas
                                </p>
                            </span>
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer" }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, "ATIVO", this.state.imagemFiltro)}>
                            <i style={{ color: this.constante.corPrincipal, }} className={this.state.situacaoFiltro === "ATIVO" ? "pi pi-times-circle" : "pi pi-circle"}></i>

                            <span style={{ backgroundColor: this.constante.corVerdeClaro, padding: 5, borderRadius: 5, }}>
                                <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corVerdeEscuro, textAlign: 'center' }}>
                                    ATIVO
                                </p>
                            </span>
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer" }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, "INATIVO", this.state.imagemFiltro)}>
                            <i style={{ color: this.constante.corPrincipal, }} className={this.state.situacaoFiltro === "INATIVO" ? "pi pi-times-circle" : "pi pi-circle"}></i>

                            <span style={{ backgroundColor: this.constante.corVermelhoClaro, padding: 5, borderRadius: 5, }}>
                                <p style={{ fontSize: 13, fontWeight: 'bold', color: this.constante.corVermelhoEscuro, textAlign: 'center' }}>
                                    INATIVO
                                </p>
                            </span>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", gap: 5, marginTop: 20 }}>
                        <h3 style={{ fontSize: 18, color: this.constante.corPrincipal }}>Imagem</h3>

                        <div
                            style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer", marginTop: 20 }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "")}>
                            <Button
                                label="Todas"
                                icon={this.state.imagemFiltro === "" ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "")} />
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer" }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "S")}>
                            <Button
                                label="Com imagem"
                                icon={this.state.imagemFiltro === "S" ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "S")} />
                        </div>

                        <div style={{ display: 'flex', alignItems: "center", gap: 20, cursor: "pointer" }}
                            onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "N")}>
                            <Button
                                label="Sem imagem"
                                icon={this.state.imagemFiltro === "N" ? "pi pi-times-circle" : "pi pi-circle"}
                                className="p-button-link"
                                style={{ textAlign: 'left', paddingLeft: 0, color: this.constante.corPrincipal, }}
                                onClick={() => this.listar(this.state.itemCategoriaFiltro, this.state.pessoaFabricanteFiltro, this.state.situacaoFiltro, "N")} />
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%", padding: 20, paddingLeft: 0, marginTop: 15 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h1 style={{ width: "20%", fontSize: 20 }}>Lista de itens</h1>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <input
                                type="file" id="input_file" style={{ display: 'none' }}
                                onChange={pEvento => this.gerenciarArquivoExcel(pEvento.target.files[0])}
                                ref={this.inputFileRef} />
                            {this.state.salvarExcelCarregando ?
                                <Button
                                    loading={true}
                                    icon="pi pi-file-excel"
                                    label="Importar lista do Excel"
                                    style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                                />
                                :
                                <label
                                    htmlFor="input_file"
                                    style={{ padding: 8, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", display: "flex", alignItems: 'center', gap: 10 }}
                                >
                                    <i className="pi pi-file-excel"></i>

                                    <span style={{ textAlign: 'center' }}>Importar lista do Excel</span>
                                </label>}

                            <Button
                                icon="pi pi-file-excel"
                                label="Exportar lista para o Excel"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                                onClick={() => this.exportarListaExcel()}
                            />

                            <Button
                                label="+ Cadastrar novo item"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                                onClick={() => {
                                    let item = new Item();

                                    item.situacao = "ATIVO";

                                    this.setState({
                                        item: item,
                                        itemCategoria: new ItemCategoria(),
                                        itemDetalheVisivel: true,
                                        inputError: {
                                            codigo: false,
                                            nome: false,
                                            descricao: false,
                                            categoria: false,
                                            situacao: false,
                                        },
                                    })
                                }} />
                        </div>
                    </div>

                    <form
                        style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
                        onSubmit={pEvento => { pEvento.preventDefault(); this.listar(); }}>
                        <InputText
                            placeholder="Pesquisar"
                            style={{ width: "80%" }}
                            onChange={pEvento => this.setState({
                                conteudo: pEvento.target.value
                            })} />
                        <Button
                            label="Pesquisar"
                            loading={this.state.pesquisarCarregando}
                            type="submit"
                            icon="pi pi-search"
                            iconPos="left"
                            style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }} />
                    </form>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", backgroundColor: this.constante.corAzulMarinhoMedio, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, gap: 20 }}>
                        <div style={{ width: "40%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Item
                            </p>
                        </div>
                        <div style={{ width: "20%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Categoria
                            </p>
                        </div>
                        <div style={{ width: "20%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Fabricante
                            </p>
                        </div>
                        <div style={{ width: "20%" }}>
                            <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', marginRight: 60 }}>
                                Status
                            </p>
                        </div>
                    </div>

                    {this.state.carregando &&
                        <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                            <Button
                                loading={true}
                                label="Carregando"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                        </div>}

                    {(!this.state.carregando && this.state.listaItem.length === 0) &&
                        <p style={{ textAlign: "center", marginTop: 20 }}>Nenhum item listado</p>}

                    {this.state.listaItem.map(pItem =>
                        <div style={{ backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 20 }}>
                            <div style={{ width: '40%', display: 'flex', alignItems: 'center', gap: 20, }}>

                                <img src={this.state.urlBase + pItem.imagem} style={{ width: 50, height: 50, borderRadius: 500, objectFit: 'contain' }} />

                                <div style={{ width: "90%", }}>
                                    <div style={{ display: "flex" }}>
                                        <span style={{ backgroundColor: this.constante.corAzulMarinhoClaro, padding: 5, borderRadius: 5, }}>
                                            <p
                                                key={Math.random()}
                                                style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                                {pItem.codigo}
                                            </p>
                                        </span>
                                    </div>

                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', cursor: "pointer", textDecoration: "underline", textDecorationColor: this.constante.corPrincipal, marginTop: 10 }}
                                        onClick={() => this.selecionar(pItem)}>
                                        {pItem.nome}
                                    </p>

                                    <p
                                        key={Math.random()}
                                        style={{ fontSize: 16, color: 'gray', marginTop: 5 }}>
                                        {pItem.descricao}
                                    </p>
                                </div>
                            </div>

                            <div style={{ width: '20%', display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                <p style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    {pItem._itemCategoriaNome}
                                </p>
                            </div>

                            <div style={{ width: '20%', display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                <p style={{ fontSize: 14, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    {pItem._pessoaRelacionadaNome}
                                </p>
                            </div>

                            <div style={{ width: "20%" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                    <InputSwitch checked={pItem.situacao === "ATIVO" ? true : false} onChange={pEvento => {
                                        if (pEvento.value === false) {
                                            pItem.situacao = "INATIVO";
                                        }

                                        if (pEvento.value === true) {
                                            pItem.situacao = "ATIVO";
                                        }

                                        this.salvarSituacao(pItem);
                                    }} />

                                    <span
                                        key={Math.random()}
                                        style={{ backgroundColor: pItem.situacao === "ATIVO" ? this.constante.corVerdeClaro : this.constante.corVermelhoClaro, padding: 5, borderRadius: 5, }}>
                                        <p
                                            key={Math.random()}
                                            style={{ fontSize: 13, color: this.constante.corPrincipal, fontWeight: 'bold', color: pItem.situacao === "ATIVO" ? this.constante.corVerdeEscuro : this.constante.corVermelhoEscuro, textAlign: 'center' }}>
                                            {pItem.situacao}
                                        </p>
                                    </span>
                                </div>
                            </div>

                        </div>)}
                </div>
            </main >
        </div >
    }
}