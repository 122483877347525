import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../../util/Constante";
import FormAlterarSenha from "../components/FormAlterarSenha";
import Util from "../../../util/Util";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Toast } from "primereact/toast";
import HeaderFabricante from "../components/HeaderFabricante";

export default class PerfilFabricante extends Component {
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaAdministrador: new Pessoa(),
        urlBase: '',
        carregando: false,
        tab: "Meu perfil",
        inputError: {
            nome: false,
            apelido: false,
            email: false,
            telefone: false,
            documento: false,
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaAdministrador = new Pessoa();
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();

        let retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador, this.constante.seqUsuario);
        pessoaAdministrador = retornoPessoa.objeto;

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
            pessoaAdministrador: pessoaAdministrador,
        })
    }

    async salvar(pPessoaUsuario) {
        let pessoaUsuario = new Pessoa();
        let inputError = this.state.inputError;
        pessoaUsuario = pPessoaUsuario;

        this.toast.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        })

        if (!pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!pessoaUsuario.apelido) {
            inputError.apelido = true;
        }

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!pessoaUsuario.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(pessoaUsuario.documento)) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === "CPF" && pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === "CNPJ" && pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        let inputOK = true;

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        this.setState({ inputError: inputError });

        if (!inputOK) {
            return;
        }

        this.setState({ carregando: true });

        let retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false,
        });
    }

    async selecionarArquivo(pArquivo) {
        const retorno = await this.util.pegarBase64(pArquivo);
        const pessoaUsuario = this.state.pessoaUsuario;

        pessoaUsuario.foto = retorno;

        this.salvar(pessoaUsuario);
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro, paddingBottom: 100 }}>
            <HeaderFabricante />

            <Toast ref={this.toast} />

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 20, display: 'flex', alignItems: 'flex-start', gap: 20 }}>
                <div style={{ width: 300, display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Button
                        icon="pi pi-home"
                        label="Meu perfil"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Meu perfil" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Meu perfil" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Meu perfil"
                        })} />
                    <Button
                        icon="pi pi-user"
                        label="Dados cadastrais"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Dados cadastrais" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Dados cadastrais" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Dados cadastrais"
                        })} />
                    <Button
                        icon="pi pi-lock"
                        label="Segurança"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Segurança" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Segurança" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Segurança"
                        })} />
                    <Button
                        icon="pi pi-dollar"
                        label="Taxas"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Taxas" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Taxas" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Taxas"
                        })} />
                </div>

                {this.state.tab === "Meu perfil" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 20 }}>
                        {this.state.carregando ?
                            <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    loading={true}
                                    label="Carregando"
                                    style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                            </div>
                            :
                            this.state.pessoaUsuario.foto ?
                                <div>
                                    <img
                                        src={this.state.urlBase + this.state.pessoaUsuario.foto}
                                        style={{ width: 200, height: 200, borderRadius: 500, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corPrincipal, padding: 20, objectFit: "contain" }} />
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", marginLeft: -50, }}
                                    >
                                        <i className="pi pi-camera"></i>
                                    </label>
                                </div>
                                :
                                <div>
                                    <input
                                        type="file" id="imagem_item" style={{ display: 'none' }}
                                        onChange={pEvento => this.selecionarArquivo(pEvento.target.files[0])} />
                                    <label
                                        htmlFor="imagem_item"
                                        style={{ padding: 10, borderRadius: 5, backgroundColor: this.constante.corPrincipal, cursor: 'pointer', color: "white", }}
                                    >
                                        <i className="pi pi-camera"></i> Adicionar imagem
                                    </label>
                                </div>}

                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-user" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.nome}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-at" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.emailPlataformaLirida}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-phone" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.telefonePlataformaLirida}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-clock" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>Usuário desde {this.util.formatarDataComTimestamp(this.state.pessoaUsuario.dataCadastro)}</p>
                        </div>
                    </div>}

                {this.state.tab === "Dados cadastrais" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seus dados</h1><br />

                        <form
                            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(this.state.pessoaUsuario) }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Nome
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.nome || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            nome: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.nome && 'var(--red-600)', marginTop: 5, marginTop: 5, }} />
                                {this.state.inputError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div><div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Nome fantasia
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.apelido || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            apelido: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.apelido && 'var(--red-600)', marginTop: 5, marginTop: 5, }} />
                                {this.state.inputError.apelido && <small style={{ color: 'var(--red-600)' }}>Nome fantasia inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Email
                                </label>
                                <InputText
                                    disabled
                                    value={this.state.pessoaUsuario.emailPlataformaLirida || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            emailPlataformaLirida: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Telefone
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.telefonePlataformaLirida || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            telefonePlataformaLirida: this.util.formatarTelefone(e.target.value)
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.telefone && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Tipo de pessoa
                                </label>
                                <Dropdown
                                    disabled
                                    options={[{ label: "Pessoa física", value: "CPF" }, { label: "Pessoa jurídica", value: "CNPJ" }]}
                                    value={this.state.pessoaUsuario.tipoDocumento || ''}
                                    // onChange={e => this.setState({
                                    //     pessoaUsuario: {
                                    //         ...this.state.pessoaUsuario,
                                    //         tipoDocumento: e.value
                                    //     }
                                    // })}
                                    style={{ width: '100%', marginTop: 5 }} />
                            </div>
                            {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                                <div>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        CPF
                                    </label>
                                    <InputText
                                        type="tel"
                                        value={this.state.pessoaUsuario.documento || ''}
                                        onChange={e => {
                                            if (e.target.value.length > 14) return;

                                            this.setState({
                                                pessoaUsuario: {
                                                    ...this.state.pessoaUsuario,
                                                    documento: this.util.formatarCPF(e.target.value)
                                                }
                                            })
                                        }}
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                                </div>}

                            {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                                <div>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        CNPJ
                                    </label>
                                    <InputText
                                        type="tel"
                                        value={this.state.pessoaUsuario.documento || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                documento: this.util.formatarCNPJ(e.target.value)
                                            }
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                                </div>}

                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    loading={this.state.carregando}
                                    type="submit"
                                    label='Salvar'
                                    className="p-button-success" />
                            </div>
                        </form>
                    </div>}

                {this.state.tab === "Segurança" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Altere sua senha</h1><br />

                        <FormAlterarSenha />
                    </div>}

                {this.state.tab === "Taxas" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Taxas</h1><br />

                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }} >
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Ganho da plataforma
                                </label>
                                <InputText
                                    disabled
                                    value={`${this.state.pessoaAdministrador.tagn3}%`}
                                    style={{ width: '100%' }} />
                            </div>
                        </form>
                    </div>}
            </main>
        </div>
    }
}