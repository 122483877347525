import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export default class Util {
  constante = new Constante();
  util_api = new Util_api(this.constante.token, this.constante.url_base);

  enviarEmail(pApiEmail) {
    this.util_api.enviarEmail(pApiEmail);
  }

  enviarWhatsApp(pApiWhatsApp) {
    this.util_api.enviarWhatsApp(pApiWhatsApp);
  }

  async buscarUrlBaseArquivo() {
    let retorno = await this.util_api.buscarUrlBaseArquivo();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarUrlBaseFinanceiro() {
    let retorno = await this.util_api.buscarUrlBaseFinanceiro();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarLocalStorage(pCampo) {
    let retorno = localStorage.getItem(pCampo);
    retorno = await JSON.parse(retorno);
    return retorno;
  }

  salvarLocalStorage(pCampo, pValor) {
    let valor = JSON.stringify(pValor);
    localStorage.setItem(pCampo, valor);
    console.log(pCampo + ' salvo localstorage com sucesso')
  }

  removerLocalStorage(pCampo) {
    let retorno = localStorage.removeItem(pCampo);
    return retorno;
  }

  formatarTelefone(value) {
    let r = value.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (value.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }

    return r;
  }

  formatarData(pData) {
    let data = pData;
    data = data.split("/").join("-");
    return data + "T00:00:00";
  }

  formatarDataComTimestamp(pData) {
    if (!pData) return;

    let data = pData;
    data = data.split("T")[0].split("-").join("/");
    return data;
  }

  formatarDataBancoParaObjetoData(pData) {
      if (!pData) {
          return pData;
      }

      let listaData = pData.split('T')[0].split("-");

      let data = new Date();
      data.setDate(listaData[0]);
      data.setMonth(listaData[1] - 1);
      data.setFullYear(listaData[2]);

      return data;
  }

  formatarObjetoDataParaDataBanco(pData) {
      return pData.toLocaleDateString("pt-br").split("/").join("-") + 'T00:00:00';
  }

  formatarCPFCNPJ(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }
  }

  formatarCPF(pTexto) {
    return pTexto.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  }


  formatarCNPJ(pTexto) {
    return pTexto.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }



  formatarValor(value) {
    if (value) {
      this.valorFormatado = Number(value).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    } else {
      this.valorFormatado = Number(0).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    }
    return this.valorFormatado;
  };

  formatarCEP(pTexto) {
    this.texto = pTexto;
    return this.texto.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  async validarDocumento(pDocumento) {
    this.retorno = await this.util_api.validarDocumento(pDocumento);
    this.retorno = await this.retorno.json();
    return this.retorno;
  }

  retornaPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa();

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;
  }

  async listarLista(pSeqUsuario, pID) {
    this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
    this.retorno = this.retorno.json();
    return this.retorno;
  }

  async listarBanco(pConteudo) {
    this.retorno = await this.util_api.listarBanco(pConteudo);
    this.retorno = this.retorno.json();
    return this.retorno;
  }

  buscarParametros() {
    this.listaParametrosSplitados = window.location.search.replace('?', '').split('&');
    this.parametros = {};

    for (let i = 0; i < this.listaParametrosSplitados.length; i++) {
      this.listaChaveValor = this.listaParametrosSplitados[i].split('=');
      this.parametros[this.listaChaveValor[0]] = this.listaChaveValor[1];
    }

    return this.parametros;
  }

  async buscarUsuarioPorSeq(pSeqUsuario) {
    this.usuario = await this.util_api.buscarUsuarioPorSeq(pSeqUsuario);
    return this.usuario;
  }

  async pegarBase64(pArquivo) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(pArquivo);
      reader.onload = () => {
        let base64 = reader.result;
        base64 = base64.split(",")[1];
        resolve(base64);
      };
      reader.onerror = error => reject(error);
    })
  }

  validarEmail(pEmail) {
    let emailRegex = /\S+@\S+\.\S+/;

    return emailRegex.test(pEmail);
  }

  validarTelefone(pTelefone) {
    let telefoneRegex = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;

    return telefoneRegex.test(pTelefone);
  }

  exportarExcel(excelData, fileName) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
}