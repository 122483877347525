import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "./index.css";

// Primereact
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

// Pages
import Dashboard from './pages/sistema/admin/Dashboard';
import Login from './pages/Hotsite/Login';
import LojistaPg from './pages/sistema/admin/LojistaPg';
import Perfil from './pages/sistema/admin/Perfil';
import ItemPg from './pages/sistema/admin/ItemPg';
import ItemCategoriaPg from './pages/sistema/admin/ItemCategoriaPg';
import ContasReceber from './pages/sistema/admin/ContasReceber';
import { locale, addLocale } from 'primereact/api';
import DashboardLojista from './pages/sistema/lojista/DashboardLojista';
import ContasReceberLojista from './pages/sistema/lojista/ContasReceberLojista';
import PerfilLojista from './pages/sistema/lojista/PerfilLojista';
import TabelaPrecoPg from './pages/sistema/lojista/TabelaPrecoPg';
import Pedido from './pages/sistema/admin/Pedido';
import PedidoLojista from './pages/sistema/lojista/PedidoLojista';
import Frota from './pages/sistema/lojista/Frota';
import RecuperarSenha from './pages/Hotsite/RecuperarSenha';
import Hotsite from './pages/Hotsite/Hotsite';
import PedidoLojistaDetalhe from './pages/sistema/lojista/PedidoLojistaDetalhe';
import HeaderHotsite from './pages/Hotsite/components/HeaderHotsite';
import FooterHotsite from './pages/Hotsite/components/FooterHotsite';
import TermoUso from './pages/Hotsite/TermoUso';
import PoliticaPrivacidade from './pages/Hotsite/PoliticaPrivacidade';
import ContasPagar from './pages/sistema/admin/ContasPagar';
import Fabricante from './pages/sistema/admin/Fabricante';
import PedidoDetalhe from './pages/sistema/admin/PedidoDetalhe';
import CadastroFabricante from './pages/Hotsite/CadastroFabricante';
import ItemFabricante from './pages/sistema/fabricante/ItemFabricante';
import PerfilFabricante from './pages/sistema/fabricante/PerfilFabricante';

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import ItemAprovacao from './pages/sistema/admin/ItemAprovacao';
import Cliente from './pages/sistema/admin/Cliente';
 
/* Alterando lingua de en pra pt calendario */
addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar'
});

locale('br');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<BrowserRouter>
  <Routes>
    <Route path="/*" element={<>
      <HeaderHotsite />
      <Routes>
        <Route path="/" element={<Hotsite />} />
        <Route path="/termo_de_uso" element={<TermoUso />} />
        <Route path="/politica_de_privacidade" element={<PoliticaPrivacidade />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recuperar_senha" element={<RecuperarSenha />} />
        <Route path="/cadastro_fabricante" element={<CadastroFabricante />} />
      </Routes>
      <FooterHotsite />
    </>} />

    <Route path="/sistema/admin/*" element={
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/lojista" element={<LojistaPg />} />
        <Route path="/fabricante" element={<Fabricante />} />
        <Route path="/cadastro" element={<ItemPg />} />
        <Route path="/categoria" element={<ItemCategoriaPg />} />
        <Route path="/contas_a_pagar" element={<ContasPagar />} />
        <Route path="/contas_a_receber" element={<ContasReceber />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/pedido" element={<Pedido />} />
        <Route path="/pedido_detalhe" element={<PedidoDetalhe />} />
        <Route path="/aprovacao" element={<ItemAprovacao />} />
      </Routes>
    } />
    <Route path="/sistema/lojista/*" element={
      <Routes>
        <Route path="/dashboard" element={<DashboardLojista />} />
        <Route path="/tabela_de_preco" element={<TabelaPrecoPg />} />
        <Route path="/contas_a_pagar" element={<ContasReceberLojista />} />
        <Route path="/contas_a_receber" element={<ContasReceberLojista />} />
        <Route path="/perfil" element={<PerfilLojista />} />
        <Route path="/pedido" element={<PedidoLojista />} />
        <Route path="/pedido_detalhe" element={<PedidoLojistaDetalhe />} />
        <Route path="/frota" element={<Frota />} />
      </Routes>
    } />
    <Route path="/sistema/fabricante/*" element={
      <Routes>
        <Route path="/cadastro" element={<ItemFabricante />} />
        <Route path="/perfil" element={<PerfilFabricante />} />
      </Routes>
    } />
  </Routes>
</BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
