import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import HeaderLojista from "../components/HeaderLojista";
import { InputNumber } from "primereact/inputnumber";

export default class ContasAPagarLojista extends Component {
    util = new Util();
    constante = new Constante();
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        valorTotal: 0,
        pessoaUsuario: new Pessoa(),
        listaFinanceiro: [],
        listaPessoaCasaFesta: [],
        pessoaCasaFesta: new Pessoa(),
        pago: null,
        dataCadastroInicial: null,
        dataCadastroFinal: null,
        dataProgramadaInicial: null,
        dataProgramadaFinal: null,
        numeroPedido: null,
        codigoInternoServico: null,
        nomeCliente: null,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.setState({ pessoaUsuario: pessoaUsuario });
    }

    async listar() {
        let listaPesquisa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_pessoa_recebedor';
        pesquisa.conteudo = this.state.pessoaUsuario._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        if (this.state.pago === 'S') {
            pesquisa = new Pesquisa();
            pesquisa.campo = ' AND movimento_financeiro.qtde_baixa > 0 ';
            pesquisa.conteudo = 0;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'MANUAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.pago === 'N') {
            pesquisa = new Pesquisa();
            pesquisa.campo = ' AND ( movimento_financeiro.qtde_baixa = 0 OR movimento_financeiro.qtde_baixa is null ) ';
            pesquisa.conteudo = 0;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'MANUAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'movimento_financeiro.data_cadastro';
            pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.dataCadastroInicial);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'movimento_financeiro.data_cadastro';
            pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.dataCadastroFinal);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataProgramadaInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'movimento_financeiro.data_programado';
            pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.dataProgramadaInicial);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataProgramadaFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'movimento_financeiro.data_programado';
            pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.dataProgramadaFinal);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.numeroPedido) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'movimento_financeiro.tagn0';
            pesquisa.conteudo = this.state.numeroPedido;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'NUMERO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.codigoInternoServico) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'movimento_financeiro.tags0';
            pesquisa.conteudo = this.state.codigoInternoServico;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.nomeCliente) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.nome';
            pesquisa.conteudo = this.state.nomeCliente;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.financeiroService.listarComFiltro(listaPesquisa);
        let listaFinanceiro = retorno.objeto;

        let valorTotal = 0;

        for (let i = 0; i < listaFinanceiro.length; i++) {
            if (listaFinanceiro[i]?.dataCadastro) {
                listaFinanceiro[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaFinanceiro[i].dataCadastro);
            }
            if (listaFinanceiro[i]?.dataProgramado) {
                listaFinanceiro[i].dataProgramado = this.util.formatarDataBancoParaObjetoData(listaFinanceiro[i].dataProgramado);
            }
            if (listaFinanceiro[i]?.valorProgramado) {
                valorTotal = valorTotal + listaFinanceiro[i].valorProgramado
            }
        }

        this.setState({
            listaFinanceiro: listaFinanceiro,
            carregando: false,
            valorTotal: Number(valorTotal).toFixed(2),
        });
    }

    exportExcel() {
        let lista = [];

        this.state.listaFinanceiro.forEach(financeiro => {
            lista.push({
                "Nome do cliente": financeiro._pesNome?.toString(),
                "Forma de pagamento": financeiro._fpNome?.toString(),
                "Valor a receber": financeiro.valorProgramado?.toString(),
                "Data do pedido": financeiro.dataCadastro?.toLocaleDateString("pt-br"),
                "Pago": financeiro.qtdeBaixa && financeiro.qtdeBaixa > 0 ? "Pago" : "Não pago"
            });
        });

        this.util.exportarExcel(lista, "Lista de Movimentos Financeiros ( Zé Marceneiro )");
    }

    render() {
        return <div>
            <HeaderLojista />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Contas à Receber
                    </h1>
                </div>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 20 }}>
                            <div style={{ width: "100%" }}>
                                <label>Número do pedido</label><br />
                                <InputNumber
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        numeroPedido: pEvento.value,
                                    })}
                                    value={this.state.numeroPedido} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Código interno de serviço</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        codigoInternoServico: pEvento.target.value,
                                    })}
                                    value={this.state.codigoInternoServico} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Nome do cliente</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        nomeCliente: pEvento.target.value,
                                    })}
                                    value={this.state.nomeCliente} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de pedido inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroInicial: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de pedido final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroFinal: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Pago</label><br />
                                <Dropdown
                                    filter
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: 'Todos', value: '' },
                                        { label: 'Pago', value: 'S' },
                                        { label: 'Não pago', value: 'N' },
                                    ]}
                                    onChange={pEvento => this.setState({
                                        pago: pEvento.value
                                    })}
                                    value={this.state.pago} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <div>
                                <h3>Valor total à receber</h3>
                                <p>{this.util.formatarValor(this.state.valorTotal)}</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <Button
                                className="p-button-outlined"
                                label="Limpar filtros"
                                onClick={() => this.setState({
                                    pessoaCasaFesta: new Pessoa(),
                                    pago: null,
                                    dataCadastroInicial: null,
                                    dataCadastroFinal: null,
                                    dataProgramadaInicial: null,
                                    dataProgramadaFinal: null
                                })} />
                            <Button
                                disabled={this.state.carregando}
                                loading={this.state.carregando}
                                label="Buscar"
                                onClick={() => this.listar()} />
                        </div>
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    responsiveLayout='scroll'
                    selectionMode="single"
                    showGridlines
                    removableSort
                    value={this.state.listaFinanceiro}
                    header={
                        <>
                            <Button type="button" icon="pi pi-file-excel" onClick={() => this.exportExcel()} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                        </>
                    }
                    onSelectionChange={pEvento => window.open('/sistema/lojista/pedido_detalhe?id=' + pEvento.value.seqDocumento)}>
                    <Column
                        sortable
                        header="Número do pedido"
                        field="tagn0"
                    />
                    <Column
                        sortable
                        header="Código interno de serviço"
                        field="tags0"
                    />
                    <Column
                        sortable
                        header="Cliente"
                        field="_pesNome"
                    />
                    <Column
                        sortable
                        header="Forma de pagamento"
                        field="_fpNome"
                    />
                    <Column
                        sortable
                        header="Valor à receber"
                        field="valorProgramado"
                        body={financeiro => this.util.formatarValor(financeiro.valorProgramado)}
                    />
                    <Column
                        sortable
                        header="Data de cadastro"
                        field="dataCadastro"
                        body={financeiro => financeiro.dataCadastro?.toLocaleDateString("pt-br")}
                    />
                    <Column
                        sortable
                        header="Data de crédito"
                        field="_dataCredito"
                        body={financeiro => financeiro.dataCadastro?.toLocaleDateString("pt-br")}
                    />
                    <Column
                        sortable
                        header="Pago"
                        field="qtdeBaixa"
                        body={financeiro => financeiro.qtdeBaixa > 0 ? 'Pago' : 'Não pago'}
                    />
                </DataTable>
            </main>
        </div>
    }
}