import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Component, createRef } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import HeaderLojista from "../components/HeaderLojista";
import TipoDocumentoProcesso from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso";
import TipoDocumentoProcessoService from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcessoService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import GoogleAPI from "lirida-back-service/Servicos/Google/GoogleAPI";
import { Dialog } from "primereact/dialog";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import Header from "../components/Header";
import ItemService from 'lirida-back-service/Servicos/Item/ItemService';

export default class PedidoDetalhe extends Component {
    util = new Util();
    constante = new Constante();
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    tipoDocumentoProcessoService = new TipoDocumentoProcessoService(this.constante.token, this.constante.url_base);
    googleApi = new GoogleAPI(this.constante.token, this.constante.url_base);
    itemService = new ItemService(this.constante.token, this.constante.url_base);

    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaRelacionamento: new PessoaRelacionamento(),
        documento: new Documento(),
        tipoDocumentoProcesso: new TipoDocumentoProcesso(),
        listaDocumentoItem: [],
        listaTipoDocumentoProcesso: [],
        listaPessoaRelacionamento: [],
        listaStep: [],
        indexStep: 0,
        urlBase: '',
        endereco: new Endereco(),
        distancia: {},
        selecionarEntregadorCarregando: false,
        alertaEntregadorVisivel: false,
        alertaListaEntregadorVisivel: false,
        alertaFinalizarPedidoVisivel: false,
        pessoaEntregador: new Pessoa(),
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let documento = new Documento();
        let pessoaEntregador = new Pessoa();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const parametros = this.util.buscarParametros();
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.setState({ urlBase: urlBase });

        const listaStep = this.state.listaStep;
        let tipoDocumentoProcesso = new TipoDocumentoProcesso();
        let indexStep = 0;
        let endereco = this.state.endereco;

        let retorno = await this.documentoService.buscar(this.constante.seqUsuario, parametros.id);
        documento = retorno.objeto;

        retorno = await this.documentoService.listarDocumentoItem(documento);
        let listaDocumentoItem = retorno.objeto;

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            const documentoItem = listaDocumentoItem[i];

            const response = await fetch(this.constante.url_base + 'item/buscar?seq_item=' + documentoItem.seqItem);
            const responseBody = await response.json();

            const item = responseBody.objeto;

            documentoItem._itemDescricao = item.descricao;
        }

        retorno = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcesso(this.constante.seqTipoDocumento);
        let listaTipoDocumentoProcesso = retorno.objeto;

        listaTipoDocumentoProcesso = listaTipoDocumentoProcesso.filter(
            item =>
                item._seqTipoDocumentoProcesso !== (this.constante.seqTipoDocumentoProcessoAguardandoPagamento) &&
                item._seqTipoDocumentoProcesso !== (this.constante.seqTipoDocumentoProcessoArquivado)
        );

        listaTipoDocumentoProcesso.forEach((pTipoDocumentoProcesso, index) => {
            if (pTipoDocumentoProcesso._seqTipoDocumentoProcesso === documento.seqTipoDocumentoProcesso) {
                tipoDocumentoProcesso = pTipoDocumentoProcesso;
                indexStep = index;
            }

            listaStep.push({ label: pTipoDocumentoProcesso.nome })
        });

        const retornoEndereco = await this.googleApi.buscarEnderecoPorLatLon(documento.latitudeDestino, documento.longitudeDestino);

        const retornoDistancia = await this.googleApi.buscarDistanciaPorLatLon(
            documento.latitudeOrigem,
            documento.longitudeOrigem,
            documento.latitudeDestino,
            documento.longitudeDestino,
        );

        retornoEndereco.results[0].address_components.forEach(pEndereco => {
            if (pEndereco.types.includes("street_number")) {
                endereco.numero = pEndereco.long_name;
            }
            if (pEndereco.types.includes("route")) {
                endereco.logradouro = pEndereco.long_name;
            }
            if (pEndereco.types.includes("sublocality_level_1")) {
                endereco.bairro = pEndereco.long_name;
            }
            if (pEndereco.types.includes("administrative_area_level_2")) {
                endereco.cidade = pEndereco.long_name;
            }
            if (pEndereco.types.includes("administrative_area_level_1")) {
                endereco.estado = pEndereco.long_name;
                endereco.uf = pEndereco.short_name;
            }
            if (pEndereco.types.includes("country")) {
                endereco.pais = pEndereco.long_name;
            }
            if (pEndereco.types.includes("postal_code")) {
                endereco.cep = pEndereco.long_name;
            }
        });

        endereco.latitude = documento.latitude;
        endereco.longitude = documento.longitude;

        if (!documento.status >= 3 && documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoTransito) {
            let retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoaEntregador, this.constante.seqUsuario);
            pessoaEntregador = retorno.objeto;
        }

        this.setState({
            documento: documento,
            listaDocumentoItem: listaDocumentoItem,
            tipoDocumentoProcesso: tipoDocumentoProcesso,
            listaTipoDocumentoProcesso: listaTipoDocumentoProcesso,
            indexStep: indexStep,
            endereco: endereco,
            distancia: retornoDistancia.rows[0].elements[0],
            pessoaUsuario: pessoaUsuario,
            pessoaEntregador: pessoaEntregador,
        });
    }

    async finalizarPedido() {
        let documento = this.state.documento;

        this.setState({ salvarCarregando: true });

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoEntregue;
        documento.status = 4;

        let retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: 'Pedido finalizado com sucesso!'
        });

        this.setState({
            documento: documento,
            alertaFinalizarPedidoVisivel: false,
            salvarCarregando: false
        })
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro }}>

            <Toast ref={this.toast} />

            <Dialog
                visible={this.state.alertaFinalizarPedidoVisivel}
                header="Finalizar pedido"
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                onHide={() => this.setState({ alertaFinalizarPedidoVisivel: false })}>
                <p style={{ fontSize: 18, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                    Deseja realmente finalizar o pedido?
                </p>

                <div style={{ marginTop: 20 }}>
                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                        Descreva o motivo
                    </label>
                    <br />
                    <InputText
                        style={{ width: "100%", marginTop: 5 }}
                        value={this.state.documento.observacao}
                        onChange={pEvento => this.setState({
                            documento: {
                                ...this.state.documento,
                                observacao: pEvento.target.value
                            }
                        })} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro, marginTop: 20 }}>
                    <Button
                        loading={this.state.salvarCarregando}
                        label="Finalizar"
                        className="p-button-success"
                        onClick={() => this.finalizarPedido()} />
                </div>
            </Dialog>

            <Header />

            <main style={{ padding: 20, width: "100%", maxWidth: 1200, margin: '0 auto', paddingBottom: 100 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <h1 style={{ fontSize: 20 }}>Detalhe do pedido</h1>
                        <h2 style={{ fontSize: 20, fontWeight: 'normal' }}>Nº {this.state.documento._codigo}</h2>
                    </div>
                </div>

                <Steps
                    style={{ marginTop: 20 }}
                    model={this.state.listaStep}
                    activeIndex={this.state.indexStep} />

                <div style={{ marginTop: 20 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal }}>
                        Pedido feito em
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal }}>
                        {this.util.formatarDataComTimestamp(this.state.documento.dataCadastro)}
                    </p>
                </div>
                <div style={{ marginTop: 10 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal }}>
                        Cliente
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal }}>
                        {this.state.documento._pessoaNome}
                    </p>
                </div>
                <div style={{ marginTop: 10 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal }}>
                        Local de entrega
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal }}>
                        {this.state.endereco.logradouro}, {this.state.endereco.numero}, {this.state.endereco.complemento && this.state.endereco.complemento + ','} {this.state.endereco.bairro}, {this.state.endereco.cidade}, {this.state.endereco.pais} - {this.state.endereco.uf}
                    </p>
                </div>
                <div style={{ marginTop: 10 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal }}>
                        Distância
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal }}>
                        {this.state.distancia?.distance?.text}
                    </p>
                </div>
                <div style={{ marginTop: 10 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal }}>
                        Duração aproximada
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal }}>
                        {this.state.distancia?.duration?.text}
                    </p>
                </div>
                {this.state.documento.status >= 3 &&
                    <div style={{ marginTop: 10 }}>
                        <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal }}>
                            Entregador
                        </label><br />
                        <div style={{ backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                <img
                                    style={{ width: 50, height: 50, borderRadius: 500, objectFit: 'contain' }}
                                    src={this.state.urlBase + this.state.pessoaEntregador.foto} />
                                <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    {this.state.pessoaEntregador.nome}
                                </p>
                            </div>
                        </div>
                    </div>}

                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: this.constante.corAzulMarinhoMedio, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20 }}>
                    <p style={{ width: '70%', fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                        Item
                    </p>
                    <p style={{ width: '10%', fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                        Quantidade
                    </p>
                    <p style={{ width: '10%', fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', textAlign: 'right' }}>
                        Preço unitário
                    </p>
                    <p style={{ width: '10%', fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', textAlign: 'right' }}>
                        Valor total
                    </p>
                </div>

                {this.state.listaDocumentoItem.map(pDocumentoItem =>
                    <div
                        key={Math.random()}
                        style={{ display: 'flex', alignItems: 'center', backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, cursor: 'pointer' }}>
                        <div style={{ width: '70%', display: 'flex', alignItems: 'center', gap: 20 }}>
                            <img
                                src={this.state.urlBase + pDocumentoItem._itemImagem}
                                style={{ width: 50, height: 50, object: 'contain' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    {pDocumentoItem._itemNome}
                                </p>
                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 16, color: this.constante.corPrincipal}}>
                                    {pDocumentoItem._itemDescricao}
                                </p>
                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    {pDocumentoItem.tags1}
                                </p>
                            </div>
                        </div>
                        <p
                            key={Math.random()}
                            style={{ width: '10%', fontSize: 16, color: this.constante.corPrincipal, marginTop: 5 }}>
                            {pDocumentoItem.quantidade}
                        </p>
                        <p
                            key={Math.random()}
                            style={{ width: '10%', fontSize: 16, color: this.constante.corPrincipal, marginTop: 5, textAlign: 'right' }}>
                            {this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}
                        </p>
                        <p
                            key={Math.random()}
                            style={{ width: '10%', fontSize: 16, color: this.constante.corPrincipal, marginTop: 5, textAlign: 'right' }}>
                            {this.util.formatarValor(pDocumentoItem.valorTotal)}
                        </p>
                    </div>)}

                <div style={{ width: '100%', marginTop: 20 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal, }}>
                        Valor total dos itens
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal, }}>
                        {this.util.formatarValor(this.state.documento.valorLiquido)}
                    </p>
                </div>

                <div style={{ width: '100%', marginTop: 20 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal, }}>
                        Valor do frete
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal, }}>
                        {this.util.formatarValor(this.state.documento.tagn0)}
                    </p>
                </div>

                <div style={{ width: '100%', marginTop: 20 }}>
                    <label style={{ fontSize: 16, fontWeight: 'bold', color: this.constante.corPrincipal, }}>
                        Valor total do pedido
                    </label><br />
                    <p style={{ fontSize: 18, color: 'black', marginTop: 5, backgroundColor: this.constante.corAzulMarinhoClaro2, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, padding: 5, color: this.constante.corPrincipal, }}>
                        {this.util.formatarValor(this.state.documento.valorTotal)}
                    </p>
                </div>

                <div style={{ margin: '50px 0' }}>
                    <hr />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {this.state.documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoEntregue &&
                        <Button
                            icon="pi pi-trash"
                            label="Finalizar pedido"
                            style={{ color: 'darkred', border: 'none', backgroundColor: 'transparent' }}
                            onClick={() => this.setState({ alertaFinalizarPedidoVisivel: true })} />}

                    {this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoEntregue && this.state.documento.observacao &&
                        <>
                            <p style={{ color: this.constante.corPrincipal, textAlign: 'center', fontWeight: 'bold' }}>
                                Motivo da finalização do pedido
                            </p>
                            <p style={{ color: this.constante.corPrincipal, textAlign: 'center' }}>
                                {this.state.documento.observacao}
                            </p>
                        </>}
                </div>
            </main>
        </div>
    }
}