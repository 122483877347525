import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Component, createRef } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import { Dialog } from "primereact/dialog";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import HeaderLojista from "../components/HeaderLojista";
import { Dropdown } from "primereact/dropdown";

export default class Frota extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    message = createRef();
    toast = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        carregando: false,
        salvarCarregando: false,
        pessoaRelacionamento: new PessoaRelacionamento(),
        listaPessoa: [],
        conteudo: "",
        inputError: {
            email: false,
        },
        novoVisivel: false,
        relacionamentoCarregando: false,
        deletarCarregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar(pessoaUsuario);

        console.log(pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        });
    }

    async listar(pPessoa) {
        this.setState({ carregando: true });

        let retorno = await this.pessoaService.listarRelacionamento(pPessoa);
        let listaPessoaRelacionamento = retorno.objeto

        console.log(listaPessoaRelacionamento);

        this.setState({
            listaPessoa: listaPessoaRelacionamento,
            carregando: false
        });
    }

    async novo(pEvento) {
        pEvento.preventDefault();
        console.log(pEvento.target[0].value);

        this.setState({ relacionamentoCarregando: true });

        const inputError = this.state.inputError;

        this.message.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        this.setState({ inputError: inputError });

        if (!pEvento.target[0].value) {
            inputError.email = true;
        }

        const retornoValidacao = this.util.validarEmail(pEvento.target[0].value);

        if (!retornoValidacao) {
            inputError.email = true;
        }

        let inputOK = true;

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        if (!inputOK) {
            this.setState({
                inputError: inputError,
                relacionamentoCarregando: false,
            });
            return;
        }

        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_pessoa_perfil";
        pesquisa.conteudo = this.constante.seqPessoaPerfilEntregador;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.email_plataforma_lirida";
        pesquisa.conteudo = pEvento.target[0].value;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        const retornolistaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);
        const pessoa = retornolistaPessoa.objeto[0];

        if (!pessoa?._seqPessoa) {
            this.message.current.show([
                { severity: 'error', summary: 'Usuário não encontrado!' },
            ]);
            this.setState({ relacionamentoCarregando: false });
            return;
        }

        const pessoaRelacionamento = new PessoaRelacionamento();
        pessoaRelacionamento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
        pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEntregador;
        pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
        pessoaRelacionamento.seqPessoaRelacionada = pessoa._seqPessoa;

        await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);

        this.setState({
            relacionamentoCarregando: false,
            novoVisivel: false,
        });

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.listar(this.state.pessoaUsuario);
    }

    async salvar() {
        const pessoa = this.state.pessoa;

        this.setState({ salvarCarregando: true });

        await this.pessoaService.salvar(pessoa);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.listar(this.state.pessoaUsuario);

        this.setState({
            entregadorDetalheVisivel: false,
            salvarCarregando: false,
        })
    }

    async deletarRelacionamento(pPessoa) {
        this.setState({deletarCarregando: true});

        await this.pessoaService.deletarRelacionamento(pPessoa);

        this.setState({deletarCarregando: false});

        this.listar(this.state.pessoaUsuario);        
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro }}>
            <HeaderLojista />

            <Toast ref={this.toast} />

            <Dialog
                header="Novo entregador"
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                contentStyle={{ padding: 0 }}
                visible={this.state.novoVisivel}
                onHide={() => this.setState({ novoVisivel: false })}>
                <Messages ref={this.message} />

                <form
                    onSubmit={(pEvento) => this.novo(pEvento)}>

                    <div style={{ padding: 20 }}>
                        <label>Email do entregador</label>
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.email && 'var(--red-400)' }} />
                        {this.state.inputError.email && <small style={{ color: 'var(--red-400)' }}>Email inválido</small>}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro }}>
                        <Button
                            type="button"
                            label="Cancelar"
                            className="p-button-outlined p-button-secondary"
                            style={{ backgroundColor: 'white' }}
                            onClick={() => this.setState({
                                novoVisivel: false
                            })} />
                        <Button
                            loading={this.state.relacionamentoCarregando}
                            type="submit"
                            label="Salvar"
                            className="p-button-success" />
                    </div>
                </form>
            </Dialog>

            <Dialog
                header={this.state.pessoa._seqPessoa ? "Detalhe do entregador" : "Novo entregador"}
                headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                style={{ width: "90%", maxWidth: 800, }}
                contentStyle={{ padding: 0 }}
                visible={this.state.entregadorDetalheVisivel}
                onHide={() => this.setState({ entregadorDetalheVisivel: false })}>
                <form
                    onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}>
                    <div style={{ padding: 20 }}>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa._pessoaRelacionadaNome} />
                        </div>
                        {/* <div style={{ marginTop: 10 }}>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                                Email
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.emailPlataformaLirida} />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                                Telefone
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.telefonePlataformaLirida} />
                        </div> */}
                        {/* <div style={{ marginTop: 10 }}>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', }}>
                                Situação
                            </label>
                            <br />
                            <Dropdown
                                placeholder="Selecione uma situação"
                                style={{ width: "100%", marginTop: 5 }}
                                options={[
                                    { label: "ATIVO", value: "ATIVO" },
                                    { label: "INATIVO", value: "INATIVO" },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        situacao: pEvento.value
                                    }
                                })}
                                value={this.state.pessoa.situacao} />
                        </div> */}
                    </div>
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10, padding: 20, backgroundColor: this.constante.corAzulMarinhoClaro }}>
                        <Button
                            type="button"
                            label="Cancelar"
                            className="p-button-outlined p-button-secondary"
                            style={{ backgroundColor: 'white' }}
                            onClick={() => this.setState({
                                entregadorDetalheVisivel: false
                            })} />
                        <Button
                            loading={this.state.salvarCarregando}
                            type="submit"
                            label="Salvar"
                            className="p-button-success" />
                    </div> */}
                </form>
            </Dialog>

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', marginTop: 20, padding: '0px 20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                    <h1 style={{ width: "20%", fontSize: 20 }}>Lista de entregadores</h1>

                    <form
                        style={{ width: "60%", display: 'flex', alignItems: 'center' }}
                        onSubmit={pEvento => { pEvento.preventDefault(); this.listar(this.state.pessoaUsuario); }}>
                        <InputText
                            placeholder="Pesquisar entregador"
                            style={{ width: "80%" }}
                            onChange={pEvento => this.setState({
                                conteudo: pEvento.target.value
                            })} />
                        <Button
                            label="Pesquisar"
                            loading={this.state.pesquisarCarregando}
                            type="submit"
                            icon="pi pi-search"
                            iconPos="left"
                            style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }} />
                    </form>

                    <div style={{ width: "20%", display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            label="+ Vincular novo entregador"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                            onClick={() => this.setState({ novoVisivel: true })} />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: this.constante.corAzulMarinhoMedio, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20 }}>
                    <p style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                        Entregador
                    </p>
                </div>

                {this.state.carregando &&
                    <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            loading={true}
                            label="Carregando"
                            style={{ backgroundColor: this.constante.corPrincipal, border: 'none', }} />
                    </div>}

                {this.state.listaPessoa.map(pPessoa =>
                    <div
                        key={Math.random()}
                        style={{ backgroundColor: "white", borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, borderRadius: 5, padding: '20px 10px', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
                            <img
                                style={{width: 50, height: 50, borderRadius: 500, objectFit: 'contain'}}
                                src={this.state.urlBase + pPessoa._pessoaRelacionadaFoto} />
                            <p
                                key={Math.random()}
                                style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold', textDecorationLine: 'underline', textDecorationColor: this.constante.corPrincipal, cursor: 'pointer' }}
                                onClick={() => this.setState({
                                    pessoa: pPessoa,
                                    entregadorDetalheVisivel: true,
                                })}>
                                {pPessoa._pessoaRelacionadaNome}
                            </p>
                        </div>

                        <Button
                            loading={this.state.deletarCarregando}
                            disabled={this.state.deletarCarregando}
                            icon="pi pi-trash"
                            className="p-button-danger"
                            onClick={() => this.deletarRelacionamento(pPessoa)} />
                    </div>)}
            </main>
        </div>
    }
}