import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Messages } from "primereact/messages";
import logo from '../../assets/imagens/logo.png';

export default class CadastroFabricante extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    mensagem = createRef();

    state = {
        pessoa: new Pessoa(),
        endereco: new Endereco(),
        carregando: false,
        inputError: {
            nome: false,
            apelido: false,
            tipoPessoa: false,
            documento: false,
            email: false,
            telefone: false,
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoa = this.state.pessoa;

        pessoa.tipoDocumento = 'CNPJ';

        this.setState({
            pessoa: pessoa,
        })
    }

    async cadastrar() {
        window.scrollTo(0, 0);

        let pessoa = this.state.pessoa;
        let listaPesquisa = [];
        let inputError = this.state.inputError;
        let inputsOK = true;
        let pessoaFabricante = new Pessoa();

        this.mensagem.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false
        });

        if (!pessoa.nome) {
            inputError.nome = true;
        }

        if (!pessoa.apelido) {
            inputError.apelido = true;
        }

        if (!pessoa.tipoDocumento) {
            inputError.tipoPessoa = true;
        }

        if (!pessoa.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(pessoa.documento)) {
            inputError.documento = true;
        } else if (pessoa.tipoDocumento === "CPF" && pessoa.documento?.length !== 14) {
            inputError.documento = true;
        } else if (pessoa.tipoDocumento === "CNPJ" && pessoa.documento?.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputsOK = false;
            }
        });

        if (!inputsOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilFabricante;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = pessoa.emailPlataformaLirida;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoaFabricante = retorno.objeto[0];

        if (pessoaFabricante) {
            this.setState({ carregando: false });
            this.mensagem.current.show({
                severity: 'error', summary: 'Usuário já cadastrado!', sticky: true
            });
            return;
        }

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilFabricante;
        pessoa.status = 0;

        const retornoPessoa = await this.pessoaService.salvar(pessoa);
        pessoa = retornoPessoa.objeto;

        console.log(pessoa);

        this.setState({ carregando: false });

        this.mensagem.current.show({
            severity: 'success', summary: 'Cadastro realizado com sucesso! Enviaremos as suas credênciais de acesso para seu e-mail', sticky: true
        });
        
        setTimeout(() => window.location.href = '/login', 5000);
    }

    render() {
        return <main style={{ width: "90%", padding: '110px 0 50px 0', margin: '0 auto' }}>
            <Messages ref={this.mensagem} />

            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} style={{ width: 400, height: 400, objectFit: "contain" }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
                    <form
                        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
                        onSubmit={pEvento => { pEvento.preventDefault(); this.cadastrar(); }}>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.nome && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome || ''} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome fantasia
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.apelido && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        apelido: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.apelido || ''} />
                            {this.state.inputError.apelido && <small style={{ color: 'red' }}>Nome fantasia inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Tipo de pessoa
                            </label>
                            <br />
                            <Dropdown
                                disabled
                                style={{ width: "100%", borderColor: this.state.inputError.tipoPessoa && 'red', marginTop: 5 }}
                                options={[
                                    { label: "Pessoa física", value: "CPF" },
                                    { label: "Pessoa jurídica", value: "CNPJ" },
                                ]}
                                // onChange={pEvento => this.setState({
                                //     pessoa: {
                                //         ...this.state.pessoa,
                                //         tipoDocumento: pEvento.value
                                //     }
                                // })}
                                value={this.state.pessoa.tipoDocumento || ''} />
                            {this.state.inputError.tipoPessoa && <small style={{ color: 'red' }}>Tipo de pessoa inválido</small>}
                        </div>
                        {this.state.pessoa.tipoDocumento === "CPF" &&
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CPF
                                </label>
                                <br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.documento && 'red', marginTop: 5 }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        {this.state.pessoa.tipoDocumento === "CNPJ" &&
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CNPJ
                                </label>
                                <br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.documento && 'red', marginTop: 5 }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Email
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.email && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida || ''} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Telefone
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.telefone && 'red', marginTop: 5 }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida || ''} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                        {/* <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Endereço
                            </label>
                            <br />
                            <div>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    {!this.state.pesquisaEnderecoVisivel && this.state.endereco.logradouro ?
                                        <i className="pi pi-check" />
                                        :
                                        <i className="pi pi-search" />
                                    }
                                    <InputText
                                        style={{ width: '100%', borderColor: this.state.inputError.endereco && 'red', marginTop: 5 }}
                                        onChange={pEvento => {
                                            this.setState({
                                                conteudoEndereco: pEvento.target.value,
                                                pesquisaEnderecoVisivel: true
                                            });
                                            this.listarEndereco(pEvento.target.value)
                                        }}
                                        value={this.state.conteudoEndereco || "" || ''} />
                                </span>
                                {this.state.inputError.endereco && <small style={{ color: 'red' }}>Endereço inválido</small>}
                            </div>

                            {this.state.listaEndereco.map(pEndereco =>
                                <Button
                                    type="button"
                                    key={Math.random()}
                                    label={pEndereco.description}
                                    className="p-button-link"
                                    style={{ width: "100%", textAlign: 'left' }}
                                    onClick={() => this.buscarEndereco(pEndereco)} />
                            )}
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Complemento
                            </label>
                            <br />
                            <InputText
                                style={{ width: "100%", marginTop: 5, }}
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.endereco.complemento || ''} />
                        </div> */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 10, marginTop: 10 }}>
                            <Button
                                loading={this.state.carregando}
                                type="submit"
                                label="Cadastrar"
                                className="p-button-success" />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    }
}