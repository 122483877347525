export default class Constante {
    // // /* DESENVOLVIMENTO */
    // url_base = 'http://192.168.1.67:8080/v1/api/';
    // hostBase = 'http://192.168.1.67:8080';

    /* PRODUÇÃO */
    url_base = 'https://api.lirida.com.br/v1/api/';
    hostBase = 'https://api.lirida.com.br'

    token = '91cbc49b-e0ef-4180-a0e5-8158abf36ab0';

    urlImagemPlaceholder = 'https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png'
    imagemUsuarioPlaceholder = 'https://media.istockphoto.com/vectors/default-avatar-profile-icon-grey-photo-placeholder-hand-drawn-modern-vector-id1273297967?k=20&m=1273297967&s=170667a&w=0&h=Iqo2x8teHMYPDg1OWFuPJOr6xBAFRLtJyIdTBPcLW7k=';

    corPrincipal = '#143460';
    corAzulMarinhoEscuro = '#8497ad';
    corAzulMarinhoMedio = '#a1b1c4';
    corAzulMarinhoClaro = '#d3d6db';
    corAzulMarinhoClaro2 = '#e1e5eb';

    corVerdeClaro = '#9fc9aa';
    corVerdeEscuro = '#497a5e'

    corVermelhoClaro = '#e0bfc3';
    corVermelhoEscuro = '#b5767d';

    /* PERFIL PERFIL */
    seqPessoaPerfilAdministrador = '8726dfb7-bec4-4adf-ab3b-fa3267154d94';
    seqPessoaPerfilLojista = 'a2918d8e-5b13-4f97-aee1-0afd3224c61a';
    seqPessoaPerfilEntregador = '345c12ef-0736-4c74-ab57-254cd1adbbd2';
    seqPessoaPerfilCliente = '3883fd59-309b-4f04-8f4e-2e28a52554d4';
    seqPessoaPerfilFabricante = '1c0b28e4-8537-4d53-aad3-f44b67a2accd';

    /* PESSOA CARACTERISTICA */
    // seqPessoaCaracteristicaBase = '1a0bd8b9-c954-416b-8a1a-a64c673a88ac';
    // seqPessoaCaracteristicaIexcombo = 'd2de7156-12e5-43d3-bff9-917f82c1d1c6';

    /* USUARIO */
    seqUsuario = "ed1c1a90-1d64-4e88-8730-0fd6d71a828e";

    seqPessoaAdministrador = "4a2146f0-df8d-429b-8a82-0e66affedf80";

    /* EMAIL */
    seqEmailAcesso = 'f7209dba-2ab9-4e94-a1f5-51b383893dc2';
    seqWhatsAppAcesso = "564fb24f-6e88-4dc1-88ba-9abf0ac8fb64"

    /* TABELA PREÇO */
    // seqTabelaPrecoTotem = '085f4583-c330-4c10-9058-d184c96071ef';
    // seqTabelaPrecoVendas = '938f3656-3f7f-44bc-aa88-3607552da07c';

    /* ITEM */
    // seqTipoRelacionamentoItemDonoProjeto = "aac52b1a-a3ff-4f96-bc4b-73c7f163f9b2"

    /* TIPO RELACIONAMENTO */
    seqTipoRelacionamentoEntregador = 'cf2d7ac6-7943-44da-8bec-09e11d196c20';
    seqTipoRelacionamentoFabricante = '4a2aae3f-55d9-415f-a9b2-0aac17016f17'
    // seqTipoRelacionamentoCliente = 'af729e07-16dd-4753-b686-21c2b3ada6b0';
    // seqTipoRelacionamentoTecnicoRejeitado = '59f0df28-447d-4a78-972f-e31bfb1d8b11';
    // seqTipoRelacionamentoTecnicoAgendado = 'a929318e-136c-4573-9ce8-372f5188ca82';
    // seqTipoRelacionamentoTecnicoResponsavel = 'c752aa3c-98fd-466b-a7d0-9f71a9fa9a86';
    // seqTipoRelacionamentoSolicitante = 'becc8f4d-57cb-4b00-a57c-c0669d5de85b';
    // seqTipoRelacionamentoDependenteIexcombo = '59289ace-6ec9-431a-a186-74085b97ec9c';
    // seqTipoRelacionamentoBase = 'ecf93dd3-5b33-41df-803d-d4f21529a8f8';

    /* FORMA PAGAMENTO */
    // seqFormaPagamentoPix = '3748d622-af97-41f2-871b-abb6075b1a34';
    // seqFormaPagamentoCartaoCredito = 'e0c37ea2-0d6b-406f-abfe-e9487a21425e';

    /* CONDIÇÃO PAGAMENTO FP */
    // seqCondicaoPagamentoFpCartaoCredito = 'c203fa1d-ef4a-4997-ac68-c64909dc4de5';

    /* DOCUMENTO ITEM CARACTERÍSTICA */
    // seqDocumentoItemCaracteristicaInstalacao = 'f9a36252-03c6-4d3b-966f-0d23bcf2a7c4';
    // seqDocumentoItemCaracteristicaManutencao = '0ec0c01e-7c83-466c-bf13-5cae7d6dc113';

    /* BANCO */
    // seqContaBancaria = '0717dad2-bff1-4eab-adf6-c47ab4b42d42';

    /* FINANCEIRO */
    // seqContaFinanceiraReceber = '4b6f7249-e293-48df-8425-09d44d550b0e';
    // seqContaFinanceiraPagar = '0520776a-3a36-4869-81d8-09eca0f139cd';

    /* TIPO ENDEREÇO */
    // seqTipoEnderecoEntrega = '7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09';
    // seqTipoEnderecoCobranca = 'b4062983-6166-4c91-8319-a25287b505b2';


    /* ITEM CARACTERISTICA */
    // seqItemCaracteristica = '1f02b95a-758b-4480-9f22-8b8d7bd3f7d0';

    /* ITEM CATEGORIA CARACTERÍSTICA */
    // seqItemCategoriaCaracteristaItemTotem = '7b9dfec9-5ef0-4045-979f-cab45bff5376';

    /* TIPO CONTATO */
    // seqTipoContatoCelular = '215e44df-7fb0-46ba-81ea-fc47984aa7ac';
    // seqTipoContatoWhatsApp = '40c9d9fa-df1f-49f1-8f90-59dc2001f093';
    // seqTipoContatoEmail = '787b95b2-d72b-46b4-8f94-9ec19f58b117';


    /* TIPO DOCUMENTO */
    seqTipoDocumento = 'ef94a50f-8f17-41c4-9fb1-523a315852de';

    /* TIPO DOCUMENTO PROCESSO */
    seqTipoDocumentoProcessoAguardandoPagamento = 'e76ccebd-d4e6-4731-a0c9-955599fec34c';
    seqTipoDocumentoProcessoPago = 'cd3231c4-1fd6-4394-aee1-5e1db5eb187e';
    seqTipoDocumentoProcessoEmSeparacao = '81cd5938-fba1-4cbf-8bda-0eaa1f56ee70';
    seqTipoDocumentoProcessoTransito = '37c53c43-144b-46d9-a66c-5c020c6e0668';
    seqTipoDocumentoProcessoEntregue = '3aa6b4cd-46dc-49c1-a64b-e3f06bf5676a';
    seqTipoDocumentoProcessoArquivado = '4ee75ae6-edc3-4977-9e05-bcf2dc5a7f80';

    /* ID */
    idListaContaBancaria = 'CB';
    idListaContaFinanceira = 'CF';
    idListaFormaPagamento = 'FP';

    // /* TAXAS PESSOA */
    // taxaBoleto = 3.8;
    // taxaCredito = 4.8;
    // taxaPix = 1.8;
    // tipoTaxaBoleto = "2";
    // tipoTaxaCredito = "1";
    // tipoTaxaPix = "2";

    seqTipoIndicadorAcessoGeral = 'f26ce8fa-c95d-4e08-8cc3-3d45d0880420';
    seqTipoIndicadorAcessoLojista = '2978be96-9041-4ab6-88a8-c8a68642caf6';
}