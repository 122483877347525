import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { Component, createRef } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";
import logo from '../../../assets/imagens/logo-branca.png';
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import FormEndereco from "./FormEndereco";
import FormDadosBancarios from "./FormDadosBancarios";
import FormDadosPessoais from "./FormDadosPessoais";
import { Toast } from "primereact/toast";
import TipoDocumentoProcessoService from "lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcessoService";

export default class HeaderLojista extends Component {
    constante = new Constante();
    util = new Util();
    mensagem = createRef();
    toast = createRef();

    tipoDocumentoProcessoService = new TipoDocumentoProcessoService(this.constante.token, this.constante.url_base);

    listaStep = [{ label: "Dados pessoais" }, { label: "Endereço" }, { label: "Dados bancários" }];

    state = {
        pessoaUsuario: new Pessoa(),
        dialogVisivel: false,
        dropdownPedidoVisivel: false,
        indexStep: 0,
        listaTipoDocumentoProcesso: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        if (!pessoaUsuario.contaS2pCodigo || !pessoaUsuario.seqEnderecoPrincipal) {
            this.mensagem.current.show([
                { severity: 'warn', summary: 'Clique aqui e complete seus dados!', sticky: true, closable: false },
            ])
        }

        const retornoListaTipoDocumentoProcesso = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcesso(this.constante.seqTipoDocumento);
        const listaTipoDocumentoProcesso = retornoListaTipoDocumentoProcesso.objeto;

        console.log("lista", listaTipoDocumentoProcesso);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            listaTipoDocumentoProcesso: listaTipoDocumentoProcesso,
        });
    }

    avancarFormulario() {
        const proximoIndexStep = this.state.indexStep + 1

        if (proximoIndexStep === this.listaStep.length) {
            this.toast.current.show([
                { severity: 'success', summary: 'Você completou todos os seus dados!' },
            ]);

            this.mensagem.current.clear();

            this.setState({ dialogVisivel: false });
        } else {
            this.setState({ indexStep: proximoIndexStep });
        }
    }

    render() {
        return <div>
            <Dialog
                style={{ width: 600 }}
                visible={this.state.dialogVisivel}
                onHide={() => this.setState({ dialogVisivel: false })}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Steps
                        model={this.listaStep}
                        activeIndex={this.state.indexStep} />
                    {this.state.indexStep === 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete seus dados pessoais</h2>
                            <FormDadosPessoais avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                    {this.state.indexStep === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete os dados do seu endereço</h2>
                            <FormEndereco avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                    {this.state.indexStep === 2 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete seus dados bancários</h2>
                            <FormDadosBancarios avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                </div>
            </Dialog>

            <Toast ref={this.toast} />

            <header style={{ height: 50, backgroundColor: this.constante.corPrincipal, padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <img src={logo} style={{ width: 150, height: 50, objectFit: "contain" }} />

                <nav style={{ display: 'flex', alignItems: "center" }}>
                    <Button
                        label="Dashboard"
                        onClick={() => window.location.href = "/sistema/lojista/dashboard"}
                        className="p-button-link"
                        style={{ color: 'white' }} />

                    <div style={{ position: 'relative' }}>
                        <Button
                            label="Pedidos"
                            onClick={() => window.location.href = "/sistema/lojista/pedido"}
                            className="p-button-link"
                            style={{ color: 'white' }} />
                    </div>
                    <Button
                        label="Frota"
                        onClick={() => window.location.href = "/sistema/lojista/frota"}
                        className="p-button-link"
                        style={{ color: 'white' }} />
                    <Button
                        label="Tabela de preço"
                        onClick={() => window.location.href = "/sistema/lojista/tabela_de_preco"}
                        className="p-button-link"
                        style={{ color: 'white' }} />
                    <div style={{ position: 'relative' }}>
                        <Button
                            label="Financeiro"
                            onClick={() => window.location.href = "/sistema/lojista/contas_a_receber"}
                            className="p-button-link"
                            style={{ color: 'white' }} />
                    </div>
                    <Button
                        label="Perfil"
                        onClick={() => window.location.href = "/sistema/lojista/perfil"}
                        className="p-button-link"
                        style={{ color: 'white' }} />
                    <Button
                        label="Sair"
                        onClick={() => {
                            this.util.removerLocalStorage("PESSOA_USUARIO");
                            window.location.href = "/login";
                        }}
                        className="p-button-link"
                        style={{ color: 'white' }} />
                </nav>
            </header>

            <Messages
                style={{ cursor: "pointer" }}
                ref={this.mensagem}
                onClick={() => this.setState({
                    dialogVisivel: true
                })} />
        </div>
    }
}