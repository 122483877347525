import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Component, createRef } from "react";
import logo from '../../../assets/imagens/logo-branca.png';
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";

export default class HeaderFabricante extends Component {
    constante = new Constante();
    util = new Util();
    toast = createRef();

    render() {
        return <header style={{ height: 50, backgroundColor: this.constante.corPrincipal, padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Toast ref={this.toast} />

            <img src={logo} style={{ width: 150, height: 50, objectFit: "contain" }} />

            <nav style={{ display: 'flex', alignItems: "center" }}>
                <Button
                    label="Cadastro"
                    onClick={() => window.location.href = "/sistema/fabricante/cadastro"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Perfil"
                    onClick={() => window.location.href = "/sistema/fabricante/perfil"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Sair"
                    onClick={() => {
                        this.util.removerLocalStorage("PESSOA_USUARIO");
                        window.location.href = "/login";
                    }}
                    className="p-button-link"
                    style={{ color: 'white' }} />
            </nav>
        </header>
    }
}