import { Component } from "react";
import imagemBanner from '../../assets/imagens/slide.png';
import imagemFlutuante1 from '../../assets/imagens/imagem-flutuante-1.png';
import imagemFlutuante2 from '../../assets/imagens/imagem-flutuante-2.png';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Constante from "../../util/Constante";
import pessoa1 from '../../assets/imagens/moto.jpg';
import pessoa2 from '../../assets/imagens/moto1.jpg';
import pessoa3 from '../../assets/imagens/moto2.jpg';

export default class Hotsite extends Component {
  constante = new Constante();

  componentDidMount() {
    this.iniciar();
  }

  iniciar() {
    if (window.location.href.includes("#")) {
      const rotaHashtag = window.location.href.split("#")[1];
      window.location.href = "#" + rotaHashtag;
    }
  }

  render() {
    return <>
      <main className="h-main-container">
        <img className="h-slide-img" src={imagemBanner} alt="imagem principal" />

        <div className="h-sobre">
          <section className="h-sobre-container" id="sobre">
            <div>
              <i className="pi pi-building" style={{ fontSize: 30 }}></i>
              <h2>Quem somos</h2>
              <p>
                O Zé Marceneiro veio facilitar a vida de todos que precisam entregar o seu projeto de montagem de móveis. Faltou alguma peça? O Zé marceneiro leva pra você! Não fique mais de mãos atadas, nós conectamos você lojista, projetista, marceneiro e montador que faz seus projetos a lojas de componentes e ferragens para marcenaria em todo território brasileiro. Assim facilitando a vida do montador que precisa de uma peça, aumentando as vendas do lojista, a velocidade e a praticidade das montagens.  
              </p>
            </div>
            <div>
              <i className="pi pi-cog" style={{ fontSize: 30 }}></i>
              <h2>Como funciona</h2>
              <p>
                Faltou ou precisou de alguma peça de última hora? O Zé Marceneiro entrega pra você! Agora não é mais preciso sair pelas ruas procurando a loja de ferragens para marcenaria mais perto de você, o Zé Marceneiro está perto de você o tempo todo. Muito mais do que uma Loja Mobile: Uma solução que vai facilitar seu dia a dia e te dar mais controle em suas montagens
              </p>
            </div>
            <div>
              <i className="pi pi-users" style={{ fontSize: 30 }}></i>
              <h2>Para quem</h2>
              <p>
                Para lojistas e profissionais que tem sua equipe de montagem, que faz projeto de móveis e querem ter a facilidade de pedir qualquer material de qualquer loja na região com apenas alguns cliques no seu celular. Assim trazendo facilidade e mais controle. Feito também para lojistas de componentes e ferragens para marcenaria que querem fazer parcerias e aumentar as suas vendas fornecendo os materiais para montadores da sua região. Crie sua conta, cadastre seus produtos e saia vendendo!
              </p>
            </div>
          </section>
        </div>

        <div className="h-func">
          <section className="h-func-container" id="funcionalidades">
            <div>
              <p>
                Resolva os imprevistos de forma rápida e segura
              </p>
              <img src={imagemFlutuante1} alt="imagem flutuante" />
            </div>

            <div>
              <p>
                Melhore o controle financeiro sobre os custos com materiais do projeto
              </p>
            </div>

            <div>
              <img src={imagemFlutuante2} alt="imagem flutuante" />
              <p>
                Conheça o real sentido de Facilidade e Segurança
              </p>
            </div>
          </section>
        </div>

        <div className="h-depo">
          <section className="h-depo-container" id="depoimentos">
            <div>
              <img src={pessoa1} alt="foto pessoa" />
              <div>
                <h2>
                  Leonardo (Dono de Distribuidora)
                </h2>
                <p>
                  “O Zé Marceneiro facilitou muito minha vida e otimizou os processos. Agora tenho mais controle sobre os meus custos e reduziu muito o tempo de entrega.”
                </p>
              </div>
            </div>

            <div>
              <img src={pessoa2} alt="foto pessoa" />
              <div>
                <h2>
                  Claudio (Montador)
                </h2>
                <p>
                  “Depois do Zé Marceneiro eu não preciso mais ficar caçando lojas de componentes e ferragens para marcenaria. É só pedir que o Zé marceneiro traz.”
                </p>
              </div>
            </div>

            <div>
              <img src={pessoa3} alt="foto pessoa" />
              <div>
                <h2>
                  Victor (Lojista)
                </h2>
                <p>
                  “Além de ser muito fácil de usar e de cadastrar meu produtos, essa parceria com o Zé Marceneiro só tem aumentado as minhas vendas.”
                </p>
              </div>
            </div>
          </section>
        </div>

        <section className="h-faq-container" id="perguntas-frequentes">
          <h2>Perguntas frequentes</h2>

          <Accordion multiple>
            <AccordionTab header="Preciso pagar alguma mensalidade para usar a plataforma?">
              <p>Não, para utilizar nossa plataforma e ter acesso aos
                recursos é totalmente livre de mensalidade.</p>
            </AccordionTab>
            <AccordionTab header="Depois que criei a conta, posso editar minhas informações?">
              <p>Sim, suas informações ficam disponíveis para edição quando você quiser.</p>
            </AccordionTab>
            <AccordionTab header="Como faço para me cadastrar na plataforma?">
              <p>Entre em contato conosco no email atendimento@zemarceneiro.com.br</p>
            </AccordionTab>
            <AccordionTab header="Eu posso cancelar ou excluir minha conta?">
              <p>Sim, você tem total autonomia e controle sobre sua conta A qualquer momento você exclui sua sem custo nenhum.</p>
            </AccordionTab>
            <AccordionTab header="Preciso passar por alguma análise?">
              <p>Sim, buscamos levar o melhor atendimento para os clientes, assim para manter a segurança e qualidade dos serviços é preciso que o profissional passe por uma análise.</p>
            </AccordionTab>
          </Accordion>
        </section>
      </main>
    </>
  }
}