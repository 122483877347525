import { Component, createRef } from "react";
import Header from "../components/Header";
import { InputText } from "primereact/inputtext";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../../util/Constante";
import FormAlterarSenha from "../components/FormAlterarSenha";
import Util from "../../../util/Util";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";

class Banco {
    _codigo;
    _nome;
    _seqBanco;
}

export default class Perfil extends Component {
    util = new Util();
    constante = new Constante();
    cepService = new CepService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        urlBase: '',
        endereco: new Endereco(),
        banco: new Banco(),
        fotoCarregando: false,
        tab: "Meu perfil",
        listaBanco: [],
        inputError: {
            nome: false,
            email: false,
            telefone: false,
            documento: false,
            responsavelDocumento: false,
            responsavelNome: false,
            banco: false,
            agencia: false,
            digitoAgencia: false,
            conta: false,
            digitoConta: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
            percentualGanho: false,
        },
        contaAlterada: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let endereco = new Endereco();
        let banco = new Banco();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        console.log(pessoaUsuario)

        this.setState({ urlBase: urlBase });

        const listaBanco = await this.util.listarBanco('');

        console.log(listaBanco)

        if (pessoaUsuario.contaCodigoBanco) {
            for (let i = 0; i < listaBanco.length; i++) {
                if (listaBanco[i]._codigo === pessoaUsuario.contaCodigoBanco) {
                    banco = listaBanco[i];
                }
            }
        }

        const retorno = await this.pessoaService.listarEndereco(pessoaUsuario);
        const listaEndereco = retorno.objeto;

        listaEndereco.forEach(pEndereco => {
            if (pessoaUsuario.seqEnderecoPrincipal === pEndereco._seqEndereco) {
                endereco = pEndereco;
            }
        })

        this.setState({
            pessoaUsuario: pessoaUsuario,
            listaBanco: listaBanco,
            endereco: endereco,
            banco: banco,
        })
    }

    async salvar() {
        const inputError = this.state.inputError;
        let endereco = this.state.endereco;
        let pessoaUsuario = this.state.pessoaUsuario;

        this.toast.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        })

        if (!this.state.pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.state.pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.state.pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.state.pessoaUsuario.documento) {
            inputError.documento = true;
        }

        if (this.state.pessoaUsuario.tipoDocumento === "CPF" && this.state.pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        }
        if (this.state.pessoaUsuario.tipoDocumento === "CNPJ" && this.state.pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        if (this.state.pessoaUsuario.tipoDocumento === "CNPJ") {
            if (!this.state.pessoaUsuario.contaResponsavelCpf) {
                inputError.responsavelDocumento = true;
            }

            if (!this.state.pessoaUsuario.contaResponsavelNome) {
                inputError.responsavelNome = true;
            }

            const retorno = await this.util.validarDocumento(this.state.pessoaUsuario.contaResponsavelCpf);

            if (!retorno) {
                inputError.responsavelDocumento = true;
            }
        }

        // if (!this.state.pessoaUsuario.contaCodigoBanco) {
        //     inputError.banco = true;
        // }

        if (!this.state.pessoaUsuario.contaAgencia) {
            inputError.agencia = true;
        }

        if (!this.state.pessoaUsuario.contaNumeroConta) {
            inputError.conta = true;
        }

        if (!this.state.pessoaUsuario.contaDigitoConta) {
            inputError.digitoConta = true;
        }
        if (!this.state.endereco.cep) {
            inputError.cep = true;
        }

        if (!this.state.endereco.logradouro) {
            inputError.logradouro = true;
        }

        if (!this.state.endereco.numero) {
            inputError.numero = true;
        }

        if (!this.state.endereco.bairro) {
            inputError.bairro = true;
        }

        if (!this.state.endereco.cidade) {
            inputError.cidade = true;
        }

        if (!this.state.endereco.estado) {
            inputError.estado = true;
        }

        if (!this.state.endereco.uf) {
            inputError.uf = true;
        }

        if (!(!!Number(this.state.pessoaUsuario.tagn3))) {
            inputError.percentualGanho = true;
        }

        let inputOK = true;

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        this.setState({ inputError: inputError });

        if (!inputOK) {
            return;
        }

        this.setState({ carregando: true });

        const retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retornoPessoa.objeto;

        if (!pessoaUsuario.seqEnderecoPrincipal) {
            endereco.seqUsuario = this.constante.seqUsuario;
            endereco.seqPessoa = pessoaUsuario._seqPessoa;
            endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

            const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
            endereco = retornoEndereco.objeto;

            pessoaUsuario.seqEnderecoPrincipal = endereco._seqEndereco;

            const retornoPessoaEndereco = await this.pessoaService.salvar(pessoaUsuario);
            pessoaUsuario = retornoPessoaEndereco.objeto;

        } else {
            const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
            endereco = retornoEndereco.objeto;
        }

        if (this.state.contaAlterada) {
            pessoaUsuario = await this.salvarContaBanco(pessoaUsuario)
        } else {
            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

            this.toast.current.show([
                { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
            ]);
        }

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
            carregando: false,
        });
    }

    async salvarContaBanco(pPessoa) {
        this.setState({ carregando: true, });

        if (!pPessoa.contaDigitoAgencia) {
            pPessoa.contaDigitoAgencia = '0'
        }

        console.log(pPessoa);

        const retorno = await this.pessoaService.salvarContaBanco(pPessoa);
        pPessoa = retorno.objeto;

        if (!pPessoa.contaS2pCodigo) {
            this.toast.current.show([
                { severity: 'error', sticky: true, summary: 'Confira seus dados bancários e tente novamente' },
            ]);
        } else {
            const retornoPessoaEndereco = await this.pessoaService.salvar(pPessoa);
            pPessoa = retornoPessoaEndereco.objeto;

            this.util.salvarLocalStorage("PESSOA_USUARIO", pPessoa);

            this.toast.current.show([
                { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
            ]);
        }

        this.setState({ contaAlterada: false });

        return pPessoa;
    }

    async buscarCEP(pCep) {
        const endereco = this.state.endereco;

        const retorno = await this.cepService.buscarCep(pCep);

        endereco.bairro = retorno.bairro;
        endereco.complemento = retorno.complemento;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.logradouro = retorno.logradouro;
        endereco.uf = retorno.uf;

        this.setState({ endereco: endereco });
    }

    render() {
        return <div style={{ width: "100%", minHeight: "100vh", backgroundColor: this.constante.corAzulMarinhoClaro, paddingBottom: 100 }}>
            <Header />

            <Toast ref={this.toast} />

            <main style={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 20, display: 'flex', alignItems: 'flex-start', gap: 20 }}>
                <div style={{ width: 300, display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Button
                        icon="pi pi-home"
                        label="Meu perfil"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Meu perfil" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Meu perfil" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Meu perfil"
                        })} />
                    <Button
                        icon="pi pi-user"
                        label="Dados cadastrais"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Dados cadastrais" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Dados cadastrais" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Dados cadastrais"
                        })} />
                    <Button
                        icon="pi pi-lock"
                        label="Segurança"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Segurança" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Segurança" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Segurança"
                        })} />
                    <Button
                        icon="pi pi-dollar"
                        label="Taxas"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Taxas" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Taxas" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Taxas"
                        })} />
                    <Button
                        icon="pi pi-percentage"
                        label="Ganho"
                        style={{ width: '100%', textAlign: 'left', color: this.constante.corPrincipal, border: "none", padding: '15px 10px', backgroundColor: this.state.tab === "Ganho" && this.constante.corAzulMarinhoMedio }}
                        className={this.state.tab === "Ganho" ? null : "p-button-link"}
                        onClick={() => this.setState({
                            tab: "Ganho"
                        })} />
                </div>

                {this.state.tab === "Meu perfil" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 20 }}>

                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-user" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.nome}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-at" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.emailPlataformaLirida}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-phone" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>{this.state.pessoaUsuario.telefonePlataformaLirida}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: 'flex-start', gap: 10 }}>
                            <i className="pi pi-clock" style={{ color: this.constante.corAzulMarinhoMedio }}></i>
                            <p>Usuário desde {this.util.formatarDataComTimestamp(this.state.pessoaUsuario.dataCadastro)}</p>
                        </div>
                    </div>}

                {this.state.tab === "Dados cadastrais" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seus dados</h1><br />

                        <form
                            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Nome
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.nome || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            nome: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.nome && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Email
                                </label>
                                <InputText
                                    disabled
                                    value={this.state.pessoaUsuario.emailPlataformaLirida || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            emailPlataformaLirida: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Telefone
                                </label>
                                <InputText
                                    value={this.state.pessoaUsuario.telefonePlataformaLirida || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            telefonePlataformaLirida: this.util.formatarTelefone(e.target.value)
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.telefone && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Tipo de pessoa
                                </label>
                                <Dropdown
                                    // disabled
                                    options={[
                                        { label: "Pessoa física", value: "CPF" },
                                        { label: "Pessoa jurídica", value: "CNPJ" }
                                    ]}
                                    value={this.state.pessoaUsuario.tipoDocumento || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tipoDocumento: e.value
                                        }
                                    })}
                                    style={{ width: '100%', marginTop: 5 }} />
                            </div>
                            {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                                <div>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        CPF
                                    </label>
                                    <InputText
                                        type="tel"
                                        value={this.state.pessoaUsuario.documento || ''}
                                        onChange={e => {
                                            if (e.target.value.length > 14) return;

                                            this.setState({
                                                pessoaUsuario: {
                                                    ...this.state.pessoaUsuario,
                                                    documento: this.util.formatarCPF(e.target.value)
                                                }
                                            })
                                        }}
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                                </div>}

                            {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                                <div>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        CNPJ
                                    </label>
                                    <InputText
                                        type="tel"
                                        value={this.state.pessoaUsuario.documento || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                documento: this.util.formatarCNPJ(e.target.value)
                                            }
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                                </div>}

                            <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seus dados bancários</h1>

                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Nome do responsável
                                </label>
                                <InputText
                                    disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                    value={this.state.pessoaUsuario.contaResponsavelNome || ''}
                                    onChange={e => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            contaResponsavelNome: e.target.value
                                        },
                                        contaAlterada: true,
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.responsavelNome && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.responsavelNome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CPF do responsável
                                </label>
                                <InputText
                                    disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                    value={this.state.pessoaUsuario.contaResponsavelCpf || ''}
                                    onChange={e => {
                                        if (e.target.value.length > 14) return;

                                        this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaResponsavelCpf: this.util.formatarCPF(e.target.value)
                                            },
                                            contaAlterada: true,
                                        })
                                    }}
                                    style={{ width: '100%', borderColor: this.state.inputError.responsavelDocumento && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.responsavelDocumento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Banco
                                </label>
                                <Dropdown
                                    filter
                                    showClear
                                    showFilterClear
                                    options={this.state.listaBanco}
                                    optionLabel="_nome"
                                    value={this.state.banco}
                                    onChange={e => this.setState({
                                        banco: e.value,
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            contaCodigoBanco: e.value._codigo
                                        },
                                        contaAlterada: true
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.banco && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.banco && <small style={{ color: 'var(--red-600)' }}>Banco inválido</small>}
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <div style={{ width: '70%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Agência
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaAgencia || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaAgencia: e.target.value
                                            },
                                            contaAlterada: true,
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.agencia && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.agencia && <small style={{ color: 'var(--red-600)' }}>Agência inválida</small>}
                                </div>
                                <div style={{ width: '30%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Digito da agência
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaDigitoAgencia || ''}
                                        maxLength={3}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaDigitoAgencia: e.target.value
                                            },
                                            contaAlterada: true,
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.digitoAgencia && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.digitoAgencia && <small style={{ color: 'var(--red-600)' }}>Dígito da agência inválido</small>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <div style={{ width: '70%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Conta
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaNumeroConta || ''}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaNumeroConta: e.target.value
                                            },
                                            contaAlterada: true
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.conta && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.conta && <small style={{ color: 'var(--red-600)' }}>Conta inválida</small>}
                                </div>
                                <div style={{ width: '30%' }}>
                                    <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                        Digito da conta
                                    </label>
                                    <InputText
                                        disabled={!this.state.pessoaUsuario.seqEnderecoPrincipal}
                                        value={this.state.pessoaUsuario.contaDigitoConta || ''}
                                        maxLength={3}
                                        onChange={e => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario,
                                                contaDigitoConta: e.target.value
                                            },
                                            contaAlterada: true
                                        })}
                                        style={{ width: '100%', borderColor: this.state.inputError.digitoConta && 'var(--red-600)', marginTop: 5 }} />
                                    {this.state.inputError.digitoConta && <small style={{ color: 'var(--red-600)' }}>Dígito da conta inválido</small>}
                                </div>
                            </div>
                            {this.state.pessoaUsuario.contaS2pCodigo &&
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <i className="pi pi-check" style={{ color: "green", fontSize: 20 }}></i>
                                    <p>Conta cadastrada</p>
                                </div>
                            }

                            <h1 style={{ fontSize: 20, textAlign: 'center' }}>Seu endereço</h1>

                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CEP
                                </label>
                                <div className="p-inputgroup">
                                    <InputText
                                        type="tel"
                                        onChange={e => this.setState({
                                            endereco: {
                                                ...this.state.endereco,
                                                cep: this.util.formatarCEP(e.target.value),
                                            }
                                        })}
                                        value={this.state.endereco.cep}
                                        style={{ width: '100%', borderColor: this.state.inputError.cep && 'var(--red-600)', marginTop: 5 }} />
                                    <Button
                                        icon="pi pi-search"
                                        type="button"
                                        onClick={() => this.buscarCEP(this.state.endereco.cep)} />
                                </div>
                                {this.state.inputError.cep && <small style={{ color: 'var(--red-600)' }}>Cep inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Logradouro
                                </label>
                                <InputText
                                    value={this.state.endereco.logradouro}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            logradouro: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.logradouro && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.logradouro && <small style={{ color: 'var(--red-600)' }}>Logradouro inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Número
                                </label>
                                <InputText
                                    value={this.state.endereco.numero}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            numero: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.numero && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.numero && <small style={{ color: 'var(--red-600)' }}>Número inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Bairro
                                </label>
                                <InputText
                                    value={this.state.endereco.bairro}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            bairro: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.bairro && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.bairro && <small style={{ color: 'var(--red-600)' }}>Bairro inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Cidade
                                </label>
                                <InputText
                                    value={this.state.endereco.cidade}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            cidade: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.cidade && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.cidade && <small style={{ color: 'var(--red-600)' }}>Cidade inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Estado
                                </label>
                                <InputText
                                    value={this.state.endereco.estado}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            estado: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.estado && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.estado && <small style={{ color: 'var(--red-600)' }}>Estado inválido</small>}
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    UF
                                </label>
                                <InputText
                                    value={this.state.endereco.uf}
                                    onChange={e => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            uf: e.target.value,
                                        }
                                    })}
                                    style={{ width: '100%', borderColor: this.state.inputError.uf && 'var(--red-600)', marginTop: 5 }} />
                                {this.state.inputError.uf && <small style={{ color: 'var(--red-600)' }}>UF inválido</small>}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    loading={this.state.carregando}
                                    type="submit"
                                    label='Salvar'
                                    className="p-button-success" />
                            </div>
                        </form>
                    </div>}

                {this.state.tab === "Segurança" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Altere sua senha</h1><br />

                        <FormAlterarSenha />
                    </div>}

                {this.state.tab === "Taxas" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Taxas</h1><br />

                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }} >
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Taxa do boleto
                                </label>
                                <InputText
                                    disabled
                                    value={`R$ ${this.state.pessoaUsuario.taxaBoleto}`}
                                    style={{ width: '100%', marginTop: 5, }} />
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Taxa do cartão de crédito
                                </label>
                                <InputText
                                    disabled
                                    value={`${this.state.pessoaUsuario.taxaCredito}%`}
                                    style={{ width: '100%', marginTop: 5, }} />
                            </div>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Taxa do PIX
                                </label>
                                <InputText
                                    disabled
                                    value={`R$ ${this.state.pessoaUsuario.taxaPix}`}
                                    style={{ width: '100%', marginTop: 5, }} />
                            </div>
                        </form>
                    </div>}

                {this.state.tab === "Ganho" &&
                    <div style={{ width: "100%", backgroundColor: "white", padding: 20, borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: this.constante.corAzulMarinhoEscuro, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 20 }}>
                        <h1 style={{ fontSize: 20, textAlign: 'center' }}>Ganho</h1><br />

                        <form
                            onSubmit={pEvento => { pEvento.preventDefault(); this.salvar() }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    Percentual de ganho
                                </label>
                                <InputText
                                    style={{ width: '100%', marginTop: 5, borderColor: this.state.inputError.percentualGanho && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            tagn3: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.tagn3} />
                                {this.state.inputError.percentualGanho && <small style={{ color: 'var(--red-600)' }}>Percentual de ganho inválido</small>}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    loading={this.state.carregando}
                                    type="submit"
                                    label='Salvar'
                                    className="p-button-success" />
                            </div>
                        </form>
                    </div>}
            </main>
        </div>
    }
}