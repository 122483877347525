import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Component } from "react";
import Constante from "../../../util/Constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Header from "../components/Header";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Util from "../../../util/Util";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";

export default class Cliente extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        carregando: false,
        pessoa: new Pessoa(),
        listaPessoa: [],
        conteudo: '',
        dialogPessoaVisivel: false,
        quantidadePedidosPago: 0,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.listar();

        this.setState({ pessoaUsuario: pessoaUsuario });
    }

    async listar() {
        this.setState({ carregando: true });

        const listaPesquisa = [];

        const pesquisa1 = new Pesquisa();
        pesquisa1.campo = 'pessoa.seq_usuario';
        pesquisa1.conteudo = this.constante.seqUsuario;
        pesquisa1.operacao = '';
        pesquisa1.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa1);

        const pesquisa2 = new Pesquisa();
        pesquisa2.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa2.conteudo = this.constante.seqPessoaPerfilCliente;
        pesquisa2.operacao = 'AND';
        pesquisa2.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa2);

        const pesquisa3 = new Pesquisa();
        pesquisa3.campo = 'pessoa.nome';
        pesquisa3.conteudo = this.state.conteudo;
        pesquisa3.operacao = 'AND';
        pesquisa3.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa3);

        const retorno = await this.pessoaService.listarComFiltro(listaPesquisa);

        const listaPessoa = retorno.objeto;

        for (let i = 0; i < listaPessoa.length; i++) {
            const pessoa = listaPessoa[i];

            const [dia, mes, ano] = pessoa.dataCadastro.split("T")[0].split("-");

            pessoa.dataCadastro = new Date(Number(ano), Number(mes), Number(dia));
        }

        this.setState({
            listaPessoa: listaPessoa,
            carregando: false,
        });
    }

    exportarPlanilha() {
        let lista = [];

        this.state.listaPessoa.forEach(pessoa => {
            lista.push({
                "Nome": pessoa.nome?.toString(),
                "Documento": pessoa.documento?.toString(),
                "Status": pessoa.situacao?.toString(),
                "Data de Cadastro": pessoa.dataCadastro?.toLocaleDateString("pt-br"),
            });
        });

        this.util.exportarExcel(lista, "Lista de Clientes ( Zé Marceneiro )");
    }

    async selecionarPessoa(e) {
        this.setState({
            pessoa: e.value,
            dialogPessoaVisivel: true,
        });

        const listaPesquisa = [];

        const pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa);

        const pesquisa10 = new Pesquisa();
        pesquisa10.campo = 'documento.seq_pessoa_pagador';
        pesquisa10.conteudo = e.value._seqPessoa;
        pesquisa10.operacao = 'AND';
        pesquisa10.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa10);

        const pesquisa20 = new Pesquisa();
        pesquisa20.campo = 'documento.pago';
        pesquisa20.conteudo = 'S';
        pesquisa20.operacao = 'AND';
        pesquisa20.tipo = 'TEXTO';

        listaPesquisa.push(pesquisa20);

        const retorno = await this.documentoService.listarComFiltro(listaPesquisa);

        this.setState({
            quantidadePedidosPago: retorno.objeto.length
        })
    }

    render() {
        return (
            <>

                <Dialog
                    header="Detalhe do cliente"
                    headerStyle={{ color: this.constante.corPrincipal, backgroundColor: this.constante.corAzulMarinhoMedio }}
                    style={{ width: "90%", maxWidth: 800, }}
                    contentStyle={{ padding: 0 }}
                    visible={this.state.dialogPessoaVisivel}
                    onHide={() => this.setState({ dialogPessoaVisivel: false, quantidadePedidosPago: 0, pessoa: new Pessoa() })}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 20, padding: 20 }}>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Nome
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.nome}
                            />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Tipo de pessoa
                            </label>
                            <br />
                            <Dropdown
                                disabled
                                placeholder="Selecione um tipo de pessoa"
                                style={{ width: "100%", marginTop: 5 }}
                                options={[
                                    { label: "Pessoa física", value: "CPF" },
                                    { label: "Pessoa jurídica", value: "CNPJ" },
                                ]}
                                value={this.state.pessoa.tipoDocumento}
                            />
                        </div>
                        {this.state.pessoa.tipoDocumento === "CPF" &&
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CPF
                                </label>
                                <br />
                                <InputText
                                    disabled
                                    style={{ width: "100%", marginTop: 5 }}
                                    value={this.state.pessoa.documento}
                                />
                            </div>}
                        {this.state.pessoa.tipoDocumento === "CNPJ" &&
                            <div>
                                <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                    CNPJ
                                </label>
                                <br />
                                <InputText
                                    disabled
                                    style={{ width: "100%", marginTop: 5 }}
                                    value={this.state.pessoa.documento}
                                />
                            </div>}
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Email de Acesso
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.emailPlataformaLirida}
                            />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Senha de Acesso
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.senha}
                            />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Telefone
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.pessoa.telefonePlataformaLirida}
                            />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Situação
                            </label>
                            <br />
                            <Dropdown
                                disabled
                                placeholder="Selecione uma situação"
                                style={{ width: "100%", marginTop: 5 }}
                                options={[
                                    { label: "ATIVO", value: "ATIVO" },
                                    { label: "INATIVO", value: "INATIVO" },
                                ]}
                                value={this.state.pessoa.situacao}
                            />
                        </div>
                        <div>
                            <label style={{ fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}>
                                Quantidade de pedidos pagos
                            </label>
                            <br />
                            <InputText
                                disabled
                                style={{ width: "100%", marginTop: 5 }}
                                value={this.state.quantidadePedidosPago}
                            />
                        </div>
                    </div>
                </Dialog>

                <div style={{ width: '100%', height: '100%', minHeight: '100vh', background: this.constante.corAzulMarinhoClaro }}>
                    <Header />

                    <main style={{ width: '100%', padding: 20, maxWidth: 1280, margin: '0 auto' }}>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                            <h1 style={{ width: "20%", fontSize: 20 }}>Lista de clientes</h1>

                            <Button
                                icon="pi pi-file-excel"
                                label="Exportar planilha"
                                style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
                                onClick={() => this.exportarPlanilha()}
                            />
                        </div>


                        <form
                            style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
                            onSubmit={pEvento => {
                                pEvento.preventDefault(); this.listar();
                            }}>
                            <InputText
                                placeholder="Pesquisar cliente"
                                style={{ width: "80%" }}
                                onChange={pEvento => this.setState({
                                    conteudo: pEvento.target.value
                                })}
                            />
                            <Button
                                label="Pesquisar"
                                loading={this.state.carregando}
                                type="submit"
                                icon="pi pi-search"
                                iconPos="left"
                                style={{ width: "20%", backgroundColor: this.constante.corPrincipal, border: 'none' }}
                            />
                        </form>

                        <DataTable
                            removableSort
                            value={this.state.listaPessoa}
                            style={{ marginTop: 20 }}
                            selectionMode="single"
                            onSelectionChange={e => this.selecionarPessoa(e)}
                        >
                            <Column
                                sortable
                                header="Nome"
                                field="nome"
                                headerStyle={{ backgroundColor: this.constante.corAzulMarinhoMedio, fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}
                                style={{ color: this.constante.corPrincipal }}
                            />
                            <Column
                                sortable
                                header="Documento"
                                field="documento"
                                headerStyle={{ backgroundColor: this.constante.corAzulMarinhoMedio, fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}
                                style={{ color: this.constante.corPrincipal }}
                            />
                            <Column
                                sortable
                                header="Status"
                                field="situacao"
                                headerStyle={{ backgroundColor: this.constante.corAzulMarinhoMedio, fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}
                                style={{ color: this.constante.corPrincipal }}
                            />
                            <Column
                                sortable
                                header="Data de Cadastro"
                                field="dataCadastro"
                                body={pessoa => pessoa.dataCadastro?.toLocaleDateString("pt-br")}
                                headerStyle={{ backgroundColor: this.constante.corAzulMarinhoMedio, fontSize: 16, color: this.constante.corPrincipal, fontWeight: 'bold' }}
                                style={{ color: this.constante.corPrincipal }}
                            />
                        </DataTable>
                    </main>
                </div>
            </>
        )
    }
}